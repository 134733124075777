import { ReactNode, createContext } from 'react';
import { ChevronDown } from 'lucide-react';
import { FilterItem } from './FilterItem';
import { SimplePopover } from '@/Components/SimplePopover';

export const FilterPopoverContext = createContext(() => {});

type Props = {
  name: string;
  filterName?: string | number;
  children: ReactNode;
  onRemoveFilter: () => void;
};

export const FilterPopover = ({
  children,
  onRemoveFilter,
  name,
  filterName,
}: Props) => {
  const filter = '' + (filterName ? filterName : '');

  return (
    <SimplePopover
      align="start"
      trigger={
        <>
          <div
            className={`flex items-center gap-1 rounded-lg border px-2.5 py-1.5 text-base text-primary ${
              filter && filter.length > 0 ? 'bg-amber-50' : ''
            }`}
          >
            {filter && filter.length > 0 ? (
              <FilterItem onRemove={onRemoveFilter}>{filter}</FilterItem>
            ) : (
              <>
                {name} <ChevronDown size={16} />
              </>
            )}
          </div>
        </>
      }
    >
      {children}
    </SimplePopover>
  );
};
