type Props = {
  completed: boolean;
  dense?: boolean;
};

export const TaskDot = ({ completed, dense }: Props) => {
  return (
    <div
      className={`${
        completed ? 'bg-green-300' : 'bg-red-200'
      } h-2.5 w-2.5 rounded-full ${dense ? 'mr-4' : 'mr-8'}`}
    />
  );
};
