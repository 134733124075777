import { useSnackbar } from '@/Components/v2/Snackbar';
import {
  useProjectTrackedKeywordDelete,
  useProjectTrackedKeywordForceUpdate,
  useRankTrackingListDestroy,
  useRankTrackingListIndex,
  useRankTrackingListItemDestroy,
  useRankTrackingListItemStore,
  useRankTrackingListStore,
  useRankTrackingListUpdate,
} from '@/api/openapiComponents';
import { useRef, useState } from 'react';
import { useAppStore } from '../AppLoader/stores';
import {
  ContentListResource,
  TrackedKeywordResource,
} from '@/api/openapiSchemas';

export const useRefreshKeyword = () => {
  const appState = useAppStore();

  const refreshTrackedKeywordMutation = useProjectTrackedKeywordForceUpdate();
  const { showSnackbar } = useSnackbar();
  const refreshedRef = useRef(false);

  const handleRefresh = (keywordId: number): Promise<void> => {
    return new Promise<void>((resolve) => {
      showSnackbar({
        message: 'Updating rank...',
        color: 'gray',
        duration: 'infinite',
      });
      refreshedRef.current = true;
      refreshTrackedKeywordMutation.mutate(
        {
          pathParams: {
            project: appState.currentProject!.id,
            trackedKeyword: keywordId,
          },
        },
        {
          onSuccess: () => {
            resolve();
          },
          onError(error) {
            showSnackbar({
              message: error.message,
              color: 'red',
            });
          },
        },
      );
    });
  };

  return handleRefresh;
};

export const useRankTrackingLists = <T = TrackedKeywordResource>({
  onSuccess,
}: {
  onSuccess: (
    type:
      | 'delete-list'
      | 'add-list'
      | 'edit-list'
      | 'add-keyword-to-list'
      | 'remove-keyword-from-list',
  ) => void;
}) => {
  const appState = useAppStore();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showAddListDialog, setShowAddListDialog] = useState(false);
  const [showAddKeywordDialog, setShowAddKeywordDialog] = useState(false);

  const [listToBeEdited, setListToBeEdited] = useState<ContentListResource>();
  const [listToBeDeleted, setListToBeDeleted] = useState<ContentListResource>();
  const [trackingToBeAddedToList, setTrackingToBeAddedToList] = useState<T[]>(
    [],
  );
  const [trackingToBeRemovedFromList, setTrackingToBeRemovedFromList] =
    useState<T[]>([]);
  const [trackingToBeDeleted, setTrackingToBeDeleted] = useState<T[]>([]);

  const listsQuery = useRankTrackingListIndex({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      limit: 100,
    },
  });

  const createListMutation = useRankTrackingListStore();
  const updateListMutation = useRankTrackingListUpdate();
  const addKeywordsToListMutation = useRankTrackingListItemStore();
  const removeFromListMutation = useRankTrackingListItemDestroy();
  const deleteListMutation = useRankTrackingListDestroy();

  const handleEditList = (name?: string) => {
    updateListMutation.mutate(
      {
        body: {
          name,
        },
        pathParams: {
          rankTrackingList: listToBeEdited!.id,
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: () => {
          onSuccess('edit-list');
          listsQuery.refetch();
          setListToBeEdited(undefined);
        },
      },
    );
  };

  const handleDeleteList = () => {
    deleteListMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
          rankTrackingList: Number(listToBeDeleted!.id),
        },
      },
      {
        onSuccess: () => {
          listsQuery.refetch();
          onSuccess('delete-list');
          setSelectedTabIndex(0);
          setListToBeDeleted(undefined);
        },
      },
    );
  };

  const handleAddList = (name?: string) => {
    createListMutation.mutate(
      {
        body: {
          name: name ?? '',
        },
        pathParams: {
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: (response) => {
          const listId = response.data.id;
          onSuccess('add-list');
          listsQuery.refetch().then((response) => {
            setSelectedTabIndex(
              response.data?.data?.findIndex((list) => list.id === listId) + 1,
            );
          });
          setShowAddListDialog(false);
        },
      },
    );
  };

  const handleDeleteItemFromList = (
    keywords: { id: number; name: string }[],
    selectedListId: number,
  ) => {
    removeFromListMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
          rankTrackingList: Number(selectedListId),
        },
        body: {
          items: keywords.map((keyword) => keyword.id),
        },
      },
      {
        onSuccess: () => {
          onSuccess('remove-keyword-from-list');
          setTrackingToBeRemovedFromList([]);
        },
      },
    );
  };

  const handleAddKeywordToList = async (
    name: string,
    selectedListId: number,
    keywords: { id: number; name: string }[],
  ) => {
    let newListId: number | undefined;

    if (selectedListId === -1) {
      const response = await createListMutation.mutateAsync({
        body: {
          name,
        },
        pathParams: {
          project: appState.currentProject!.id,
        },
      });

      newListId = parseInt(response.data.id);
    }
    const id = newListId ?? selectedListId;
    addKeywordsToListMutation.mutate(
      {
        body: {
          items: keywords.map((keyword) => keyword.id),
        },
        pathParams: {
          project: appState.currentProject!.id,
          rankTrackingList: newListId ?? selectedListId,
        },
      },
      {
        onSuccess: () => {
          onSuccess('add-keyword-to-list');
          listsQuery.refetch().then((response) => {
            if (!response.data?.data) return;
            setSelectedTabIndex(
              response.data?.data?.findIndex(
                (list) => parseInt(list.id) === id,
              ) + 1,
            );
          });
          setTrackingToBeAddedToList([]);
        },
      },
    );
  };

  return {
    deleteController: {
      list: listToBeDeleted,
      handleSubmit: handleDeleteList,
      isLoading: deleteListMutation.isPending,
      open: (list: ContentListResource) => setListToBeDeleted(list),
    },
    editController: {
      list: listToBeEdited,
      handleSubmit: handleEditList,
      isLoading: updateListMutation.isPending,
      open: (list: ContentListResource) => setListToBeEdited(list),
      error: updateListMutation.error,
    },
    closeDialogs: () => {
      setListToBeEdited(undefined);
      setListToBeDeleted(undefined);
      setShowAddListDialog(false);
    },
    addController: {
      isLoading: createListMutation.isPending,
      handleSubmit: handleAddList,
      isOpen: showAddListDialog,
      open: () => setShowAddListDialog(true),
      error: createListMutation.error,
    },
    removeFromListController: {
      handleSubmit: handleDeleteItemFromList,
      isLoading: removeFromListMutation.isPending,
      error: removeFromListMutation.error,
    },
    addToListController: {
      handleSubmit: handleAddKeywordToList,
      isLoading: addKeywordsToListMutation.isPending,
      error: addKeywordsToListMutation.error,
    },
    selectedTabIndex,
    setSelectedTabIndex,
    showAddKeywordDialog,
    setShowAddKeywordDialog,
    listsQuery,
    trackingToBeAddedToList,
    setTrackingToBeAddedToList,
    trackingToBeRemovedFromList,
    setTrackingToBeRemovedFromList,
    trackingToBeDeleted,
    setTrackingToBeDeleted,
  };
};
