import * as React from 'react';
import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import SimpleTitle from '@/Components/SimpleTitle';
import * as Table from '@/Components/Table';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { Copy, MoreVertical, Trash2 } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import { ScoreLabel } from '@/Components/Labels/ScoreLabel';
import { TagEditor } from '../Document/components/TagEditor/TagEditor';
import { hasher } from '../AppLoader/AppLoader';
import { MultipleItemSelector } from '@/Components/Table/components/MultipleItemSelector';
import AppLabel, { TagVariant } from '@/Components/Labels/AppLabel';
import { Loader } from '@/Components/Loader';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { useAppStore } from '../AppLoader/stores';
import { Link, useNavigate } from '@tanstack/react-router';
import {
  DocumentResource,
  OrganisationTagResource,
} from '@/api/openapiSchemas';
import ToggleButton from '@/Components/ToggleButton';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { ContentCreatedRoute, ContentCreatedSearchParams } from './routes';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import {
  useDeleteDocument,
  useExportDocuments,
  useListDocuments,
  useOrganisationsTagsIndex,
  useUpdateDocument,
} from '@/api/openapiComponents';
import { queryKeyFn } from '@/api/openapiContext';
import { useDebounce } from '@/Hooks/useDebounce';
import { downloadCSV } from '@/utils';
import { useCopyTable, useTableSelection } from '@/Components/Table/hooks';
import { SelectionActions } from '@/Components/Table/components/SelectionActions';
import { DeleteDocumentsDialog } from './components/DeleteDocumentsDialog';

export default function ContentCreated() {
  const appState = useAppStore();
  const tableState = ContentCreatedRoute.useSearch();
  const { selection, setSelection, resetSelection } = useTableSelection();
  const navigate = useNavigate({ from: ContentCreatedRoute.fullPath });

  const [documentsToBeDeleted, setDocumentsToBeDeleted] = React.useState<
    DocumentResource[]
  >([]);
  const [documentIdToBeDeleted, setDocumentIdToBeDeleted] =
    React.useState<number>();

  const client = useQueryClient();
  const variables = {
    pathParams: { project: appState.currentProject!.id },
    queryParams: {
      ...tableState,
      limit: tableState?.limit ?? 25,
    },
  };

  const queryKey = queryKeyFn({
    path: '/projects/{project}/documents',
    operationId: 'listDocuments',
    variables,
  });

  const {
    data: documents,
    isLoading: isLoadingDocuments,
    refetch: refetchDocuments,
  } = useListDocuments(variables);

  const {
    mutateAsync: exportingDocumentsToCSV,
    isPending: isExportingDocumentsToCSV,
  } = useExportDocuments();

  const { mutate: deleteDocument, isPending: isDeletingDocument } =
    useDeleteDocument({
      onSuccess: () => {
        client.invalidateQueries({
          queryKey,
        });
      },
    });

  const [tagsFilter, setTagsFilter] = React.useState('');
  const [initialTagFilter, setInitialTagFilter] = React.useState('');

  const handleCopyToClipboard = useCopyTable(
    selection.map((item) => (item.title ? item.title : 'Untitled')).join('\n'),
  );

  useDebounce(
    () => {
      setTagsFilter(initialTagFilter);
    },
    [initialTagFilter],
    350,
  );

  const updateDocumentMutation = useUpdateDocument();
  const tagsQuery = useOrganisationsTagsIndex({
    pathParams: {
      organisation: appState.auth!.user.org_id,
    },
    queryParams: {
      filters: tagsFilter === '' ? undefined : { search: tagsFilter },
    },
  });

  React.useEffect(() => {
    const url = `projects.${appState.currentProject!.id}`;
    window.Echo.private(url).listen(`DocumentUpdatedEvent`, () => {
      refetchDocuments();
    });
    return () => {
      window.Echo.leave(url);
    };
  }, []);

  const handleUpdateTags = (
    documentId: number,
    tags: OrganisationTagResource[],
  ) => {
    tags.sort((a, b) => a.name.localeCompare(b.name));

    const newTagIds = tags.map((tag) => tag.id).toString();
    const oldTagIds = (
      documents?.data
        ?.find((doc) => doc.id === documentId)
        ?.tags?.map((tag) => tag.id) ?? []
    ).toString();

    if (oldTagIds !== newTagIds) {
      updateDocumentMutation.mutate({
        pathParams: {
          document: documentId,
          project: appState.currentProject!.id,
        },
        body: {
          tags: tags.map((tag) => tag.id),
        },
      });
    }

    const updatedDocuments = documents?.data?.map((doc) => {
      if (doc.id === documentId) {
        return {
          ...doc,
          tags: tags,
        };
      }
      return doc;
    });
    client.setQueryData(queryKey, {
      data: updatedDocuments,
      meta: documents?.meta,
    });
    refetchDocuments();
  };

  appState.pageTitle('Created content');

  return (
    <Menus>
      <DeleteDocumentsDialog
        key={
          documentsToBeDeleted.map((doc) => doc.id).join(',') +
          'deletedocuments'
        }
        documentsToBeDeleted={documentsToBeDeleted}
        onClose={() => setDocumentsToBeDeleted([])}
        onRemove={() => {
          resetSelection();
          refetchDocuments();
        }}
      />
      <ConfirmDialog
        title="Delete document"
        description={[
          'Are you sure you want to delete this document? It cannot be undone',
        ]}
        confirmColor="red"
        confirmText="delete"
        isOpen={!!documentIdToBeDeleted}
        onClose={() => setDocumentIdToBeDeleted(undefined)}
        onConfirm={() => {
          deleteDocument(
            {
              pathParams: {
                document: documentIdToBeDeleted!,
                project: appState.currentProject!.id,
              },
            },
            {
              onSuccess: () => {
                setDocumentIdToBeDeleted(undefined);
                refetchDocuments();
              },
            },
          );
        }}
        isLoading={isDeletingDocument}
      />
      <PageContainer>
        <div className="mb-8">
          <SimpleTitle>Created Content</SimpleTitle>
        </div>

        <Table.Root
          selection={selection}
          onSelectionChange={setSelection}
          isLoading={isLoadingDocuments}
          skeletonLoaders={10}
          meta={documents?.meta}
          sorting={tableState}
          onSortChange={({ sortBy, sortDirection }) => {
            navigate({
              search: (prev) => ({
                ...prev,
                sortBy: sortBy as ContentCreatedSearchParams,
                sortDirection,
              }),
            });
          }}
          items={
            documents?.data?.map((doc) => ({
              isGenerating: doc.is_generating,
              id: doc.id,
              targetScore: doc.target_score,
              hash_id: doc.hash_id,
              title: doc.internal_name,
              keyword: doc.keyword.name,
              seoScore: doc.score ?? 0,
              tags: doc.tags,
              wordCount:
                doc.word_count?.toLocaleString().replaceAll(',', '.') ?? '0',
              buttons: (
                <PopoverMenu
                  trigger={<IconButton color="gray" icon={MoreVertical} />}
                  items={[
                    {
                      title: 'Delete',
                      onClick: () => {
                        setDocumentIdToBeDeleted(doc.id);
                      },
                      prependIcon: Trash2,
                    },
                  ]}
                />
              ),
            })) ?? []
          }
          columns={[
            {
              property: 'title',
              sortableHeader: true,
              heading: 'Title',
              render: (item) => (
                <div className="flex items-center">
                  <Link
                    to={`/documents/$documentId`}
                    params={{ documentId: hasher.encode(item.id) }}
                    state={(prev) => ({ ...prev, tableState })}
                    className={`pr-2 font-extrabold ${
                      item.title ? 'text-black' : 'text-gray-400'
                    } hover:opacity-60`}
                    href={`/documents/${item.hash_id}`}
                  >
                    <div className="flex items-center gap-2">
                      {item.isGenerating && (
                        <Loader className="h-6 w-6 flex-shrink-0 stroke-green-500" />
                      )}
                      {item.title ? item.title : 'Untitled'}
                    </div>
                  </Link>
                </div>
              ),
            },
            {
              property: 'keyword',
              sortableHeader: true,
              heading: 'Target keyword',
            },
            {
              property: 'seoScore',
              sortableHeader: 'score',
              heading: 'Seo score',
              rightAlign: true,
              render: (item) => (
                <ScoreLabel
                  score={item.seoScore}
                  color={
                    item.seoScore >= (item.targetScore ?? 0) ? 'green' : 'gray'
                  }
                />
              ),
            },
            {
              property: 'wordCount',
              rightAlign: true,
              sortableHeader: 'word_count',
              heading: 'Word count',
              render: (item) => <>{item.wordCount}</>,
            },
            {
              heading: 'Tags',
              render: (item) => (
                <div className="w-[15vw] min-w-[8rem]">
                  <TagEditor
                    key={item.id}
                    onTagUpdated={(tags) => handleUpdateTags(item.id, tags)}
                    organisationId={appState.organisation!.id}
                    tags={item.tags ?? []}
                  />
                </div>
              ),
            },
            {
              property: 'buttons',
              heading: <h4 className="invisible mt-4 uppercase" />,
            },
          ]}
        >
          <Table.Header
            onExportCSV={{
              isLoading: isExportingDocumentsToCSV,
              onClick: () => {
                exportingDocumentsToCSV({
                  body: {
                    filters: tableState.filters,
                    sort_by: tableState.sortBy,
                    sort_direction: tableState.sortDirection,
                  },
                  pathParams: { project: appState.currentProject!.id },
                }).then((response) => {
                  downloadCSV(response as unknown as Blob, 'content.csv');
                });
              },
            }}
            search={tableState.filters?.search}
            onSearchChange={(value) =>
              navigate({
                search: (prev) => ({
                  ...prev,
                  page: 1,
                  filters: { ...prev.filters, search: value },
                }),
              })
            }
          >
            {selection.length !== 0 ? (
              <SelectionActions
                selection={selection}
                actions={[
                  {
                    size: 'sm',
                    dense: true,
                    prependIcon: Trash2,
                    text: 'Delete',
                    variant: 'ghost',
                    onClick: () => setDocumentsToBeDeleted(selection),
                  },
                  {
                    size: 'sm',
                    dense: true,
                    prependIcon: Copy,
                    text: 'Copy',
                    variant: 'ghost',
                    onClick: handleCopyToClipboard,
                  },
                ]}
              />
            ) : (
              <div className="flex gap-2">
                <Table.ResultsTotal
                  isLoading={isLoadingDocuments}
                  title="documents"
                  total={documents?.meta?.total}
                />
                <Table.FilterPopover
                  onRemoveFilter={() =>
                    navigate({
                      search: (prev) => ({
                        ...prev,
                        filters: { ...prev.filters, tags: undefined },
                      }),
                    })
                  }
                  name="Tags"
                  filterName={
                    (tableState.filters?.tags ?? []).length > 0
                      ? `Tags: ${tableState.filters
                          ?.tags!.map(
                            (tagId) =>
                              tagsQuery.data?.data.find(
                                (tag) => tag.id === tagId,
                              )?.name,
                          )
                          .map((name) =>
                            (name?.length ?? 0) > 15
                              ? name?.slice(0, 12) + '... '
                              : name,
                          )
                          .toString()}`
                      : undefined
                  }
                >
                  <MultipleItemSelector
                    filterValue={initialTagFilter}
                    onFilterValueChange={setInitialTagFilter}
                    items={(tagsQuery.data?.data ?? []).map((tag) => ({
                      id: tag.id,
                      item: (
                        <div className="max-w-[10rem] overflow-hidden">
                          <AppLabel
                            variant={
                              TagVariant[tag.color as keyof typeof TagVariant]
                            }
                          >
                            {tag.name}
                          </AppLabel>
                        </div>
                      ),
                    }))}
                    values={tableState.filters?.tags}
                    onChange={(tags) =>
                      navigate({
                        search: (prev) => ({
                          ...prev,
                          filters: { ...prev.filters, tags },
                        }),
                      })
                    }
                  >
                    <ToggleButton<'all' | 'any'>
                      options={[
                        { displayName: 'All', value: 'all' },
                        { displayName: 'Any', value: 'any' },
                      ]}
                      onChange={(value) => {
                        navigate({
                          search: (prev) => ({
                            ...prev,
                            filters: { ...prev.filters, tag_mode: value },
                          }),
                        });
                      }}
                      size="sm"
                      value={tableState.filters?.tag_mode ?? 'all'}
                      buttonClassName="py-0.5 w-full justify-center"
                    />
                  </MultipleItemSelector>
                </Table.FilterPopover>
              </div>
            )}
          </Table.Header>
          <Table.Footer>
            <Pagination
              currentPage={tableState.page ?? 1}
              setCurrentPage={(currentPage) => {
                navigate({
                  search: (prev) => ({ ...prev, page: currentPage }),
                });
              }}
              lastPage={documents?.meta.last_page}
            />
          </Table.Footer>
        </Table.Root>
      </PageContainer>
    </Menus>
  );
}
