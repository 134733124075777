import { LucideIcon } from 'lucide-react';
import { SkeletonLoader } from '../SkeletonLoader/SkeletonLoader';

type Props = {
  title: string;
  PrependIcon?: LucideIcon;
  onClick?: () => void;
  variant?: 'dashed' | 'solid';
  onDelete?: () => void;
  isLoading?: boolean;
};

export const Chip = ({
  title,
  PrependIcon,
  onClick,
  variant = 'solid',
  onDelete,
  isLoading,
}: Props) => {
  const onClickCss = onClick ? 'cursor-pointer' : '';

  const variantCss = variant === 'dashed' ? 'border-dashed' : 'border-solid';
  return !isLoading ? (
    <>
      <div
        onClick={onClick}
        className={`flex max-w-[12rem] items-center justify-center gap-2 rounded-lg border px-2.5 py-1.5 text-base text-primary ${onClickCss} ${variantCss}`}
      >
        <span className="truncate">{title}</span>
        {PrependIcon && (
          <PrependIcon
            onClick={(e) => {
              if (onDelete) {
                e.stopPropagation();
                onDelete();
              }
            }}
            size={14}
          />
        )}
      </div>
    </>
  ) : (
    <div className="w-[12rem]">
      <SkeletonLoader height="xs" />
    </div>
  );
};
