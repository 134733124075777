import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from 'lucide-react';
import React from 'react';

interface Props {
  title: string | React.ReactNode;
  children: React.ReactNode;
  fullWidth?: boolean;
}
export const Collapsable = ({ title, children, fullWidth }: Props) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`flex items-center justify-center gap-2 px-2 text-lg text-primary ${
              fullWidth ? 'w-full justify-between' : 'justify-center'
            }`}
          >
            {title}
            <ChevronUpIcon
              size={24}
              className={`flex-shrink-0 ${
                !open ? ' rotate-180 transform' : ''
              }`}
            />
          </Disclosure.Button>
          <Disclosure.Panel>{children}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
