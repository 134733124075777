import { useEffect, useState } from 'react';
import { LanguageResource } from '@/api/openapiSchemas';
import LanguageComboboxV2 from '@/Components/Inputs/LanguageComboBoxV2';
import { useAppStore } from '../../AppLoader/stores';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { useNavigate } from '@tanstack/react-router';
import { Errors } from '@/Components/Errors';
import { Button } from '@/Components/v2/Button';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import {
  useLanguagesIndex,
  useProjectsDestroy,
  useProjectsIndex,
  useProjectsUpdate,
} from '@/api/openapiComponents';

type FormValues = {
  domain: string;
  language?: LanguageResource;
  projectName: string;
};

export default function EditProject() {
  const navigate = useNavigate();
  const appState = useAppStore();
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const languageQuery = useLanguagesIndex({
    queryParams: {
      limit: 500,
    },
  });
  const languages = languageQuery.data?.data ?? [];
  const language = languages.find(
    (language) => language.id === appState.currentProject!.language.id,
  );

  const projectsQuery = useProjectsIndex({ queryParams: { limit: 1 } });

  const hasMoreThanOneProject =
    (projectsQuery.data?.meta.total ?? 1) > 1 ?? false;

  const editProjectMutation = useProjectsUpdate();

  const deleteProjectMutation = useProjectsDestroy({
    onSuccess: async () => {
      appState.handleDeleteProject(appState.currentProject!.id);
      await appState.refreshSubscription();
      navigate({ to: '/' });
    },
  });

  const isLoading =
    editProjectMutation.isPending ||
    deleteProjectMutation.isPending ||
    editProjectMutation.isSuccess;
  const errors = editProjectMutation.error || deleteProjectMutation.error;

  const [data, setData] = useState<FormValues>({
    domain: appState.currentProject!.domain,
    language: language,
    projectName: appState.currentProject!.name,
  });

  useEffect(() => {
    setData({
      domain: appState.currentProject!.domain,
      language: language,
      projectName: appState.currentProject!.name,
    });
  }, [appState.currentProject]);

  const handleChange = (name: keyof FormValues, value: any) => {
    if (name === 'domain' && data.domain === data.projectName) {
      setData({ ...data, [name]: value, projectName: value });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    editProjectMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          name: data.projectName,
        },
      },
      {
        onSuccess: (data) => {
          appState.handleEditProject(data.data);
          navigate({ to: '/' });
        },
      },
    );
    return false;
  };

  const handleDeletedDocument = async () => {
    deleteProjectMutation.mutate({
      pathParams: {
        project: appState.currentProject!.id,
      },
    });
  };

  appState.pageTitle(`Edit ${appState.currentProject!.name}`);

  return (
    <>
      <div className="mb-7 mt-4 flex h-max w-full max-w-7xl justify-center rounded-lg border border-gray-200 bg-white py-12 font-work">
        <div className="flex w-[480px] flex-wrap">
          <form onSubmit={submit} autoComplete="off" className="w-full">
            <div className="relative mb-6 w-full">
              <InputDecoration required label="Domain or website URL path">
                <Input
                  onChange={(value) => handleChange('domain', value)}
                  value={data.domain}
                  placeholder="www.yoursite.com"
                  disabled
                />
              </InputDecoration>
            </div>

            <div className="mb-6 w-full">
              <InputDecoration required label="Website name">
                <Input
                  onChange={(value) => handleChange('projectName', value)}
                  value={data.projectName}
                  placeholder="Name of the project"
                />
              </InputDecoration>
            </div>

            <div className="mb-6 w-full">
              <InputDecoration required label="Language">
                <LanguageComboboxV2
                  classNames="h-10"
                  languages={languages}
                  disabled
                  selectedLanguage={language ?? null}
                  setSelectedLanguage={() => null}
                />
              </InputDecoration>
              <div className="ml-1 mt-1 w-full text-sm">
                <a
                  href="https://docs.seo.ai/a-4-how-to-create-projects"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Learn more about <u>website languages</u>
                </a>
              </div>
            </div>

            <div className="mb-6 mt-6 w-full">
              <Errors error={errors} />
            </div>

            <div className="flex w-full justify-center">
              <Button
                text="Save"
                type="submit"
                isLoading={isLoading}
                color="secondary"
                variant="outline"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="mb-10 flex w-full cursor-pointer justify-center">
        <Button
          text="Delete website"
          color="red"
          size="sm"
          dense
          variant="outline"
          onClick={() => setShowDeletePopup(true)}
        />
      </div>

      <ConfirmDialog
        title="Delete website"
        description={[
          `Are you sure you want to delete the website ${data.projectName}?`,
          'This cannot be undone.',
        ]}
        isOpen={showDeletePopup}
        confirmText="Delete"
        onClose={() => setShowDeletePopup(false)}
        confirmColor="red"
        onConfirm={handleDeletedDocument}
        isLoading={deleteProjectMutation.isPending}
      />
    </>
  );
}
