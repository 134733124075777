/**
 * Generated by @openapi-codegen
 *
 * @version 2.0.0
 */
import * as reactQuery from '@tanstack/react-query';
import { useOpenapiContext, OpenapiContext } from './openapiContext';
import type * as Fetcher from './openapiFetcher';
import { openapiFetch } from './openapiFetcher';
import type * as Schemas from './openapiSchemas';
import type * as Responses from './openapiResponses';

export type AuthSignUpError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Responses.ValidationException;
}>;

export type AuthSignUpVariables = {
  body: Schemas.SignUpRequest;
} & OpenapiContext['fetcherOptions'];

/**
 * An email will be sent to verify the user's email address.
 *
 * This email will contain a link to the next step in the sign up flow.
 */
export const fetchAuthSignUp = (
  variables: AuthSignUpVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<undefined, AuthSignUpError, Schemas.SignUpRequest, {}, {}, {}>({
    url: '/sign-up',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * An email will be sent to verify the user's email address.
 *
 * This email will contain a link to the next step in the sign up flow.
 */
export const useAuthSignUp = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AuthSignUpError,
      AuthSignUpVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    AuthSignUpError,
    AuthSignUpVariables
  >({
    mutationFn: (variables: AuthSignUpVariables) =>
      fetchAuthSignUp({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AuthRegisterError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Responses.ValidationException;
}>;

export type AuthRegisterResponse = {
  data: Schemas.UserResource;
};

export type AuthRegisterVariables = {
  body: Schemas.RegisterRequest;
} & OpenapiContext['fetcherOptions'];

export const fetchAuthRegister = (
  variables: AuthRegisterVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    AuthRegisterResponse,
    AuthRegisterError,
    Schemas.RegisterRequest,
    {},
    {},
    {}
  >({ url: '/register', method: 'post', ...variables, signal });

export const useAuthRegister = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      AuthRegisterResponse,
      AuthRegisterError,
      AuthRegisterVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    AuthRegisterResponse,
    AuthRegisterError,
    AuthRegisterVariables
  >({
    mutationFn: (variables: AuthRegisterVariables) =>
      fetchAuthRegister({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AuthTokenError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example Invalid credentials.
         */
        message: string;
      };
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type AuthTokenResponse = {
  data: Schemas.AccessTokenResource;
};

export type AuthTokenVariables = {
  body: Schemas.LoginRequest;
} & OpenapiContext['fetcherOptions'];

export const fetchAuthToken = (
  variables: AuthTokenVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    AuthTokenResponse,
    AuthTokenError,
    Schemas.LoginRequest,
    {},
    {},
    {}
  >({ url: '/auth/token', method: 'post', ...variables, signal });

export const useAuthToken = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      AuthTokenResponse,
      AuthTokenError,
      AuthTokenVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    AuthTokenResponse,
    AuthTokenError,
    AuthTokenVariables
  >({
    mutationFn: (variables: AuthTokenVariables) =>
      fetchAuthToken({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AuthPasswordResetLinkError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Responses.ValidationException;
}>;

export type AuthPasswordResetLinkRequestBody = {
  /**
   * @format email
   */
  email: string;
};

export type AuthPasswordResetLinkVariables = {
  body: AuthPasswordResetLinkRequestBody;
} & OpenapiContext['fetcherOptions'];

export const fetchAuthPasswordResetLink = (
  variables: AuthPasswordResetLinkVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    AuthPasswordResetLinkError,
    AuthPasswordResetLinkRequestBody,
    {},
    {},
    {}
  >({ url: '/auth/forgot-password', method: 'post', ...variables, signal });

export const useAuthPasswordResetLink = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AuthPasswordResetLinkError,
      AuthPasswordResetLinkVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    AuthPasswordResetLinkError,
    AuthPasswordResetLinkVariables
  >({
    mutationFn: (variables: AuthPasswordResetLinkVariables) =>
      fetchAuthPasswordResetLink({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AuthNewPasswordError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Responses.ValidationException;
}>;

export type AuthNewPasswordRequestBody = {
  token: string;
  /**
   * @format email
   */
  email: string;
  password: string;
  password_confirmation: string;
};

export type AuthNewPasswordVariables = {
  body: AuthNewPasswordRequestBody;
} & OpenapiContext['fetcherOptions'];

export const fetchAuthNewPassword = (
  variables: AuthNewPasswordVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    AuthNewPasswordError,
    AuthNewPasswordRequestBody,
    {},
    {},
    {}
  >({ url: '/auth/reset-password', method: 'post', ...variables, signal });

export const useAuthNewPassword = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AuthNewPasswordError,
      AuthNewPasswordVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    AuthNewPasswordError,
    AuthNewPasswordVariables
  >({
    mutationFn: (variables: AuthNewPasswordVariables) =>
      fetchAuthNewPassword({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type InitializeGoogleLoginError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type InitializeGoogleLoginResponse = {
  data: Schemas.SocialLoginResource;
};

export type InitializeGoogleLoginVariables = {
  body: Schemas.SocialLoginRedirectRequest;
} & OpenapiContext['fetcherOptions'];

export const fetchInitializeGoogleLogin = (
  variables: InitializeGoogleLoginVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    InitializeGoogleLoginResponse,
    InitializeGoogleLoginError,
    Schemas.SocialLoginRedirectRequest,
    {},
    {},
    {}
  >({ url: '/auth/google', method: 'post', ...variables, signal });

export const useInitializeGoogleLogin = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      InitializeGoogleLoginResponse,
      InitializeGoogleLoginError,
      InitializeGoogleLoginVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    InitializeGoogleLoginResponse,
    InitializeGoogleLoginError,
    InitializeGoogleLoginVariables
  >({
    mutationFn: (variables: InitializeGoogleLoginVariables) =>
      fetchInitializeGoogleLogin({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListUrlsPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ListUrlsQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?:
    | 'total_clicks'
    | 'opportunity_score'
    | 'keyword_clicks'
    | 'keyword_impressions'
    | 'keyword_position'
    | 'keyword_name';
  sort_direction?: 'ASC' | 'DESC';
  filters?: {
    total_clicks_min?: number | null;
    total_clicks_max?: number | null;
    keyword_clicks_min?: number | null;
    keyword_clicks_max?: number | null;
    keyword_impressions_min?: number | null;
    keyword_impressions_max?: number | null;
    keyword_position_min?: number | null;
    keyword_position_max?: number | null;
    opportunity_score_min?: number | null;
    opportunity_score_max?: number | null;
    search?: string | null;
  };
};

export type ListUrlsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Upgrade required to use Content Scanner
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ListUrlsResponse = {
  data: Schemas.ScannedContentResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ListUrlsVariables = {
  pathParams: ListUrlsPathParams;
  queryParams?: ListUrlsQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchListUrls = (
  variables: ListUrlsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ListUrlsResponse,
    ListUrlsError,
    undefined,
    {},
    ListUrlsQueryParams,
    ListUrlsPathParams
  >({
    url: '/projects/{project}/content-scanner',
    method: 'get',
    ...variables,
    signal,
  });

export const useListUrls = <TData = ListUrlsResponse,>(
  variables: ListUrlsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ListUrlsResponse, ListUrlsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<ListUrlsResponse, ListUrlsError, TData>({
    queryKey: queryKeyFn({
      path: '/projects/{project}/content-scanner',
      operationId: 'listUrls',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListUrls({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type StartScanPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type StartScanError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Upgrade required to use Content Scanner
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 409;
      payload: {
        /**
         * Error overview.
         *
         * @example Scan already in progress
         */
        message: string;
      };
    }
>;

export type StartScanVariables = {
  body?: Record<string, any>;
  pathParams: StartScanPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * ### Broadcasting details
 *
 * #### Content Scan
 *
 * The broadcast event is simply a poke, indicating that the scan is complete. No data is included.
 *
 * Broadcast channel: `projects.{projectId}`
 *
 * Broadcast event: `.ContentScanCompleted`
 *
 * #### Single URL Scan
 *
 * The broadcast event is simply a poke, indicating that the scan for a specific URL is complete.
 *
 * Broadcast channel: `projects.{projectId}`
 *
 * Broadcast event: `.ProjectUrlScanCompleted`
 *
 * Broadcast event data:
 *
 * ```json
 * {
 *    "project_url_id": 1
 * }
 * ```
 */
export const fetchStartScan = (
  variables: StartScanVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    string,
    StartScanError,
    Record<string, any>,
    {},
    {},
    StartScanPathParams
  >({
    url: '/projects/{project}/content-scanner',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * ### Broadcasting details
 *
 * #### Content Scan
 *
 * The broadcast event is simply a poke, indicating that the scan is complete. No data is included.
 *
 * Broadcast channel: `projects.{projectId}`
 *
 * Broadcast event: `.ContentScanCompleted`
 *
 * #### Single URL Scan
 *
 * The broadcast event is simply a poke, indicating that the scan for a specific URL is complete.
 *
 * Broadcast channel: `projects.{projectId}`
 *
 * Broadcast event: `.ProjectUrlScanCompleted`
 *
 * Broadcast event data:
 *
 * ```json
 * {
 *    "project_url_id": 1
 * }
 * ```
 */
export const useStartScan = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, StartScanError, StartScanVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<string, StartScanError, StartScanVariables>({
    mutationFn: (variables: StartScanVariables) =>
      fetchStartScan({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectContentScannerUpdatePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectContentScannerUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Upgrade required to use Content Scanner
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 409;
      payload: {
        /**
         * Error overview.
         *
         * @example Project is already scanning
         */
        message: string;
      };
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectContentScannerUpdateVariables = {
  body: Schemas.RescanProjectUrlsRequest;
  pathParams: ProjectContentScannerUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectContentScannerUpdate = (
  variables: ProjectContentScannerUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    string,
    ProjectContentScannerUpdateError,
    Schemas.RescanProjectUrlsRequest,
    {},
    {},
    ProjectContentScannerUpdatePathParams
  >({
    url: '/projects/{project}/content-scanner/update',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectContentScannerUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      ProjectContentScannerUpdateError,
      ProjectContentScannerUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    string,
    ProjectContentScannerUpdateError,
    ProjectContentScannerUpdateVariables
  >({
    mutationFn: (variables: ProjectContentScannerUpdateVariables) =>
      fetchProjectContentScannerUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListUrlKeywordsPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The url ID
   */
  url: number;
};

export type ListUrlKeywordsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Upgrade required to use Content Scanner
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ListUrlKeywordsResponse = {
  data: Schemas.ScannedContentSubResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ListUrlKeywordsVariables = {
  body?: Schemas.PaginatedRequest;
  pathParams: ListUrlKeywordsPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchListUrlKeywords = (
  variables: ListUrlKeywordsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ListUrlKeywordsResponse,
    ListUrlKeywordsError,
    Schemas.PaginatedRequest,
    {},
    {},
    ListUrlKeywordsPathParams
  >({
    url: '/projects/{project}/content-scanner/{url}',
    method: 'post',
    ...variables,
    signal,
  });

export const useListUrlKeywords = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ListUrlKeywordsResponse,
      ListUrlKeywordsError,
      ListUrlKeywordsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ListUrlKeywordsResponse,
    ListUrlKeywordsError,
    ListUrlKeywordsVariables
  >({
    mutationFn: (variables: ListUrlKeywordsVariables) =>
      fetchListUrlKeywords({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ExportDocumentsPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ExportDocumentsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ExportDocumentsVariables = {
  body?: Schemas.ExportDocumentRequestDocuments;
  pathParams: ExportDocumentsPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchExportDocuments = (
  variables: ExportDocumentsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    string,
    ExportDocumentsError,
    Schemas.ExportDocumentRequestDocuments,
    {},
    {},
    ExportDocumentsPathParams
  >({
    url: '/projects/{project}/documents/export',
    method: 'post',
    ...variables,
    signal,
  });

export const useExportDocuments = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      ExportDocumentsError,
      ExportDocumentsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    string,
    ExportDocumentsError,
    ExportDocumentsVariables
  >({
    mutationFn: (variables: ExportDocumentsVariables) =>
      fetchExportDocuments({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteDocumentsPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type DeleteDocumentsQueryParams = {
  document_ids: number[];
};

export type DeleteDocumentsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type DeleteDocumentsVariables = {
  pathParams: DeleteDocumentsPathParams;
  queryParams: DeleteDocumentsQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDeleteDocuments = (
  variables: DeleteDocumentsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    DeleteDocumentsError,
    undefined,
    {},
    DeleteDocumentsQueryParams,
    DeleteDocumentsPathParams
  >({
    url: '/projects/{project}/documents',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteDocuments = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteDocumentsError,
      DeleteDocumentsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteDocumentsError,
    DeleteDocumentsVariables
  >({
    mutationFn: (variables: DeleteDocumentsVariables) =>
      fetchDeleteDocuments({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListDocumentsPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ListDocumentsQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id' | 'title' | 'keyword' | 'word_count' | 'score';
  sort_direction?: 'ASC' | 'DESC';
  filters?: {
    /**
     * @minLength 1
     * @maxLength 255
     */
    keyword?: string;
    /**
     * @minLength 1
     * @maxLength 255
     */
    search?: string;
    tag_mode?: Schemas.FilterMode;
    tags?: number[];
    list_id?: number;
  };
};

export type ListDocumentsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ListDocumentsResponse = {
  data: Schemas.DocumentResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ListDocumentsVariables = {
  pathParams: ListDocumentsPathParams;
  queryParams?: ListDocumentsQueryParams;
} & OpenapiContext['fetcherOptions'];

/**
 * ### Broadcasting details
 *
 * Broadcast channels: `projects.{projectId}` and `documents.{documentId}`
 *
 * Broadcast event: `DocumentUpdatedEvent`
 *
 * Broadcast event data:
 *
 * ```typescript
 * type SlimDocumentResource = {
 *     id: int,
 *     hash_id: string,
 *     internal_name: string,
 *     title: string,
 *     keyword: {
 *         name: string,
 *     },
 *     is_generating: bool,
 *     project_id: int,
 * }
 * ```
 */
export const fetchListDocuments = (
  variables: ListDocumentsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ListDocumentsResponse,
    ListDocumentsError,
    undefined,
    {},
    ListDocumentsQueryParams,
    ListDocumentsPathParams
  >({
    url: '/projects/{project}/documents',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * ### Broadcasting details
 *
 * Broadcast channels: `projects.{projectId}` and `documents.{documentId}`
 *
 * Broadcast event: `DocumentUpdatedEvent`
 *
 * Broadcast event data:
 *
 * ```typescript
 * type SlimDocumentResource = {
 *     id: int,
 *     hash_id: string,
 *     internal_name: string,
 *     title: string,
 *     keyword: {
 *         name: string,
 *     },
 *     is_generating: bool,
 *     project_id: int,
 * }
 * ```
 */
export const useListDocuments = <TData = ListDocumentsResponse,>(
  variables: ListDocumentsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListDocumentsResponse,
      ListDocumentsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<ListDocumentsResponse, ListDocumentsError, TData>({
    queryKey: queryKeyFn({
      path: '/projects/{project}/documents',
      operationId: 'listDocuments',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListDocuments({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateDocumentPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type CreateDocumentError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example The chosen keyword is not allowed
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type CreateDocumentResponse = {
  data: Schemas.DocumentResource;
};

export type CreateDocumentVariables = {
  body?: Schemas.CreateDocumentRequest;
  pathParams: CreateDocumentPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchCreateDocument = (
  variables: CreateDocumentVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    CreateDocumentResponse,
    CreateDocumentError,
    Schemas.CreateDocumentRequest,
    {},
    {},
    CreateDocumentPathParams
  >({
    url: '/projects/{project}/documents',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateDocument = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateDocumentResponse,
      CreateDocumentError,
      CreateDocumentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    CreateDocumentResponse,
    CreateDocumentError,
    CreateDocumentVariables
  >({
    mutationFn: (variables: CreateDocumentVariables) =>
      fetchCreateDocument({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetDocumentPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type GetDocumentError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type GetDocumentResponse = {
  data: Schemas.DocumentResource;
};

export type GetDocumentVariables = {
  pathParams: GetDocumentPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchGetDocument = (
  variables: GetDocumentVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    GetDocumentResponse,
    GetDocumentError,
    undefined,
    {},
    {},
    GetDocumentPathParams
  >({
    url: '/projects/{project}/documents/{document}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetDocument = <TData = GetDocumentResponse,>(
  variables: GetDocumentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetDocumentResponse, GetDocumentError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<GetDocumentResponse, GetDocumentError, TData>({
    queryKey: queryKeyFn({
      path: '/projects/{project}/documents/{document}',
      operationId: 'getDocument',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDocument({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateDocumentPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type UpdateDocumentError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type UpdateDocumentResponse = {
  data: Schemas.DocumentResource;
};

export type UpdateDocumentVariables = {
  body?: Schemas.UpdateDocumentRequest;
  pathParams: UpdateDocumentPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchUpdateDocument = (
  variables: UpdateDocumentVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    UpdateDocumentResponse,
    UpdateDocumentError,
    Schemas.UpdateDocumentRequest,
    {},
    {},
    UpdateDocumentPathParams
  >({
    url: '/projects/{project}/documents/{document}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateDocument = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateDocumentResponse,
      UpdateDocumentError,
      UpdateDocumentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    UpdateDocumentResponse,
    UpdateDocumentError,
    UpdateDocumentVariables
  >({
    mutationFn: (variables: UpdateDocumentVariables) =>
      fetchUpdateDocument({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteDocumentPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type DeleteDocumentError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type DeleteDocumentVariables = {
  pathParams: DeleteDocumentPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDeleteDocument = (
  variables: DeleteDocumentVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    DeleteDocumentError,
    undefined,
    {},
    {},
    DeleteDocumentPathParams
  >({
    url: '/projects/{project}/documents/{document}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteDocument = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteDocumentError,
      DeleteDocumentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteDocumentError,
    DeleteDocumentVariables
  >({
    mutationFn: (variables: DeleteDocumentVariables) =>
      fetchDeleteDocument({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectGenerateOutlinePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectGenerateOutlineError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload:
        | {
            /**
             * Error overview.
             *
             * @example The chosen keyword is not allowed.
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example The chosen brief is not allowed.
             */
            message: string;
          };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectGenerateOutlineResponse = {
  data: Schemas.DraftResource;
};

export type ProjectGenerateOutlineVariables = {
  body: Schemas.GenerateOutlineRequest;
  pathParams: ProjectGenerateOutlinePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectGenerateOutline = (
  variables: ProjectGenerateOutlineVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectGenerateOutlineResponse,
    ProjectGenerateOutlineError,
    Schemas.GenerateOutlineRequest,
    {},
    {},
    ProjectGenerateOutlinePathParams
  >({
    url: '/projects/{project}/generate-outline',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectGenerateOutline = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectGenerateOutlineResponse,
      ProjectGenerateOutlineError,
      ProjectGenerateOutlineVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectGenerateOutlineResponse,
    ProjectGenerateOutlineError,
    ProjectGenerateOutlineVariables
  >({
    mutationFn: (variables: ProjectGenerateOutlineVariables) =>
      fetchProjectGenerateOutline({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectGenerateDocumentPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectGenerateDocumentError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload:
        | {
            /**
             * Error overview.
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example The chosen title, keyword, brief or outline is not allowed.
             */
            message: string;
          };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectGenerateDocumentResponse = {
  data: Schemas.SlimDocumentResource;
};

export type ProjectGenerateDocumentVariables = {
  body: Schemas.GenerateDocumentRequest;
  pathParams: ProjectGenerateDocumentPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectGenerateDocument = (
  variables: ProjectGenerateDocumentVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectGenerateDocumentResponse,
    ProjectGenerateDocumentError,
    Schemas.GenerateDocumentRequest,
    {},
    {},
    ProjectGenerateDocumentPathParams
  >({
    url: '/projects/{project}/generate-document',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectGenerateDocument = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectGenerateDocumentResponse,
      ProjectGenerateDocumentError,
      ProjectGenerateDocumentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectGenerateDocumentResponse,
    ProjectGenerateDocumentError,
    ProjectGenerateDocumentVariables
  >({
    mutationFn: (variables: ProjectGenerateDocumentVariables) =>
      fetchProjectGenerateDocument({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectGenerateDocumentsPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectGenerateDocumentsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example The keywords, outline brief, title brief or one of the commands are not allowed.
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example The usage limit has been reached.
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectGenerateDocumentsResponse = {
  data: Schemas.SlimDocumentResource[];
};

export type ProjectGenerateDocumentsVariables = {
  body: Schemas.GenerateDocumentsRequest;
  pathParams: ProjectGenerateDocumentsPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectGenerateDocuments = (
  variables: ProjectGenerateDocumentsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectGenerateDocumentsResponse,
    ProjectGenerateDocumentsError,
    Schemas.GenerateDocumentsRequest,
    {},
    {},
    ProjectGenerateDocumentsPathParams
  >({
    url: '/projects/{project}/generate-documents',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectGenerateDocuments = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectGenerateDocumentsResponse,
      ProjectGenerateDocumentsError,
      ProjectGenerateDocumentsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectGenerateDocumentsResponse,
    ProjectGenerateDocumentsError,
    ProjectGenerateDocumentsVariables
  >({
    mutationFn: (variables: ProjectGenerateDocumentsVariables) =>
      fetchProjectGenerateDocuments({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectDocumentGeneratePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type ProjectDocumentGenerateError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload:
        | {
            /**
             * Error overview.
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example The chosen title, outline or brief is not allowed.
             */
            message: string;
          };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectDocumentGenerateResponse = {
  data: Schemas.SlimDocumentResource;
};

export type ProjectDocumentGenerateVariables = {
  body: Schemas.RegenerateDocumentRequest;
  pathParams: ProjectDocumentGeneratePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectDocumentGenerate = (
  variables: ProjectDocumentGenerateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectDocumentGenerateResponse,
    ProjectDocumentGenerateError,
    Schemas.RegenerateDocumentRequest,
    {},
    {},
    ProjectDocumentGeneratePathParams
  >({
    url: '/projects/{project}/documents/{document}/generate',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectDocumentGenerate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectDocumentGenerateResponse,
      ProjectDocumentGenerateError,
      ProjectDocumentGenerateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectDocumentGenerateResponse,
    ProjectDocumentGenerateError,
    ProjectDocumentGenerateVariables
  >({
    mutationFn: (variables: ProjectDocumentGenerateVariables) =>
      fetchProjectDocumentGenerate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectDocumentGenerateStreamPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type ProjectDocumentGenerateStreamQueryParams = {
  document_template_id?: number;
};

export type ProjectDocumentGenerateStreamError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 409;
      payload: {
        /**
         * Error overview.
         *
         * @example Generation already started
         */
        message: string;
      };
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectDocumentGenerateStreamVariables = {
  pathParams: ProjectDocumentGenerateStreamPathParams;
  queryParams?: ProjectDocumentGenerateStreamQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectDocumentGenerateStream = (
  variables: ProjectDocumentGenerateStreamVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    string,
    ProjectDocumentGenerateStreamError,
    undefined,
    {},
    ProjectDocumentGenerateStreamQueryParams,
    ProjectDocumentGenerateStreamPathParams
  >({
    url: '/projects/{project}/documents/{document}/generate-stream',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectDocumentGenerateStream = <TData = string,>(
  variables: ProjectDocumentGenerateStreamVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      string,
      ProjectDocumentGenerateStreamError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<string, ProjectDocumentGenerateStreamError, TData>(
    {
      queryKey: queryKeyFn({
        path: '/projects/{project}/documents/{document}/generate-stream',
        operationId: 'projectDocumentGenerateStream',
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchProjectDocumentGenerateStream(
          { ...fetcherOptions, ...variables },
          signal,
        ),
      ...options,
      ...queryOptions,
    },
  );
};

export type ProjectsDocumentsLinkSuggestionsStorePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type ProjectsDocumentsLinkSuggestionsStoreError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectsDocumentsLinkSuggestionsStoreResponse = {
  data: Schemas.LinkSuggestionResource[];
};

export type ProjectsDocumentsLinkSuggestionsStoreVariables = {
  body?: Record<string, any>;
  pathParams: ProjectsDocumentsLinkSuggestionsStorePathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * OpenAI will verify the link suggestions and broadcast events either approving or rejecting them.
 *
 * ### Broadcasting details
 *
 * The broadcast will also contain an "anchor text" if the AI has decided to change it.
 *
 * Broadcast channel: `documents.{documentId}`
 *
 * Broadcast event: `LinkSuggestionVerificationCompletedEvent`
 *
 * Broadcast event data:
 *
 * ```typescript
 * type LinkSuggestionResource = {
 *     link_id: int,
 *     keyword: string,
 *     is_verified: boolean,
 *     anchor_text: string
 * }
 * ```
 */
export const fetchProjectsDocumentsLinkSuggestionsStore = (
  variables: ProjectsDocumentsLinkSuggestionsStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsDocumentsLinkSuggestionsStoreResponse,
    ProjectsDocumentsLinkSuggestionsStoreError,
    Record<string, any>,
    {},
    {},
    ProjectsDocumentsLinkSuggestionsStorePathParams
  >({
    url: '/projects/{project}/documents/{document}/link-suggestions',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * OpenAI will verify the link suggestions and broadcast events either approving or rejecting them.
 *
 * ### Broadcasting details
 *
 * The broadcast will also contain an "anchor text" if the AI has decided to change it.
 *
 * Broadcast channel: `documents.{documentId}`
 *
 * Broadcast event: `LinkSuggestionVerificationCompletedEvent`
 *
 * Broadcast event data:
 *
 * ```typescript
 * type LinkSuggestionResource = {
 *     link_id: int,
 *     keyword: string,
 *     is_verified: boolean,
 *     anchor_text: string
 * }
 * ```
 */
export const useProjectsDocumentsLinkSuggestionsStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsDocumentsLinkSuggestionsStoreResponse,
      ProjectsDocumentsLinkSuggestionsStoreError,
      ProjectsDocumentsLinkSuggestionsStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectsDocumentsLinkSuggestionsStoreResponse,
    ProjectsDocumentsLinkSuggestionsStoreError,
    ProjectsDocumentsLinkSuggestionsStoreVariables
  >({
    mutationFn: (variables: ProjectsDocumentsLinkSuggestionsStoreVariables) =>
      fetchProjectsDocumentsLinkSuggestionsStore({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ListContentTypesQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id';
  sort_direction?: 'ASC' | 'DESC';
};

export type ListContentTypesError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ListContentTypesResponse = {
  data: Schemas.ContentTypeResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ListContentTypesVariables = {
  queryParams?: ListContentTypesQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchListContentTypes = (
  variables: ListContentTypesVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ListContentTypesResponse,
    ListContentTypesError,
    undefined,
    {},
    ListContentTypesQueryParams,
    {}
  >({ url: '/content-types', method: 'get', ...variables, signal });

export const useListContentTypes = <TData = ListContentTypesResponse,>(
  variables: ListContentTypesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListContentTypesResponse,
      ListContentTypesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ListContentTypesResponse,
    ListContentTypesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/content-types',
      operationId: 'listContentTypes',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListContentTypes({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ListDocumentElementByCategoryQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id' | 'name';
  sort_direction?: 'ASC' | 'DESC';
};

export type ListDocumentElementByCategoryError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ListDocumentElementByCategoryResponse = {
  data: Schemas.DocumentElementCategoryGroupResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ListDocumentElementByCategoryVariables = {
  queryParams?: ListDocumentElementByCategoryQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchListDocumentElementByCategory = (
  variables: ListDocumentElementByCategoryVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ListDocumentElementByCategoryResponse,
    ListDocumentElementByCategoryError,
    undefined,
    {},
    ListDocumentElementByCategoryQueryParams,
    {}
  >({
    url: '/document-elements/categories',
    method: 'get',
    ...variables,
    signal,
  });

export const useListDocumentElementByCategory = <
  TData = ListDocumentElementByCategoryResponse,
>(
  variables: ListDocumentElementByCategoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListDocumentElementByCategoryResponse,
      ListDocumentElementByCategoryError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ListDocumentElementByCategoryResponse,
    ListDocumentElementByCategoryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/document-elements/categories',
      operationId: 'listDocumentElementByCategory',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListDocumentElementByCategory(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ListDocumentElementsQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id' | 'name';
  sort_direction?: 'ASC' | 'DESC';
};

export type ListDocumentElementsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ListDocumentElementsResponse = {
  data: Schemas.DocumentElementResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ListDocumentElementsVariables = {
  queryParams?: ListDocumentElementsQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchListDocumentElements = (
  variables: ListDocumentElementsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ListDocumentElementsResponse,
    ListDocumentElementsError,
    undefined,
    {},
    ListDocumentElementsQueryParams,
    {}
  >({ url: '/document-elements', method: 'get', ...variables, signal });

export const useListDocumentElements = <TData = ListDocumentElementsResponse,>(
  variables: ListDocumentElementsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListDocumentElementsResponse,
      ListDocumentElementsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ListDocumentElementsResponse,
    ListDocumentElementsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/document-elements',
      operationId: 'listDocumentElements',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListDocumentElements({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectDocumentListsIndexPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectDocumentListsIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id';
  sort_direction?: 'ASC' | 'DESC';
};

export type ProjectDocumentListsIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectDocumentListsIndexResponse = {
  data: Schemas.ContentListResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ProjectDocumentListsIndexVariables = {
  pathParams: ProjectDocumentListsIndexPathParams;
  queryParams?: ProjectDocumentListsIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectDocumentListsIndex = (
  variables: ProjectDocumentListsIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectDocumentListsIndexResponse,
    ProjectDocumentListsIndexError,
    undefined,
    {},
    ProjectDocumentListsIndexQueryParams,
    ProjectDocumentListsIndexPathParams
  >({
    url: '/projects/{project}/document-lists',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectDocumentListsIndex = <
  TData = ProjectDocumentListsIndexResponse,
>(
  variables: ProjectDocumentListsIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectDocumentListsIndexResponse,
      ProjectDocumentListsIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectDocumentListsIndexResponse,
    ProjectDocumentListsIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/document-lists',
      operationId: 'projectDocumentListsIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectDocumentListsIndex(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectDocumentListsStorePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectDocumentListsStoreError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectDocumentListsStoreResponse = {
  data: Schemas.ContentListResource;
};

export type ProjectDocumentListsStoreVariables = {
  body: Schemas.CreateListRequest;
  pathParams: ProjectDocumentListsStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectDocumentListsStore = (
  variables: ProjectDocumentListsStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectDocumentListsStoreResponse,
    ProjectDocumentListsStoreError,
    Schemas.CreateListRequest,
    {},
    {},
    ProjectDocumentListsStorePathParams
  >({
    url: '/projects/{project}/document-lists',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectDocumentListsStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectDocumentListsStoreResponse,
      ProjectDocumentListsStoreError,
      ProjectDocumentListsStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectDocumentListsStoreResponse,
    ProjectDocumentListsStoreError,
    ProjectDocumentListsStoreVariables
  >({
    mutationFn: (variables: ProjectDocumentListsStoreVariables) =>
      fetchProjectDocumentListsStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectDocumentListsUpdatePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document list ID
   */
  documentList: number;
};

export type ProjectDocumentListsUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectDocumentListsUpdateResponse = {
  data: Schemas.ContentListResource;
};

export type ProjectDocumentListsUpdateVariables = {
  body: Schemas.UpdateRankTrackingListRequest;
  pathParams: ProjectDocumentListsUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectDocumentListsUpdate = (
  variables: ProjectDocumentListsUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectDocumentListsUpdateResponse,
    ProjectDocumentListsUpdateError,
    Schemas.UpdateRankTrackingListRequest,
    {},
    {},
    ProjectDocumentListsUpdatePathParams
  >({
    url: '/projects/{project}/document-lists/{documentList}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectDocumentListsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectDocumentListsUpdateResponse,
      ProjectDocumentListsUpdateError,
      ProjectDocumentListsUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectDocumentListsUpdateResponse,
    ProjectDocumentListsUpdateError,
    ProjectDocumentListsUpdateVariables
  >({
    mutationFn: (variables: ProjectDocumentListsUpdateVariables) =>
      fetchProjectDocumentListsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectDocumentListsDestroyPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document list ID
   */
  documentList: number;
};

export type ProjectDocumentListsDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectDocumentListsDestroyVariables = {
  pathParams: ProjectDocumentListsDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectDocumentListsDestroy = (
  variables: ProjectDocumentListsDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    ProjectDocumentListsDestroyError,
    undefined,
    {},
    {},
    ProjectDocumentListsDestroyPathParams
  >({
    url: '/projects/{project}/document-lists/{documentList}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectDocumentListsDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectDocumentListsDestroyError,
      ProjectDocumentListsDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectDocumentListsDestroyError,
    ProjectDocumentListsDestroyVariables
  >({
    mutationFn: (variables: ProjectDocumentListsDestroyVariables) =>
      fetchProjectDocumentListsDestroy({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectDocumentListItemStorePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document list ID
   */
  documentList: number;
};

export type ProjectDocumentListItemStoreError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectDocumentListItemStoreResponse = {
  data: Schemas.DocumentResource[];
};

export type ProjectDocumentListItemStoreVariables = {
  body: Schemas.AddDocumentListItemsRequest;
  pathParams: ProjectDocumentListItemStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectDocumentListItemStore = (
  variables: ProjectDocumentListItemStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectDocumentListItemStoreResponse,
    ProjectDocumentListItemStoreError,
    Schemas.AddDocumentListItemsRequest,
    {},
    {},
    ProjectDocumentListItemStorePathParams
  >({
    url: '/projects/{project}/document-content-lists/{documentList}/add-items',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectDocumentListItemStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectDocumentListItemStoreResponse,
      ProjectDocumentListItemStoreError,
      ProjectDocumentListItemStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectDocumentListItemStoreResponse,
    ProjectDocumentListItemStoreError,
    ProjectDocumentListItemStoreVariables
  >({
    mutationFn: (variables: ProjectDocumentListItemStoreVariables) =>
      fetchProjectDocumentListItemStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectDocumentListItemDestroyPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document list ID
   */
  documentList: number;
};

export type ProjectDocumentListItemDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectDocumentListItemDestroyVariables = {
  body: Schemas.RemoveDocumentListItemsRequest;
  pathParams: ProjectDocumentListItemDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectDocumentListItemDestroy = (
  variables: ProjectDocumentListItemDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    ProjectDocumentListItemDestroyError,
    Schemas.RemoveDocumentListItemsRequest,
    {},
    {},
    ProjectDocumentListItemDestroyPathParams
  >({
    url: '/projects/{project}/document-content-lists/{documentList}/remove-items',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectDocumentListItemDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectDocumentListItemDestroyError,
      ProjectDocumentListItemDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectDocumentListItemDestroyError,
    ProjectDocumentListItemDestroyVariables
  >({
    mutationFn: (variables: ProjectDocumentListItemDestroyVariables) =>
      fetchProjectDocumentListItemDestroy({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DocumentTemplateDuplicatePathParams = {
  /**
   * The document template ID
   */
  documentTemplate: number;
};

export type DocumentTemplateDuplicateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type DocumentTemplateDuplicateResponse = {
  data: Schemas.DocumentTemplateResource;
};

export type DocumentTemplateDuplicateVariables = {
  body: Schemas.DocumentTemplateDuplicateRequest;
  pathParams: DocumentTemplateDuplicatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentTemplateDuplicate = (
  variables: DocumentTemplateDuplicateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    DocumentTemplateDuplicateResponse,
    DocumentTemplateDuplicateError,
    Schemas.DocumentTemplateDuplicateRequest,
    {},
    {},
    DocumentTemplateDuplicatePathParams
  >({
    url: '/document-templates/{documentTemplate}/duplicate',
    method: 'post',
    ...variables,
    signal,
  });

export const useDocumentTemplateDuplicate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DocumentTemplateDuplicateResponse,
      DocumentTemplateDuplicateError,
      DocumentTemplateDuplicateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    DocumentTemplateDuplicateResponse,
    DocumentTemplateDuplicateError,
    DocumentTemplateDuplicateVariables
  >({
    mutationFn: (variables: DocumentTemplateDuplicateVariables) =>
      fetchDocumentTemplateDuplicate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateDocumentTemplatePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type CreateDocumentTemplateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type CreateDocumentTemplateResponse = {
  data: Schemas.DocumentTemplateResource;
};

export type CreateDocumentTemplateVariables = {
  body?: Schemas.CreateDocumentTemplateRequest;
  pathParams: CreateDocumentTemplatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchCreateDocumentTemplate = (
  variables: CreateDocumentTemplateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    CreateDocumentTemplateResponse,
    CreateDocumentTemplateError,
    Schemas.CreateDocumentTemplateRequest,
    {},
    {},
    CreateDocumentTemplatePathParams
  >({
    url: '/projects/{project}/document-templates',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateDocumentTemplate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateDocumentTemplateResponse,
      CreateDocumentTemplateError,
      CreateDocumentTemplateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    CreateDocumentTemplateResponse,
    CreateDocumentTemplateError,
    CreateDocumentTemplateVariables
  >({
    mutationFn: (variables: CreateDocumentTemplateVariables) =>
      fetchCreateDocumentTemplate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type TestSectionPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type TestSectionError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example The content you provided is not allowed.
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Upgrade required to use Document Template
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type TestSectionResponse = {
  data: Schemas.DocumentTemplateSectionTestResource;
};

export type TestSectionVariables = {
  body: Schemas.DocumentTemplateSectionTestRequest;
  pathParams: TestSectionPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Test a document template section with the provided values *before* adding it.
 */
export const fetchTestSection = (
  variables: TestSectionVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    TestSectionResponse,
    TestSectionError,
    Schemas.DocumentTemplateSectionTestRequest,
    {},
    {},
    TestSectionPathParams
  >({
    url: '/projects/{project}/document-template-section-tester',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Test a document template section with the provided values *before* adding it.
 */
export const useTestSection = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      TestSectionResponse,
      TestSectionError,
      TestSectionVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    TestSectionResponse,
    TestSectionError,
    TestSectionVariables
  >({
    mutationFn: (variables: TestSectionVariables) =>
      fetchTestSection({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateDocumentTemplatePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document template ID
   */
  documentTemplate: number;
};

export type UpdateDocumentTemplateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type UpdateDocumentTemplateResponse = {
  data: Schemas.DocumentTemplateResource;
};

export type UpdateDocumentTemplateVariables = {
  body?: Schemas.UpdateDocumentTemplateRequest;
  pathParams: UpdateDocumentTemplatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchUpdateDocumentTemplate = (
  variables: UpdateDocumentTemplateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    UpdateDocumentTemplateResponse,
    UpdateDocumentTemplateError,
    Schemas.UpdateDocumentTemplateRequest,
    {},
    {},
    UpdateDocumentTemplatePathParams
  >({
    url: '/projects/{project}/document-templates/{documentTemplate}',
    method: 'patch',
    ...variables,
    signal,
  });

export const useUpdateDocumentTemplate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateDocumentTemplateResponse,
      UpdateDocumentTemplateError,
      UpdateDocumentTemplateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    UpdateDocumentTemplateResponse,
    UpdateDocumentTemplateError,
    UpdateDocumentTemplateVariables
  >({
    mutationFn: (variables: UpdateDocumentTemplateVariables) =>
      fetchUpdateDocumentTemplate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListDocumentTemplatesQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id';
  sort_direction?: 'ASC' | 'DESC';
  filters?: {
    project_id?: number | null;
  };
};

export type ListDocumentTemplatesError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ListDocumentTemplatesResponse = {
  data: Schemas.DocumentTemplateResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ListDocumentTemplatesVariables = {
  queryParams?: ListDocumentTemplatesQueryParams;
} & OpenapiContext['fetcherOptions'];

/**
 * ### Filtering
 *
 * By default, it returns all that are not associated with any project.
 *
 * However, you can filter the document templates by **filters[project_id]** query parameter.
 */
export const fetchListDocumentTemplates = (
  variables: ListDocumentTemplatesVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ListDocumentTemplatesResponse,
    ListDocumentTemplatesError,
    undefined,
    {},
    ListDocumentTemplatesQueryParams,
    {}
  >({ url: '/document-templates', method: 'get', ...variables, signal });

/**
 * ### Filtering
 *
 * By default, it returns all that are not associated with any project.
 *
 * However, you can filter the document templates by **filters[project_id]** query parameter.
 */
export const useListDocumentTemplates = <
  TData = ListDocumentTemplatesResponse,
>(
  variables: ListDocumentTemplatesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListDocumentTemplatesResponse,
      ListDocumentTemplatesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ListDocumentTemplatesResponse,
    ListDocumentTemplatesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/document-templates',
      operationId: 'listDocumentTemplates',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListDocumentTemplates({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ViewDocumentTemplatePathParams = {
  /**
   * The document template ID
   */
  documentTemplate: number;
};

export type ViewDocumentTemplateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ViewDocumentTemplateResponse = {
  data: Schemas.DocumentTemplateResource;
};

export type ViewDocumentTemplateVariables = {
  pathParams: ViewDocumentTemplatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchViewDocumentTemplate = (
  variables: ViewDocumentTemplateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ViewDocumentTemplateResponse,
    ViewDocumentTemplateError,
    undefined,
    {},
    {},
    ViewDocumentTemplatePathParams
  >({
    url: '/document-templates/{documentTemplate}',
    method: 'get',
    ...variables,
    signal,
  });

export const useViewDocumentTemplate = <TData = ViewDocumentTemplateResponse,>(
  variables: ViewDocumentTemplateVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ViewDocumentTemplateResponse,
      ViewDocumentTemplateError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ViewDocumentTemplateResponse,
    ViewDocumentTemplateError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/document-templates/{documentTemplate}',
      operationId: 'viewDocumentTemplate',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchViewDocumentTemplate({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeleteDocumentTemplatePathParams = {
  /**
   * The document template ID
   */
  documentTemplate: number;
};

export type DeleteDocumentTemplateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type DeleteDocumentTemplateVariables = {
  pathParams: DeleteDocumentTemplatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDeleteDocumentTemplate = (
  variables: DeleteDocumentTemplateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    DeleteDocumentTemplateError,
    undefined,
    {},
    {},
    DeleteDocumentTemplatePathParams
  >({
    url: '/document-templates/{documentTemplate}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteDocumentTemplate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteDocumentTemplateError,
      DeleteDocumentTemplateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteDocumentTemplateError,
    DeleteDocumentTemplateVariables
  >({
    mutationFn: (variables: DeleteDocumentTemplateVariables) =>
      fetchDeleteDocumentTemplate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RefreshKeywordReportPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type RefreshKeywordReportError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Content audits limit exceeded
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 409;
      payload: {
        /**
         * Error overview.
         *
         * @example Keyword report cannot be refreshed at this time
         */
        message: string;
      };
    }
>;

export type RefreshKeywordReportResponse = {
  data: Schemas.DocumentResource;
};

export type RefreshKeywordReportVariables = {
  body?: Record<string, any>;
  pathParams: RefreshKeywordReportPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Can only be refreshed every 7 days
 */
export const fetchRefreshKeywordReport = (
  variables: RefreshKeywordReportVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    RefreshKeywordReportResponse,
    RefreshKeywordReportError,
    Record<string, any>,
    {},
    {},
    RefreshKeywordReportPathParams
  >({
    url: '/projects/{project}/documents/{document}/regenerate-keyword-report',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Can only be refreshed every 7 days
 */
export const useRefreshKeywordReport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      RefreshKeywordReportResponse,
      RefreshKeywordReportError,
      RefreshKeywordReportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    RefreshKeywordReportResponse,
    RefreshKeywordReportError,
    RefreshKeywordReportVariables
  >({
    mutationFn: (variables: RefreshKeywordReportVariables) =>
      fetchRefreshKeywordReport({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeKeywordPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type ChangeKeywordError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example The chosen keyword is not allowed
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Content audits limit reached.
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ChangeKeywordResponse = {
  data: Schemas.DocumentResource;
};

export type ChangeKeywordVariables = {
  body: Schemas.DocumentKeywordChangeRequest;
  pathParams: ChangeKeywordPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchChangeKeyword = (
  variables: ChangeKeywordVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ChangeKeywordResponse,
    ChangeKeywordError,
    Schemas.DocumentKeywordChangeRequest,
    {},
    {},
    ChangeKeywordPathParams
  >({
    url: '/projects/{project}/documents/{document}/change-keyword',
    method: 'put',
    ...variables,
    signal,
  });

export const useChangeKeyword = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ChangeKeywordResponse,
      ChangeKeywordError,
      ChangeKeywordVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ChangeKeywordResponse,
    ChangeKeywordError,
    ChangeKeywordVariables
  >({
    mutationFn: (variables: ChangeKeywordVariables) =>
      fetchChangeKeyword({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DocumentDocumentKeywordContentSuggestionsPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type DocumentDocumentKeywordContentSuggestionsError =
  Fetcher.ErrorWrapper<
    | {
        status: 401;
        payload: Responses.AuthenticationException;
      }
    | {
        status: 403;
        payload: Responses.AuthorizationException;
      }
    | {
        status: 404;
        payload: Responses.ModelNotFoundException;
      }
    | {
        status: 422;
        payload: Responses.ValidationException;
      }
  >;

export type DocumentDocumentKeywordContentSuggestionsResponse = {
  data: Schemas.DocumentKeywordContentSuggestionResource;
};

export type DocumentDocumentKeywordContentSuggestionsVariables = {
  body: Schemas.DocumentKeywordContentSuggestionsRequest;
  pathParams: DocumentDocumentKeywordContentSuggestionsPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentDocumentKeywordContentSuggestions = (
  variables: DocumentDocumentKeywordContentSuggestionsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    DocumentDocumentKeywordContentSuggestionsResponse,
    DocumentDocumentKeywordContentSuggestionsError,
    Schemas.DocumentKeywordContentSuggestionsRequest,
    {},
    {},
    DocumentDocumentKeywordContentSuggestionsPathParams
  >({
    url: '/projects/{project}/documents/{document}/keyword-content-suggestions',
    method: 'post',
    ...variables,
    signal,
  });

export const useDocumentDocumentKeywordContentSuggestions = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DocumentDocumentKeywordContentSuggestionsResponse,
      DocumentDocumentKeywordContentSuggestionsError,
      DocumentDocumentKeywordContentSuggestionsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    DocumentDocumentKeywordContentSuggestionsResponse,
    DocumentDocumentKeywordContentSuggestionsError,
    DocumentDocumentKeywordContentSuggestionsVariables
  >({
    mutationFn: (
      variables: DocumentDocumentKeywordContentSuggestionsVariables,
    ) =>
      fetchDocumentDocumentKeywordContentSuggestions({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectKeywordGapsPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type ProjectKeywordGapsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example Google Search Console connection expired
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Upgrade required to use Keyword Gaps
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectKeywordGapsResponse = {
  data: Schemas.KeywordGapGroupResource[];
};

export type ProjectKeywordGapsVariables = {
  pathParams: ProjectKeywordGapsPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Keyword gaps are keywords that the document URL is receiving clicks for, but hasn't mentioned in the text.
 */
export const fetchProjectKeywordGaps = (
  variables: ProjectKeywordGapsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectKeywordGapsResponse,
    ProjectKeywordGapsError,
    undefined,
    {},
    {},
    ProjectKeywordGapsPathParams
  >({
    url: '/projects/{project}/documents/{document}/keyword-gaps',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Keyword gaps are keywords that the document URL is receiving clicks for, but hasn't mentioned in the text.
 */
export const useProjectKeywordGaps = <TData = ProjectKeywordGapsResponse,>(
  variables: ProjectKeywordGapsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectKeywordGapsResponse,
      ProjectKeywordGapsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectKeywordGapsResponse,
    ProjectKeywordGapsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/documents/{document}/keyword-gaps',
      operationId: 'projectKeywordGaps',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectKeywordGaps({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DocumentMetaDescriptionSuggestionsPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type DocumentMetaDescriptionSuggestionsQueryParams = {
  type?: Schemas.DocumentMetaDescriptionSuggestionsPromptType;
  /**
   * Used when type is not "default"
   *
   * @minLength 1
   * @maxLength 1000
   */
  context?: string | null;
};

export type DocumentMetaDescriptionSuggestionsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type DocumentMetaDescriptionSuggestionsResponse = {
  data: Schemas.MetaDescriptionResource[];
};

export type DocumentMetaDescriptionSuggestionsVariables = {
  pathParams: DocumentMetaDescriptionSuggestionsPathParams;
  queryParams?: DocumentMetaDescriptionSuggestionsQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentMetaDescriptionSuggestions = (
  variables: DocumentMetaDescriptionSuggestionsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    DocumentMetaDescriptionSuggestionsResponse,
    DocumentMetaDescriptionSuggestionsError,
    undefined,
    {},
    DocumentMetaDescriptionSuggestionsQueryParams,
    DocumentMetaDescriptionSuggestionsPathParams
  >({
    url: '/projects/{project}/documents/{document}/meta-description-suggestions',
    method: 'get',
    ...variables,
    signal,
  });

export const useDocumentMetaDescriptionSuggestions = <
  TData = DocumentMetaDescriptionSuggestionsResponse,
>(
  variables: DocumentMetaDescriptionSuggestionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DocumentMetaDescriptionSuggestionsResponse,
      DocumentMetaDescriptionSuggestionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    DocumentMetaDescriptionSuggestionsResponse,
    DocumentMetaDescriptionSuggestionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/documents/{document}/meta-description-suggestions',
      operationId: 'documentMetaDescriptionSuggestions',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchDocumentMetaDescriptionSuggestions(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type DocumentMetaTitleSuggestionsPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type DocumentMetaTitleSuggestionsQueryParams = {
  type?: Schemas.DocumentMetaTitleSuggestionsPromptType;
  /**
   * @minLength 1
   * @maxLength 1000
   */
  context?: string;
};

export type DocumentMetaTitleSuggestionsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload:
        | {
            /**
             * Error overview.
             *
             * @example The chosen keyword is not allowed
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example The content you have written is not allowed
             */
            message: string;
          };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type DocumentMetaTitleSuggestionsResponse = {
  data: Schemas.DocumentMetaTitleResource[];
};

export type DocumentMetaTitleSuggestionsVariables = {
  pathParams: DocumentMetaTitleSuggestionsPathParams;
  queryParams?: DocumentMetaTitleSuggestionsQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentMetaTitleSuggestions = (
  variables: DocumentMetaTitleSuggestionsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    DocumentMetaTitleSuggestionsResponse,
    DocumentMetaTitleSuggestionsError,
    undefined,
    {},
    DocumentMetaTitleSuggestionsQueryParams,
    DocumentMetaTitleSuggestionsPathParams
  >({
    url: '/projects/{project}/documents/{document}/meta-title-suggestions',
    method: 'get',
    ...variables,
    signal,
  });

export const useDocumentMetaTitleSuggestions = <
  TData = DocumentMetaTitleSuggestionsResponse,
>(
  variables: DocumentMetaTitleSuggestionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DocumentMetaTitleSuggestionsResponse,
      DocumentMetaTitleSuggestionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    DocumentMetaTitleSuggestionsResponse,
    DocumentMetaTitleSuggestionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/documents/{document}/meta-title-suggestions',
      operationId: 'documentMetaTitleSuggestions',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchDocumentMetaTitleSuggestions(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type DocumentsChatShowPathParams = {
  /**
   * The document ID
   */
  document: number;
};

export type DocumentsChatShowError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type DocumentsChatShowResponse = {
  data: Schemas.DocumentChatResource;
};

export type DocumentsChatShowVariables = {
  pathParams: DocumentsChatShowPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentsChatShow = (
  variables: DocumentsChatShowVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    DocumentsChatShowResponse,
    DocumentsChatShowError,
    undefined,
    {},
    {},
    DocumentsChatShowPathParams
  >({ url: '/documents/{document}/chat', method: 'get', ...variables, signal });

export const useDocumentsChatShow = <TData = DocumentsChatShowResponse,>(
  variables: DocumentsChatShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DocumentsChatShowResponse,
      DocumentsChatShowError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    DocumentsChatShowResponse,
    DocumentsChatShowError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/documents/{document}/chat',
      operationId: 'documentsChatShow',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchDocumentsChatShow({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DocumentsChatUpdatePathParams = {
  /**
   * The document ID
   */
  document: number;
};

export type DocumentsChatUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type DocumentsChatUpdateResponse = {
  data: Schemas.DocumentChatResource;
};

export type DocumentsChatUpdateVariables = {
  body: Schemas.UpdateDocumentChatRequest;
  pathParams: DocumentsChatUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentsChatUpdate = (
  variables: DocumentsChatUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    DocumentsChatUpdateResponse,
    DocumentsChatUpdateError,
    Schemas.UpdateDocumentChatRequest,
    {},
    {},
    DocumentsChatUpdatePathParams
  >({ url: '/documents/{document}/chat', method: 'put', ...variables, signal });

export const useDocumentsChatUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DocumentsChatUpdateResponse,
      DocumentsChatUpdateError,
      DocumentsChatUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    DocumentsChatUpdateResponse,
    DocumentsChatUpdateError,
    DocumentsChatUpdateVariables
  >({
    mutationFn: (variables: DocumentsChatUpdateVariables) =>
      fetchDocumentsChatUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DocumentChatClearPathParams = {
  /**
   * The document ID
   */
  document: number;
};

export type DocumentChatClearError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type DocumentChatClearVariables = {
  body?: Record<string, any>;
  pathParams: DocumentChatClearPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentChatClear = (
  variables: DocumentChatClearVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    DocumentChatClearError,
    Record<string, any>,
    {},
    {},
    DocumentChatClearPathParams
  >({
    url: '/documents/{document}/chat/clear',
    method: 'post',
    ...variables,
    signal,
  });

export const useDocumentChatClear = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DocumentChatClearError,
      DocumentChatClearVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    DocumentChatClearError,
    DocumentChatClearVariables
  >({
    mutationFn: (variables: DocumentChatClearVariables) =>
      fetchDocumentChatClear({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DocumentsChatMessagesIndexPathParams = {
  /**
   * The document ID
   */
  document: number;
};

export type DocumentsChatMessagesIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type DocumentsChatMessagesIndexResponse = {
  data: Schemas.AiChatMessageResource[];
};

export type DocumentsChatMessagesIndexVariables = {
  pathParams: DocumentsChatMessagesIndexPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentsChatMessagesIndex = (
  variables: DocumentsChatMessagesIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    DocumentsChatMessagesIndexResponse,
    DocumentsChatMessagesIndexError,
    undefined,
    {},
    {},
    DocumentsChatMessagesIndexPathParams
  >({
    url: '/documents/{document}/chat-messages',
    method: 'get',
    ...variables,
    signal,
  });

export const useDocumentsChatMessagesIndex = <
  TData = DocumentsChatMessagesIndexResponse,
>(
  variables: DocumentsChatMessagesIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DocumentsChatMessagesIndexResponse,
      DocumentsChatMessagesIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    DocumentsChatMessagesIndexResponse,
    DocumentsChatMessagesIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/documents/{document}/chat-messages',
      operationId: 'documentsChatMessagesIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchDocumentsChatMessagesIndex(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type DocumentsChatMessagesStorePathParams = {
  /**
   * The document ID
   */
  document: number;
};

export type DocumentsChatMessagesStoreError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type DocumentsChatMessagesStoreResponse = {
  data: Schemas.AiChatMessageResource;
};

export type DocumentsChatMessagesStoreVariables = {
  body: Schemas.CreateChatMessageRequest;
  pathParams: DocumentsChatMessagesStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentsChatMessagesStore = (
  variables: DocumentsChatMessagesStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    DocumentsChatMessagesStoreResponse,
    DocumentsChatMessagesStoreError,
    Schemas.CreateChatMessageRequest,
    {},
    {},
    DocumentsChatMessagesStorePathParams
  >({
    url: '/documents/{document}/chat-messages',
    method: 'post',
    ...variables,
    signal,
  });

export const useDocumentsChatMessagesStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DocumentsChatMessagesStoreResponse,
      DocumentsChatMessagesStoreError,
      DocumentsChatMessagesStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    DocumentsChatMessagesStoreResponse,
    DocumentsChatMessagesStoreError,
    DocumentsChatMessagesStoreVariables
  >({
    mutationFn: (variables: DocumentsChatMessagesStoreVariables) =>
      fetchDocumentsChatMessagesStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DocumentsChatMessagesDestroyPathParams = {
  /**
   * The document ID
   */
  document: number;
  /**
   * The chat message ID
   */
  chatMessage: number;
};

export type DocumentsChatMessagesDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type DocumentsChatMessagesDestroyVariables = {
  pathParams: DocumentsChatMessagesDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Will also delete all messages newer to clear the history.
 */
export const fetchDocumentsChatMessagesDestroy = (
  variables: DocumentsChatMessagesDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    DocumentsChatMessagesDestroyError,
    undefined,
    {},
    {},
    DocumentsChatMessagesDestroyPathParams
  >({
    url: '/documents/{document}/chat-messages/{chatMessage}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Will also delete all messages newer to clear the history.
 */
export const useDocumentsChatMessagesDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DocumentsChatMessagesDestroyError,
      DocumentsChatMessagesDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    DocumentsChatMessagesDestroyError,
    DocumentsChatMessagesDestroyVariables
  >({
    mutationFn: (variables: DocumentsChatMessagesDestroyVariables) =>
      fetchDocumentsChatMessagesDestroy({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DocumentChatStreamPathParams = {
  /**
   * The document ID
   */
  document: number;
};

export type DocumentChatStreamError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type DocumentChatStreamVariables = {
  pathParams: DocumentChatStreamPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentChatStream = (
  variables: DocumentChatStreamVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    string,
    DocumentChatStreamError,
    undefined,
    {},
    {},
    DocumentChatStreamPathParams
  >({
    url: '/documents/{document}/chat/stream',
    method: 'get',
    ...variables,
    signal,
  });

export const useDocumentChatStream = <TData = string,>(
  variables: DocumentChatStreamVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, DocumentChatStreamError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<string, DocumentChatStreamError, TData>({
    queryKey: queryKeyFn({
      path: '/documents/{document}/chat/stream',
      operationId: 'documentChatStream',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchDocumentChatStream({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DocumentDocumentContentPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type DocumentDocumentContentError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type DocumentDocumentContentResponse = {
  data: Schemas.DocumentResource;
};

export type DocumentDocumentContentVariables = {
  body?: Schemas.UpdateDocumentContentRequest;
  pathParams: DocumentDocumentContentPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentDocumentContent = (
  variables: DocumentDocumentContentVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    DocumentDocumentContentResponse,
    DocumentDocumentContentError,
    Schemas.UpdateDocumentContentRequest,
    {},
    {},
    DocumentDocumentContentPathParams
  >({
    url: '/projects/{project}/documents/{document}/content',
    method: 'patch',
    ...variables,
    signal,
  });

export const useDocumentDocumentContent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DocumentDocumentContentResponse,
      DocumentDocumentContentError,
      DocumentDocumentContentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    DocumentDocumentContentResponse,
    DocumentDocumentContentError,
    DocumentDocumentContentVariables
  >({
    mutationFn: (variables: DocumentDocumentContentVariables) =>
      fetchDocumentDocumentContent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DocumentExportPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type DocumentExportError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type DocumentExportResponse = {
  data: Schemas.DocumentExportResource;
};

export type DocumentExportVariables = {
  body: Schemas.DocumentExportRequest;
  pathParams: DocumentExportPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentExport = (
  variables: DocumentExportVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    DocumentExportResponse,
    DocumentExportError,
    Schemas.DocumentExportRequest,
    {},
    {},
    DocumentExportPathParams
  >({
    url: '/projects/{project}/documents/{document}/export',
    method: 'post',
    ...variables,
    signal,
  });

export const useDocumentExport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DocumentExportResponse,
      DocumentExportError,
      DocumentExportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    DocumentExportResponse,
    DocumentExportError,
    DocumentExportVariables
  >({
    mutationFn: (variables: DocumentExportVariables) =>
      fetchDocumentExport({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DocumentDocumentKeywordQuestionsPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type DocumentDocumentKeywordQuestionsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type DocumentDocumentKeywordQuestionsResponse = {
  data: Schemas.DocumentKeywordQuestionResource[];
};

export type DocumentDocumentKeywordQuestionsVariables = {
  body?: Record<string, any>;
  pathParams: DocumentDocumentKeywordQuestionsPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentDocumentKeywordQuestions = (
  variables: DocumentDocumentKeywordQuestionsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    DocumentDocumentKeywordQuestionsResponse,
    DocumentDocumentKeywordQuestionsError,
    Record<string, any>,
    {},
    {},
    DocumentDocumentKeywordQuestionsPathParams
  >({
    url: '/projects/{project}/documents/{document}/keyword-questions',
    method: 'post',
    ...variables,
    signal,
  });

export const useDocumentDocumentKeywordQuestions = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DocumentDocumentKeywordQuestionsResponse,
      DocumentDocumentKeywordQuestionsError,
      DocumentDocumentKeywordQuestionsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    DocumentDocumentKeywordQuestionsResponse,
    DocumentDocumentKeywordQuestionsError,
    DocumentDocumentKeywordQuestionsVariables
  >({
    mutationFn: (variables: DocumentDocumentKeywordQuestionsVariables) =>
      fetchDocumentDocumentKeywordQuestions({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectKeywordResearchAiExplorerPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectKeywordResearchAiExplorerError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example Moderated input
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Hard limit reached
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectKeywordResearchAiExplorerResponse = {
  data: Schemas.SlimKeywordResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ProjectKeywordResearchAiExplorerVariables = {
  body?: Schemas.AiKeywordResearchRequest;
  pathParams: ProjectKeywordResearchAiExplorerPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectKeywordResearchAiExplorer = (
  variables: ProjectKeywordResearchAiExplorerVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectKeywordResearchAiExplorerResponse,
    ProjectKeywordResearchAiExplorerError,
    Schemas.AiKeywordResearchRequest,
    {},
    {},
    ProjectKeywordResearchAiExplorerPathParams
  >({
    url: '/projects/{project}/keyword-research/ai-explorer',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectKeywordResearchAiExplorer = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectKeywordResearchAiExplorerResponse,
      ProjectKeywordResearchAiExplorerError,
      ProjectKeywordResearchAiExplorerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectKeywordResearchAiExplorerResponse,
    ProjectKeywordResearchAiExplorerError,
    ProjectKeywordResearchAiExplorerVariables
  >({
    mutationFn: (variables: ProjectKeywordResearchAiExplorerVariables) =>
      fetchProjectKeywordResearchAiExplorer({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectKeywordResearchRelatedExplorerPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectKeywordResearchRelatedExplorerError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example Moderated input
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Hard limit reached
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectKeywordResearchRelatedExplorerResponse = {
  data: Schemas.SlimKeywordResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ProjectKeywordResearchRelatedExplorerVariables = {
  body?: Schemas.KeywordResearchRequest;
  pathParams: ProjectKeywordResearchRelatedExplorerPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectKeywordResearchRelatedExplorer = (
  variables: ProjectKeywordResearchRelatedExplorerVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectKeywordResearchRelatedExplorerResponse,
    ProjectKeywordResearchRelatedExplorerError,
    Schemas.KeywordResearchRequest,
    {},
    {},
    ProjectKeywordResearchRelatedExplorerPathParams
  >({
    url: '/projects/{project}/keyword-research/related-explorer',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectKeywordResearchRelatedExplorer = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectKeywordResearchRelatedExplorerResponse,
      ProjectKeywordResearchRelatedExplorerError,
      ProjectKeywordResearchRelatedExplorerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectKeywordResearchRelatedExplorerResponse,
    ProjectKeywordResearchRelatedExplorerError,
    ProjectKeywordResearchRelatedExplorerVariables
  >({
    mutationFn: (variables: ProjectKeywordResearchRelatedExplorerVariables) =>
      fetchProjectKeywordResearchRelatedExplorer({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectKeywordResearchAudienceExplorerPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectKeywordResearchAudienceExplorerError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example Moderated input
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Hard limit reached
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectKeywordResearchAudienceExplorerResponse = {
  data: Schemas.SlimKeywordResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ProjectKeywordResearchAudienceExplorerVariables = {
  body?: Schemas.AudienceKeywordResearchRequest;
  pathParams: ProjectKeywordResearchAudienceExplorerPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectKeywordResearchAudienceExplorer = (
  variables: ProjectKeywordResearchAudienceExplorerVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectKeywordResearchAudienceExplorerResponse,
    ProjectKeywordResearchAudienceExplorerError,
    Schemas.AudienceKeywordResearchRequest,
    {},
    {},
    ProjectKeywordResearchAudienceExplorerPathParams
  >({
    url: '/projects/{project}/keyword-research/audience-explorer',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectKeywordResearchAudienceExplorer = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectKeywordResearchAudienceExplorerResponse,
      ProjectKeywordResearchAudienceExplorerError,
      ProjectKeywordResearchAudienceExplorerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectKeywordResearchAudienceExplorerResponse,
    ProjectKeywordResearchAudienceExplorerError,
    ProjectKeywordResearchAudienceExplorerVariables
  >({
    mutationFn: (variables: ProjectKeywordResearchAudienceExplorerVariables) =>
      fetchProjectKeywordResearchAudienceExplorer({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectKeywordResearchSearchVolumePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectKeywordResearchSearchVolumeError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example No credits available for this feature.
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectKeywordResearchSearchVolumeResponse = {
  data: Schemas.SlimKeywordResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ProjectKeywordResearchSearchVolumeVariables = {
  body?: Schemas.SearchVolumeKeywordResearchRequest;
  pathParams: ProjectKeywordResearchSearchVolumePathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Allows user to get keyword data from an array of keyword strings.
 */
export const fetchProjectKeywordResearchSearchVolume = (
  variables: ProjectKeywordResearchSearchVolumeVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectKeywordResearchSearchVolumeResponse,
    ProjectKeywordResearchSearchVolumeError,
    Schemas.SearchVolumeKeywordResearchRequest,
    {},
    {},
    ProjectKeywordResearchSearchVolumePathParams
  >({
    url: '/projects/{project}/keyword-research/search-volume',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Allows user to get keyword data from an array of keyword strings.
 */
export const useProjectKeywordResearchSearchVolume = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectKeywordResearchSearchVolumeResponse,
      ProjectKeywordResearchSearchVolumeError,
      ProjectKeywordResearchSearchVolumeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectKeywordResearchSearchVolumeResponse,
    ProjectKeywordResearchSearchVolumeError,
    ProjectKeywordResearchSearchVolumeVariables
  >({
    mutationFn: (variables: ProjectKeywordResearchSearchVolumeVariables) =>
      fetchProjectKeywordResearchSearchVolume({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectKeywordResearchExportPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectKeywordResearchExportError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectKeywordResearchExportVariables = {
  body: Schemas.KeywordExportRequest;
  pathParams: ProjectKeywordResearchExportPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectKeywordResearchExport = (
  variables: ProjectKeywordResearchExportVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    string,
    ProjectKeywordResearchExportError,
    Schemas.KeywordExportRequest,
    {},
    {},
    ProjectKeywordResearchExportPathParams
  >({
    url: '/projects/{project}/keyword-research/export',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectKeywordResearchExport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      ProjectKeywordResearchExportError,
      ProjectKeywordResearchExportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    string,
    ProjectKeywordResearchExportError,
    ProjectKeywordResearchExportVariables
  >({
    mutationFn: (variables: ProjectKeywordResearchExportVariables) =>
      fetchProjectKeywordResearchExport({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListScannedKeywordsPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ListScannedKeywordsQueryParams = {
  filters?: {
    list_id?: number;
  };
};

export type ListScannedKeywordsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Upgrade required to use Keyword scanner
         */
        message: string;
      };
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ListScannedKeywordsResponse = {
  data: Schemas.TrackedKeywordResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ListScannedKeywordsVariables = {
  pathParams: ListScannedKeywordsPathParams;
  queryParams?: ListScannedKeywordsQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchListScannedKeywords = (
  variables: ListScannedKeywordsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ListScannedKeywordsResponse,
    ListScannedKeywordsError,
    undefined,
    {},
    ListScannedKeywordsQueryParams,
    ListScannedKeywordsPathParams
  >({
    url: '/projects/{project}/scanned-keywords',
    method: 'get',
    ...variables,
    signal,
  });

export const useListScannedKeywords = <TData = ListScannedKeywordsResponse,>(
  variables: ListScannedKeywordsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListScannedKeywordsResponse,
      ListScannedKeywordsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ListScannedKeywordsResponse,
    ListScannedKeywordsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/scanned-keywords',
      operationId: 'listScannedKeywords',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListScannedKeywords({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectScannedKeywordScanPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectScannedKeywordScanError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Upgrade required to use Keyword scanner
         */
        message: string;
      };
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 409;
      payload: {
        /**
         * Error overview.
         *
         * @example Scan already in progress
         */
        message: string;
      };
    }
>;

export type ProjectScannedKeywordScanVariables = {
  body?: Record<string, any>;
  pathParams: ProjectScannedKeywordScanPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectScannedKeywordScan = (
  variables: ProjectScannedKeywordScanVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    string,
    ProjectScannedKeywordScanError,
    Record<string, any>,
    {},
    {},
    ProjectScannedKeywordScanPathParams
  >({
    url: '/projects/{project}/scan-keywords',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectScannedKeywordScan = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      ProjectScannedKeywordScanError,
      ProjectScannedKeywordScanVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    string,
    ProjectScannedKeywordScanError,
    ProjectScannedKeywordScanVariables
  >({
    mutationFn: (variables: ProjectScannedKeywordScanVariables) =>
      fetchProjectScannedKeywordScan({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListScannedKeywordsGroupedPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The rank tracking list ID
   */
  rankTrackingList: number;
};

export type ListScannedKeywordsGroupedQueryParams = {
  /**
   * @minimum 1
   * @maximum 5
   */
  search_results_similarity_threshold?: number;
};

export type ListScannedKeywordsGroupedError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Upgrade required to use Keyword scanner
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ListScannedKeywordsGroupedResponse = {
  data: Schemas.ScannedKeywordGroupResource[];
};

export type ListScannedKeywordsGroupedVariables = {
  pathParams: ListScannedKeywordsGroupedPathParams;
  queryParams?: ListScannedKeywordsGroupedQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchListScannedKeywordsGrouped = (
  variables: ListScannedKeywordsGroupedVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ListScannedKeywordsGroupedResponse,
    ListScannedKeywordsGroupedError,
    undefined,
    {},
    ListScannedKeywordsGroupedQueryParams,
    ListScannedKeywordsGroupedPathParams
  >({
    url: '/projects/{project}/scanned-keywords/{rankTrackingList}/grouped',
    method: 'get',
    ...variables,
    signal,
  });

export const useListScannedKeywordsGrouped = <
  TData = ListScannedKeywordsGroupedResponse,
>(
  variables: ListScannedKeywordsGroupedVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListScannedKeywordsGroupedResponse,
      ListScannedKeywordsGroupedError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ListScannedKeywordsGroupedResponse,
    ListScannedKeywordsGroupedError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/scanned-keywords/{rankTrackingList}/grouped',
      operationId: 'listScannedKeywordsGrouped',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListScannedKeywordsGrouped(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type DocumentOptimiseTitleSuggestionsPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type DocumentOptimiseTitleSuggestionsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example No title to optimise
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type DocumentOptimiseTitleSuggestionsResponse = {
  data: Schemas.DocumentTitleResource[];
};

export type DocumentOptimiseTitleSuggestionsVariables = {
  pathParams: DocumentOptimiseTitleSuggestionsPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentOptimiseTitleSuggestions = (
  variables: DocumentOptimiseTitleSuggestionsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    DocumentOptimiseTitleSuggestionsResponse,
    DocumentOptimiseTitleSuggestionsError,
    undefined,
    {},
    {},
    DocumentOptimiseTitleSuggestionsPathParams
  >({
    url: '/projects/{project}/documents/{document}/optimise-title-suggestions',
    method: 'get',
    ...variables,
    signal,
  });

export const useDocumentOptimiseTitleSuggestions = <
  TData = DocumentOptimiseTitleSuggestionsResponse,
>(
  variables: DocumentOptimiseTitleSuggestionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DocumentOptimiseTitleSuggestionsResponse,
      DocumentOptimiseTitleSuggestionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    DocumentOptimiseTitleSuggestionsResponse,
    DocumentOptimiseTitleSuggestionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/documents/{document}/optimise-title-suggestions',
      operationId: 'documentOptimiseTitleSuggestions',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchDocumentOptimiseTitleSuggestions(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type OrganisationsIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id';
  sort_direction?: 'ASC' | 'DESC';
};

export type OrganisationsIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type OrganisationsIndexResponse = {
  data: Schemas.OrganisationResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type OrganisationsIndexVariables = {
  queryParams?: OrganisationsIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Only lists organisations accessible by the authenticated user.
 *
 * ### Broadcasting details
 *
 * Broadcast channels: `organisations.{organisationId}`
 *
 * Broadcast event: `SubscriptionUpdatedEvent`
 *
 * Broadcast event data:
 *
 * ```
 * No data
 * ```
 */
export const fetchOrganisationsIndex = (
  variables: OrganisationsIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationsIndexResponse,
    OrganisationsIndexError,
    undefined,
    {},
    OrganisationsIndexQueryParams,
    {}
  >({ url: '/organisations', method: 'get', ...variables, signal });

/**
 * Only lists organisations accessible by the authenticated user.
 *
 * ### Broadcasting details
 *
 * Broadcast channels: `organisations.{organisationId}`
 *
 * Broadcast event: `SubscriptionUpdatedEvent`
 *
 * Broadcast event data:
 *
 * ```
 * No data
 * ```
 */
export const useOrganisationsIndex = <TData = OrganisationsIndexResponse,>(
  variables: OrganisationsIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganisationsIndexResponse,
      OrganisationsIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    OrganisationsIndexResponse,
    OrganisationsIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/organisations',
      operationId: 'organisationsIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganisationsIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type OrganisationsShowPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationsShowError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type OrganisationsShowResponse = {
  data: Schemas.OrganisationResource;
};

export type OrganisationsShowVariables = {
  pathParams: OrganisationsShowPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchOrganisationsShow = (
  variables: OrganisationsShowVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationsShowResponse,
    OrganisationsShowError,
    undefined,
    {},
    {},
    OrganisationsShowPathParams
  >({
    url: '/organisations/{organisation}',
    method: 'get',
    ...variables,
    signal,
  });

export const useOrganisationsShow = <TData = OrganisationsShowResponse,>(
  variables: OrganisationsShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganisationsShowResponse,
      OrganisationsShowError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    OrganisationsShowResponse,
    OrganisationsShowError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/organisations/{organisation}',
      operationId: 'organisationsShow',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganisationsShow({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type OrganisationsUpdatePathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationsUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type OrganisationsUpdateResponse = {
  data: Schemas.OrganisationResource;
};

export type OrganisationsUpdateVariables = {
  body: Schemas.UpdateOrganisationRequest;
  pathParams: OrganisationsUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Domain and language can not be updated once a project has been created.
 */
export const fetchOrganisationsUpdate = (
  variables: OrganisationsUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationsUpdateResponse,
    OrganisationsUpdateError,
    Schemas.UpdateOrganisationRequest,
    {},
    {},
    OrganisationsUpdatePathParams
  >({
    url: '/organisations/{organisation}',
    method: 'put',
    ...variables,
    signal,
  });

/**
 * Domain and language can not be updated once a project has been created.
 */
export const useOrganisationsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      OrganisationsUpdateResponse,
      OrganisationsUpdateError,
      OrganisationsUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    OrganisationsUpdateResponse,
    OrganisationsUpdateError,
    OrganisationsUpdateVariables
  >({
    mutationFn: (variables: OrganisationsUpdateVariables) =>
      fetchOrganisationsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganisationsOrganisationInvitesIndexPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationsOrganisationInvitesIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id';
  sort_direction?: 'ASC' | 'DESC';
};

export type OrganisationsOrganisationInvitesIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type OrganisationsOrganisationInvitesIndexResponse = {
  data: Schemas.OrganisationInviteResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type OrganisationsOrganisationInvitesIndexVariables = {
  pathParams: OrganisationsOrganisationInvitesIndexPathParams;
  queryParams?: OrganisationsOrganisationInvitesIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Lists all invitations connected to the organisation
 */
export const fetchOrganisationsOrganisationInvitesIndex = (
  variables: OrganisationsOrganisationInvitesIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationsOrganisationInvitesIndexResponse,
    OrganisationsOrganisationInvitesIndexError,
    undefined,
    {},
    OrganisationsOrganisationInvitesIndexQueryParams,
    OrganisationsOrganisationInvitesIndexPathParams
  >({
    url: '/organisations/{organisation}/organisation-invites',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Lists all invitations connected to the organisation
 */
export const useOrganisationsOrganisationInvitesIndex = <
  TData = OrganisationsOrganisationInvitesIndexResponse,
>(
  variables: OrganisationsOrganisationInvitesIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganisationsOrganisationInvitesIndexResponse,
      OrganisationsOrganisationInvitesIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    OrganisationsOrganisationInvitesIndexResponse,
    OrganisationsOrganisationInvitesIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/organisations/{organisation}/organisation-invites',
      operationId: 'organisationsOrganisationInvitesIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganisationsOrganisationInvitesIndex(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type OrganisationsOrganisationInvitesStorePathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationsOrganisationInvitesStoreError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example User already exists
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Not enough user credits
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type OrganisationsOrganisationInvitesStoreResponse = {
  data: Schemas.OrganisationInviteResource;
};

export type OrganisationsOrganisationInvitesStoreVariables = {
  body: Schemas.CreateOrganisationUserRequest;
  pathParams: OrganisationsOrganisationInvitesStorePathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Sends an email invitation to the user
 */
export const fetchOrganisationsOrganisationInvitesStore = (
  variables: OrganisationsOrganisationInvitesStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationsOrganisationInvitesStoreResponse,
    OrganisationsOrganisationInvitesStoreError,
    Schemas.CreateOrganisationUserRequest,
    {},
    {},
    OrganisationsOrganisationInvitesStorePathParams
  >({
    url: '/organisations/{organisation}/organisation-invites',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Sends an email invitation to the user
 */
export const useOrganisationsOrganisationInvitesStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      OrganisationsOrganisationInvitesStoreResponse,
      OrganisationsOrganisationInvitesStoreError,
      OrganisationsOrganisationInvitesStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    OrganisationsOrganisationInvitesStoreResponse,
    OrganisationsOrganisationInvitesStoreError,
    OrganisationsOrganisationInvitesStoreVariables
  >({
    mutationFn: (variables: OrganisationsOrganisationInvitesStoreVariables) =>
      fetchOrganisationsOrganisationInvitesStore({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type OrganisationsOrganisationInvitesDestroyPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
  /**
   * The organisation invite ID
   */
  organisationInvite: number;
};

export type OrganisationsOrganisationInvitesDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type OrganisationsOrganisationInvitesDestroyVariables = {
  pathParams: OrganisationsOrganisationInvitesDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Retracts the invitation
 */
export const fetchOrganisationsOrganisationInvitesDestroy = (
  variables: OrganisationsOrganisationInvitesDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    OrganisationsOrganisationInvitesDestroyError,
    undefined,
    {},
    {},
    OrganisationsOrganisationInvitesDestroyPathParams
  >({
    url: '/organisations/{organisation}/organisation-invites/{organisationInvite}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Retracts the invitation
 */
export const useOrganisationsOrganisationInvitesDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrganisationsOrganisationInvitesDestroyError,
      OrganisationsOrganisationInvitesDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    OrganisationsOrganisationInvitesDestroyError,
    OrganisationsOrganisationInvitesDestroyVariables
  >({
    mutationFn: (variables: OrganisationsOrganisationInvitesDestroyVariables) =>
      fetchOrganisationsOrganisationInvitesDestroy({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type OrganisationOrganisationMetricsPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationOrganisationMetricsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type OrganisationOrganisationMetricsResponse = {
  data: Schemas.OrganisationMetricsResource;
};

export type OrganisationOrganisationMetricsVariables = {
  pathParams: OrganisationOrganisationMetricsPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchOrganisationOrganisationMetrics = (
  variables: OrganisationOrganisationMetricsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationOrganisationMetricsResponse,
    OrganisationOrganisationMetricsError,
    undefined,
    {},
    {},
    OrganisationOrganisationMetricsPathParams
  >({
    url: '/organisations/{organisation}/metrics',
    method: 'get',
    ...variables,
    signal,
  });

export const useOrganisationOrganisationMetrics = <
  TData = OrganisationOrganisationMetricsResponse,
>(
  variables: OrganisationOrganisationMetricsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganisationOrganisationMetricsResponse,
      OrganisationOrganisationMetricsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    OrganisationOrganisationMetricsResponse,
    OrganisationOrganisationMetricsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/organisations/{organisation}/metrics',
      operationId: 'organisationOrganisationMetrics',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganisationOrganisationMetrics(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type OrganisationPaymentSetupPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationPaymentSetupError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type OrganisationPaymentSetupResponse = {
  data: Schemas.SetupIntentResource;
};

export type OrganisationPaymentSetupVariables = {
  pathParams: OrganisationPaymentSetupPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchOrganisationPaymentSetup = (
  variables: OrganisationPaymentSetupVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationPaymentSetupResponse,
    OrganisationPaymentSetupError,
    undefined,
    {},
    {},
    OrganisationPaymentSetupPathParams
  >({
    url: '/organisations/{organisation}/payment-setup',
    method: 'get',
    ...variables,
    signal,
  });

export const useOrganisationPaymentSetup = <
  TData = OrganisationPaymentSetupResponse,
>(
  variables: OrganisationPaymentSetupVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganisationPaymentSetupResponse,
      OrganisationPaymentSetupError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    OrganisationPaymentSetupResponse,
    OrganisationPaymentSetupError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/organisations/{organisation}/payment-setup',
      operationId: 'organisationPaymentSetup',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganisationPaymentSetup(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type OrganisationSubscribePathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationSubscribeError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload:
        | {
            /**
             * Error overview.
             *
             * @example Payment method could not be verified.
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example Processing error. Please contact support.
             */
            message: string;
          };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example The chosen card is not eligible for a trial.
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 409;
      payload: {
        /**
         * Error overview.
         *
         * @example Organisation already has an active subscription.
         */
        message: string;
      };
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type OrganisationSubscribeVariables = {
  body: Schemas.SubscribeRequest;
  pathParams: OrganisationSubscribePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchOrganisationSubscribe = (
  variables: OrganisationSubscribeVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    OrganisationSubscribeError,
    Schemas.SubscribeRequest,
    {},
    {},
    OrganisationSubscribePathParams
  >({
    url: '/organisations/{organisation}/subscribe',
    method: 'post',
    ...variables,
    signal,
  });

export const useOrganisationSubscribe = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrganisationSubscribeError,
      OrganisationSubscribeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    OrganisationSubscribeError,
    OrganisationSubscribeVariables
  >({
    mutationFn: (variables: OrganisationSubscribeVariables) =>
      fetchOrganisationSubscribe({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganisationSubscriptionListPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationSubscriptionListError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type OrganisationSubscriptionListResponse = {
  data: Schemas.SubscriptionResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type OrganisationSubscriptionListVariables = {
  pathParams: OrganisationSubscriptionListPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * ### Broadcasting details
 *
 * When a subscription or its usage is updated, the following event is broadcasted:
 *
 * Broadcast channel: `organisation.{organisationId}`
 *
 * Broadcast event: `SubscriptionUpdatedEvent`
 *
 * Broadcast event data:
 *
 * ```json
 * {"id": int}
 * ```
 */
export const fetchOrganisationSubscriptionList = (
  variables: OrganisationSubscriptionListVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationSubscriptionListResponse,
    OrganisationSubscriptionListError,
    undefined,
    {},
    {},
    OrganisationSubscriptionListPathParams
  >({
    url: '/organisations/{organisation}/subscriptions',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * ### Broadcasting details
 *
 * When a subscription or its usage is updated, the following event is broadcasted:
 *
 * Broadcast channel: `organisation.{organisationId}`
 *
 * Broadcast event: `SubscriptionUpdatedEvent`
 *
 * Broadcast event data:
 *
 * ```json
 * {"id": int}
 * ```
 */
export const useOrganisationSubscriptionList = <
  TData = OrganisationSubscriptionListResponse,
>(
  variables: OrganisationSubscriptionListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganisationSubscriptionListResponse,
      OrganisationSubscriptionListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    OrganisationSubscriptionListResponse,
    OrganisationSubscriptionListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/organisations/{organisation}/subscriptions',
      operationId: 'organisationSubscriptionList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganisationSubscriptionList(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type OrganisationSubscriptionIndexPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationSubscriptionIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type OrganisationSubscriptionIndexResponse = {
  data: Schemas.SubscriptionResource;
};

export type OrganisationSubscriptionIndexVariables = {
  pathParams: OrganisationSubscriptionIndexPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * ### Broadcasting details
 *
 * When a subscription or its usage is updated, the following event is broadcasted:
 *
 * Broadcast channel: `organisation.{organisationId}`
 *
 * Broadcast event: `SubscriptionUpdatedEvent`
 *
 * Broadcast event data:
 *
 * ```json
 * {"id": int}
 * ```
 */
export const fetchOrganisationSubscriptionIndex = (
  variables: OrganisationSubscriptionIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationSubscriptionIndexResponse,
    OrganisationSubscriptionIndexError,
    undefined,
    {},
    {},
    OrganisationSubscriptionIndexPathParams
  >({
    url: '/organisations/{organisation}/subscription',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * ### Broadcasting details
 *
 * When a subscription or its usage is updated, the following event is broadcasted:
 *
 * Broadcast channel: `organisation.{organisationId}`
 *
 * Broadcast event: `SubscriptionUpdatedEvent`
 *
 * Broadcast event data:
 *
 * ```json
 * {"id": int}
 * ```
 */
export const useOrganisationSubscriptionIndex = <
  TData = OrganisationSubscriptionIndexResponse,
>(
  variables: OrganisationSubscriptionIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganisationSubscriptionIndexResponse,
      OrganisationSubscriptionIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    OrganisationSubscriptionIndexResponse,
    OrganisationSubscriptionIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/organisations/{organisation}/subscription',
      operationId: 'organisationSubscriptionIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganisationSubscriptionIndex(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type OrganisationSubscriptionUpdatePathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationSubscriptionUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload:
        | {
            /**
             * Error overview.
             *
             * @example Subscription is cancelled
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example Products should have the same interval
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example You must specify a plan when modifying addons
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example Addons are not supported by this plan
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example The "plan" can only have a quantity of 1
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example No items to update
             */
            message: string;
          };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type OrganisationSubscriptionUpdateResponse = {
  data: Schemas.SubscriptionResource;
};

export type OrganisationSubscriptionUpdateVariables = {
  body: Schemas.UpdateSubscriptionRequest;
  pathParams: OrganisationSubscriptionUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchOrganisationSubscriptionUpdate = (
  variables: OrganisationSubscriptionUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationSubscriptionUpdateResponse,
    OrganisationSubscriptionUpdateError,
    Schemas.UpdateSubscriptionRequest,
    {},
    {},
    OrganisationSubscriptionUpdatePathParams
  >({
    url: '/organisations/{organisation}/subscription',
    method: 'patch',
    ...variables,
    signal,
  });

export const useOrganisationSubscriptionUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      OrganisationSubscriptionUpdateResponse,
      OrganisationSubscriptionUpdateError,
      OrganisationSubscriptionUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    OrganisationSubscriptionUpdateResponse,
    OrganisationSubscriptionUpdateError,
    OrganisationSubscriptionUpdateVariables
  >({
    mutationFn: (variables: OrganisationSubscriptionUpdateVariables) =>
      fetchOrganisationSubscriptionUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganisationSubscriptionCancelPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationSubscriptionCancelError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example No subscription or already cancelled
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type OrganisationSubscriptionCancelResponse = {
  data: Schemas.SubscriptionResource;
};

export type OrganisationSubscriptionCancelVariables = {
  body: Schemas.CancelSubscriptionRequest;
  pathParams: OrganisationSubscriptionCancelPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Cancels an active subscription
 */
export const fetchOrganisationSubscriptionCancel = (
  variables: OrganisationSubscriptionCancelVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationSubscriptionCancelResponse,
    OrganisationSubscriptionCancelError,
    Schemas.CancelSubscriptionRequest,
    {},
    {},
    OrganisationSubscriptionCancelPathParams
  >({
    url: '/organisations/{organisation}/subscription/cancel',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Cancels an active subscription
 */
export const useOrganisationSubscriptionCancel = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      OrganisationSubscriptionCancelResponse,
      OrganisationSubscriptionCancelError,
      OrganisationSubscriptionCancelVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    OrganisationSubscriptionCancelResponse,
    OrganisationSubscriptionCancelError,
    OrganisationSubscriptionCancelVariables
  >({
    mutationFn: (variables: OrganisationSubscriptionCancelVariables) =>
      fetchOrganisationSubscriptionCancel({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganisationSubscriptionResumePathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationSubscriptionResumeError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example No subscription or not cancelled
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type OrganisationSubscriptionResumeResponse = {
  data: Schemas.SubscriptionResource;
};

export type OrganisationSubscriptionResumeVariables = {
  body?: Record<string, any>;
  pathParams: OrganisationSubscriptionResumePathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Resumes an already cancelled subscription
 */
export const fetchOrganisationSubscriptionResume = (
  variables: OrganisationSubscriptionResumeVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationSubscriptionResumeResponse,
    OrganisationSubscriptionResumeError,
    Record<string, any>,
    {},
    {},
    OrganisationSubscriptionResumePathParams
  >({
    url: '/organisations/{organisation}/subscription/resume',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Resumes an already cancelled subscription
 */
export const useOrganisationSubscriptionResume = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      OrganisationSubscriptionResumeResponse,
      OrganisationSubscriptionResumeError,
      OrganisationSubscriptionResumeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    OrganisationSubscriptionResumeResponse,
    OrganisationSubscriptionResumeError,
    OrganisationSubscriptionResumeVariables
  >({
    mutationFn: (variables: OrganisationSubscriptionResumeVariables) =>
      fetchOrganisationSubscriptionResume({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganisationSubscriptionCancelDowngradePathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationSubscriptionCancelDowngradeError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example No subscription or not downgrading
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type OrganisationSubscriptionCancelDowngradeResponse = {
  data: Schemas.SubscriptionResource;
};

export type OrganisationSubscriptionCancelDowngradeVariables = {
  body?: Record<string, any>;
  pathParams: OrganisationSubscriptionCancelDowngradePathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Stops the downgrade of a subscription
 */
export const fetchOrganisationSubscriptionCancelDowngrade = (
  variables: OrganisationSubscriptionCancelDowngradeVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationSubscriptionCancelDowngradeResponse,
    OrganisationSubscriptionCancelDowngradeError,
    Record<string, any>,
    {},
    {},
    OrganisationSubscriptionCancelDowngradePathParams
  >({
    url: '/organisations/{organisation}/subscription/cancel-downgrade',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Stops the downgrade of a subscription
 */
export const useOrganisationSubscriptionCancelDowngrade = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      OrganisationSubscriptionCancelDowngradeResponse,
      OrganisationSubscriptionCancelDowngradeError,
      OrganisationSubscriptionCancelDowngradeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    OrganisationSubscriptionCancelDowngradeResponse,
    OrganisationSubscriptionCancelDowngradeError,
    OrganisationSubscriptionCancelDowngradeVariables
  >({
    mutationFn: (variables: OrganisationSubscriptionCancelDowngradeVariables) =>
      fetchOrganisationSubscriptionCancelDowngrade({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type OrganisationSubscriptionEndTrialPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationSubscriptionEndTrialError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example No subscription or not in trial
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type OrganisationSubscriptionEndTrialResponse = {
  data: Schemas.SubscriptionResource;
};

export type OrganisationSubscriptionEndTrialVariables = {
  body?: Record<string, any>;
  pathParams: OrganisationSubscriptionEndTrialPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Ends the trial period and immediately charges the client.
 */
export const fetchOrganisationSubscriptionEndTrial = (
  variables: OrganisationSubscriptionEndTrialVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationSubscriptionEndTrialResponse,
    OrganisationSubscriptionEndTrialError,
    Record<string, any>,
    {},
    {},
    OrganisationSubscriptionEndTrialPathParams
  >({
    url: '/organisations/{organisation}/subscription/end-trial',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Ends the trial period and immediately charges the client.
 */
export const useOrganisationSubscriptionEndTrial = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      OrganisationSubscriptionEndTrialResponse,
      OrganisationSubscriptionEndTrialError,
      OrganisationSubscriptionEndTrialVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    OrganisationSubscriptionEndTrialResponse,
    OrganisationSubscriptionEndTrialError,
    OrganisationSubscriptionEndTrialVariables
  >({
    mutationFn: (variables: OrganisationSubscriptionEndTrialVariables) =>
      fetchOrganisationSubscriptionEndTrial({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type OrganisationSubscriptionCancelReasonsIndexPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationSubscriptionCancelReasonsIndexError =
  Fetcher.ErrorWrapper<
    | {
        status: 401;
        payload: Responses.AuthenticationException;
      }
    | {
        status: 403;
        payload: Responses.AuthorizationException;
      }
    | {
        status: 404;
        payload: Responses.ModelNotFoundException;
      }
  >;

export type OrganisationSubscriptionCancelReasonsIndexResponse = {
  data: Schemas.SubscriptionCancelReasonResource[];
};

export type OrganisationSubscriptionCancelReasonsIndexVariables = {
  pathParams: OrganisationSubscriptionCancelReasonsIndexPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * List all active cancel reasons that a subscription can have
 */
export const fetchOrganisationSubscriptionCancelReasonsIndex = (
  variables: OrganisationSubscriptionCancelReasonsIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationSubscriptionCancelReasonsIndexResponse,
    OrganisationSubscriptionCancelReasonsIndexError,
    undefined,
    {},
    {},
    OrganisationSubscriptionCancelReasonsIndexPathParams
  >({
    url: '/organisations/{organisation}/subscription/cancel-reasons',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * List all active cancel reasons that a subscription can have
 */
export const useOrganisationSubscriptionCancelReasonsIndex = <
  TData = OrganisationSubscriptionCancelReasonsIndexResponse,
>(
  variables: OrganisationSubscriptionCancelReasonsIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganisationSubscriptionCancelReasonsIndexResponse,
      OrganisationSubscriptionCancelReasonsIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    OrganisationSubscriptionCancelReasonsIndexResponse,
    OrganisationSubscriptionCancelReasonsIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/organisations/{organisation}/subscription/cancel-reasons',
      operationId: 'organisationSubscriptionCancelReasonsIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganisationSubscriptionCancelReasonsIndex(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type OrganisationsTagsIndexPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationsTagsIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id' | 'name';
  sort_direction?: 'ASC' | 'DESC';
  filters?: {
    search?: string;
  };
};

export type OrganisationsTagsIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type OrganisationsTagsIndexResponse = {
  data: Schemas.OrganisationTagResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type OrganisationsTagsIndexVariables = {
  pathParams: OrganisationsTagsIndexPathParams;
  queryParams?: OrganisationsTagsIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchOrganisationsTagsIndex = (
  variables: OrganisationsTagsIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationsTagsIndexResponse,
    OrganisationsTagsIndexError,
    undefined,
    {},
    OrganisationsTagsIndexQueryParams,
    OrganisationsTagsIndexPathParams
  >({
    url: '/organisations/{organisation}/tags',
    method: 'get',
    ...variables,
    signal,
  });

export const useOrganisationsTagsIndex = <
  TData = OrganisationsTagsIndexResponse,
>(
  variables: OrganisationsTagsIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganisationsTagsIndexResponse,
      OrganisationsTagsIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    OrganisationsTagsIndexResponse,
    OrganisationsTagsIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/organisations/{organisation}/tags',
      operationId: 'organisationsTagsIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganisationsTagsIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type OrganisationsTagsStorePathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationsTagsStoreError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type OrganisationsTagsStoreResponse = {
  data: Schemas.OrganisationTagResource;
};

export type OrganisationsTagsStoreVariables = {
  body: Schemas.CreateOrganisationTagRequest;
  pathParams: OrganisationsTagsStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchOrganisationsTagsStore = (
  variables: OrganisationsTagsStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationsTagsStoreResponse,
    OrganisationsTagsStoreError,
    Schemas.CreateOrganisationTagRequest,
    {},
    {},
    OrganisationsTagsStorePathParams
  >({
    url: '/organisations/{organisation}/tags',
    method: 'post',
    ...variables,
    signal,
  });

export const useOrganisationsTagsStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      OrganisationsTagsStoreResponse,
      OrganisationsTagsStoreError,
      OrganisationsTagsStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    OrganisationsTagsStoreResponse,
    OrganisationsTagsStoreError,
    OrganisationsTagsStoreVariables
  >({
    mutationFn: (variables: OrganisationsTagsStoreVariables) =>
      fetchOrganisationsTagsStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganisationsTagsShowPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
  /**
   * The tag ID
   */
  tag: number;
};

export type OrganisationsTagsShowError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type OrganisationsTagsShowResponse = {
  data: Schemas.OrganisationTagResource;
};

export type OrganisationsTagsShowVariables = {
  pathParams: OrganisationsTagsShowPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchOrganisationsTagsShow = (
  variables: OrganisationsTagsShowVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationsTagsShowResponse,
    OrganisationsTagsShowError,
    undefined,
    {},
    {},
    OrganisationsTagsShowPathParams
  >({
    url: '/organisations/{organisation}/tags/{tag}',
    method: 'get',
    ...variables,
    signal,
  });

export const useOrganisationsTagsShow = <
  TData = OrganisationsTagsShowResponse,
>(
  variables: OrganisationsTagsShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganisationsTagsShowResponse,
      OrganisationsTagsShowError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    OrganisationsTagsShowResponse,
    OrganisationsTagsShowError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/organisations/{organisation}/tags/{tag}',
      operationId: 'organisationsTagsShow',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganisationsTagsShow({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type OrganisationsTagsUpdatePathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
  /**
   * The tag ID
   */
  tag: number;
};

export type OrganisationsTagsUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type OrganisationsTagsUpdateResponse = {
  data: Schemas.OrganisationTagResource;
};

export type OrganisationsTagsUpdateVariables = {
  body?: Schemas.UpdateOrganisationTagRequest;
  pathParams: OrganisationsTagsUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchOrganisationsTagsUpdate = (
  variables: OrganisationsTagsUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationsTagsUpdateResponse,
    OrganisationsTagsUpdateError,
    Schemas.UpdateOrganisationTagRequest,
    {},
    {},
    OrganisationsTagsUpdatePathParams
  >({
    url: '/organisations/{organisation}/tags/{tag}',
    method: 'put',
    ...variables,
    signal,
  });

export const useOrganisationsTagsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      OrganisationsTagsUpdateResponse,
      OrganisationsTagsUpdateError,
      OrganisationsTagsUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    OrganisationsTagsUpdateResponse,
    OrganisationsTagsUpdateError,
    OrganisationsTagsUpdateVariables
  >({
    mutationFn: (variables: OrganisationsTagsUpdateVariables) =>
      fetchOrganisationsTagsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganisationsTagsDestroyPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
  /**
   * The tag ID
   */
  tag: number;
};

export type OrganisationsTagsDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type OrganisationsTagsDestroyVariables = {
  pathParams: OrganisationsTagsDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchOrganisationsTagsDestroy = (
  variables: OrganisationsTagsDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    OrganisationsTagsDestroyError,
    undefined,
    {},
    {},
    OrganisationsTagsDestroyPathParams
  >({
    url: '/organisations/{organisation}/tags/{tag}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useOrganisationsTagsDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrganisationsTagsDestroyError,
      OrganisationsTagsDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    OrganisationsTagsDestroyError,
    OrganisationsTagsDestroyVariables
  >({
    mutationFn: (variables: OrganisationsTagsDestroyVariables) =>
      fetchOrganisationsTagsDestroy({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OrganisationsUsersIndexPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationsUsersIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id';
  sort_direction?: 'ASC' | 'DESC';
};

export type OrganisationsUsersIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type OrganisationsUsersIndexResponse = {
  data: Schemas.OrganisationUserResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type OrganisationsUsersIndexVariables = {
  pathParams: OrganisationsUsersIndexPathParams;
  queryParams?: OrganisationsUsersIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Lists all users connected to the organisation
 */
export const fetchOrganisationsUsersIndex = (
  variables: OrganisationsUsersIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationsUsersIndexResponse,
    OrganisationsUsersIndexError,
    undefined,
    {},
    OrganisationsUsersIndexQueryParams,
    OrganisationsUsersIndexPathParams
  >({
    url: '/organisations/{organisation}/users',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Lists all users connected to the organisation
 */
export const useOrganisationsUsersIndex = <
  TData = OrganisationsUsersIndexResponse,
>(
  variables: OrganisationsUsersIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganisationsUsersIndexResponse,
      OrganisationsUsersIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    OrganisationsUsersIndexResponse,
    OrganisationsUsersIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/organisations/{organisation}/users',
      operationId: 'organisationsUsersIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganisationsUsersIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type OrganisationsUsersDestroyPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
  /**
   * The user ID
   */
  user: number;
};

export type OrganisationsUsersDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload:
        | {
            /**
             * Error overview.
             *
             * @example Can't delete yourself
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example All organisations must have at least 1 user
             */
            message: string;
          };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type OrganisationsUsersDestroyVariables = {
  pathParams: OrganisationsUsersDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Retracts the invitation
 */
export const fetchOrganisationsUsersDestroy = (
  variables: OrganisationsUsersDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    OrganisationsUsersDestroyError,
    undefined,
    {},
    {},
    OrganisationsUsersDestroyPathParams
  >({
    url: '/organisations/{organisation}/users/{user}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Retracts the invitation
 */
export const useOrganisationsUsersDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrganisationsUsersDestroyError,
      OrganisationsUsersDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    OrganisationsUsersDestroyError,
    OrganisationsUsersDestroyVariables
  >({
    mutationFn: (variables: OrganisationsUsersDestroyVariables) =>
      fetchOrganisationsUsersDestroy({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ListQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id' | 'search_volume' | 'difficulty' | 'keyword' | 'documents';
  sort_direction?: 'ASC' | 'DESC';
  filters?: {
    search_volume_min?: number | null;
    search_volume_max?: number | null;
    difficulty_min?: number | null;
    difficulty_max?: number | null;
    search?: string | null;
    has_documents?: boolean | null;
    list_id?: number | null;
  };
};

export type ListError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ListResponse = {
  data: Schemas.PlannedContentResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ListVariables = {
  pathParams: ListPathParams;
  queryParams?: ListQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchList = (variables: ListVariables, signal?: AbortSignal) =>
  openapiFetch<
    ListResponse,
    ListError,
    undefined,
    {},
    ListQueryParams,
    ListPathParams
  >({
    url: '/projects/{project}/planned-content',
    method: 'get',
    ...variables,
    signal,
  });

export const useList = <TData = ListResponse,>(
  variables: ListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ListResponse, ListError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<ListResponse, ListError, TData>({
    queryKey: queryKeyFn({
      path: '/projects/{project}/planned-content',
      operationId: 'list',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreatePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type CreateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type CreateResponse = {
  data: Schemas.PlannedContentResource[];
};

export type CreateVariables = {
  body: Schemas.CreateBulkPlannedContentRequest;
  pathParams: CreatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchCreate = (variables: CreateVariables, signal?: AbortSignal) =>
  openapiFetch<
    CreateResponse,
    CreateError,
    Schemas.CreateBulkPlannedContentRequest,
    {},
    {},
    CreatePathParams
  >({
    url: '/projects/{project}/planned-content',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<CreateResponse, CreateError, CreateVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<CreateResponse, CreateError, CreateVariables>({
    mutationFn: (variables: CreateVariables) =>
      fetchCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeletePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type DeleteQueryParams = {
  ids: number[];
};

export type DeleteError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type DeleteVariables = {
  pathParams: DeletePathParams;
  queryParams: DeleteQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDelete = (variables: DeleteVariables, signal?: AbortSignal) =>
  openapiFetch<
    undefined,
    DeleteError,
    undefined,
    {},
    DeleteQueryParams,
    DeletePathParams
  >({
    url: '/projects/{project}/planned-content',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteError, DeleteVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<undefined, DeleteError, DeleteVariables>({
    mutationFn: (variables: DeleteVariables) =>
      fetchDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdatePathParams = {
  /**
   * The project ID
   */
  project: number;
  plannedContent: string;
};

export type UpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type UpdateResponse = {
  data: Schemas.PlannedContentResource;
};

export type UpdateVariables = {
  body?: Schemas.UpdatePlannedContentRequest;
  pathParams: UpdatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchUpdate = (variables: UpdateVariables, signal?: AbortSignal) =>
  openapiFetch<
    UpdateResponse,
    UpdateError,
    Schemas.UpdatePlannedContentRequest,
    {},
    {},
    UpdatePathParams
  >({
    url: '/projects/{project}/planned-content/{plannedContent}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<UpdateResponse, UpdateError, UpdateVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<UpdateResponse, UpdateError, UpdateVariables>({
    mutationFn: (variables: UpdateVariables) =>
      fetchUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectProjectPlannedContentExportPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectProjectPlannedContentExportError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectProjectPlannedContentExportVariables = {
  body?: Schemas.PlannedContentExportRequest;
  pathParams: ProjectProjectPlannedContentExportPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectProjectPlannedContentExport = (
  variables: ProjectProjectPlannedContentExportVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    string,
    ProjectProjectPlannedContentExportError,
    Schemas.PlannedContentExportRequest,
    {},
    {},
    ProjectProjectPlannedContentExportPathParams
  >({
    url: '/projects/{project}/planned-content/export',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectProjectPlannedContentExport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      ProjectProjectPlannedContentExportError,
      ProjectProjectPlannedContentExportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    string,
    ProjectProjectPlannedContentExportError,
    ProjectProjectPlannedContentExportVariables
  >({
    mutationFn: (variables: ProjectProjectPlannedContentExportVariables) =>
      fetchProjectProjectPlannedContentExport({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type PlannedContentListIndexPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type PlannedContentListIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id';
  sort_direction?: 'ASC' | 'DESC';
};

export type PlannedContentListIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type PlannedContentListIndexResponse = {
  data: Schemas.ContentListResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type PlannedContentListIndexVariables = {
  pathParams: PlannedContentListIndexPathParams;
  queryParams?: PlannedContentListIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchPlannedContentListIndex = (
  variables: PlannedContentListIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    PlannedContentListIndexResponse,
    PlannedContentListIndexError,
    undefined,
    {},
    PlannedContentListIndexQueryParams,
    PlannedContentListIndexPathParams
  >({
    url: '/projects/{project}/planned-content-lists',
    method: 'get',
    ...variables,
    signal,
  });

export const usePlannedContentListIndex = <
  TData = PlannedContentListIndexResponse,
>(
  variables: PlannedContentListIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      PlannedContentListIndexResponse,
      PlannedContentListIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    PlannedContentListIndexResponse,
    PlannedContentListIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/planned-content-lists',
      operationId: 'plannedContentListIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchPlannedContentListIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PlannedContentListStorePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type PlannedContentListStoreError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type PlannedContentListStoreResponse = {
  data: Schemas.ContentListResource;
};

export type PlannedContentListStoreVariables = {
  body: Schemas.CreatePlannedContentListRequest;
  pathParams: PlannedContentListStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchPlannedContentListStore = (
  variables: PlannedContentListStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    PlannedContentListStoreResponse,
    PlannedContentListStoreError,
    Schemas.CreatePlannedContentListRequest,
    {},
    {},
    PlannedContentListStorePathParams
  >({
    url: '/projects/{project}/planned-content-lists',
    method: 'post',
    ...variables,
    signal,
  });

export const usePlannedContentListStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PlannedContentListStoreResponse,
      PlannedContentListStoreError,
      PlannedContentListStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    PlannedContentListStoreResponse,
    PlannedContentListStoreError,
    PlannedContentListStoreVariables
  >({
    mutationFn: (variables: PlannedContentListStoreVariables) =>
      fetchPlannedContentListStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PlannedContentListUpdatePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The planned content list ID
   */
  plannedContentList: number;
};

export type PlannedContentListUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type PlannedContentListUpdateResponse = {
  data: Schemas.ContentListResource;
};

export type PlannedContentListUpdateVariables = {
  body: Schemas.UpdatePlannedContentListRequest;
  pathParams: PlannedContentListUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchPlannedContentListUpdate = (
  variables: PlannedContentListUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    PlannedContentListUpdateResponse,
    PlannedContentListUpdateError,
    Schemas.UpdatePlannedContentListRequest,
    {},
    {},
    PlannedContentListUpdatePathParams
  >({
    url: '/projects/{project}/planned-content-lists/{plannedContentList}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePlannedContentListUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PlannedContentListUpdateResponse,
      PlannedContentListUpdateError,
      PlannedContentListUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    PlannedContentListUpdateResponse,
    PlannedContentListUpdateError,
    PlannedContentListUpdateVariables
  >({
    mutationFn: (variables: PlannedContentListUpdateVariables) =>
      fetchPlannedContentListUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PlannedContentListDestroyPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The planned content list ID
   */
  plannedContentList: number;
};

export type PlannedContentListDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type PlannedContentListDestroyVariables = {
  pathParams: PlannedContentListDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchPlannedContentListDestroy = (
  variables: PlannedContentListDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    PlannedContentListDestroyError,
    undefined,
    {},
    {},
    PlannedContentListDestroyPathParams
  >({
    url: '/projects/{project}/planned-content-lists/{plannedContentList}',
    method: 'delete',
    ...variables,
    signal,
  });

export const usePlannedContentListDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PlannedContentListDestroyError,
      PlannedContentListDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    PlannedContentListDestroyError,
    PlannedContentListDestroyVariables
  >({
    mutationFn: (variables: PlannedContentListDestroyVariables) =>
      fetchPlannedContentListDestroy({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PlannedContentListItemStorePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The planned content list ID
   */
  plannedContentList: number;
};

export type PlannedContentListItemStoreError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type PlannedContentListItemStoreResponse = {
  data: Schemas.PlannedContentResource[];
};

export type PlannedContentListItemStoreVariables = {
  body: Schemas.AddPlannedContentListItemsRequest;
  pathParams: PlannedContentListItemStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchPlannedContentListItemStore = (
  variables: PlannedContentListItemStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    PlannedContentListItemStoreResponse,
    PlannedContentListItemStoreError,
    Schemas.AddPlannedContentListItemsRequest,
    {},
    {},
    PlannedContentListItemStorePathParams
  >({
    url: '/projects/{project}/planned-content-lists/{plannedContentList}/add-items',
    method: 'post',
    ...variables,
    signal,
  });

export const usePlannedContentListItemStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PlannedContentListItemStoreResponse,
      PlannedContentListItemStoreError,
      PlannedContentListItemStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    PlannedContentListItemStoreResponse,
    PlannedContentListItemStoreError,
    PlannedContentListItemStoreVariables
  >({
    mutationFn: (variables: PlannedContentListItemStoreVariables) =>
      fetchPlannedContentListItemStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PlannedContentListItemDestroyPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The planned content list ID
   */
  plannedContentList: number;
};

export type PlannedContentListItemDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type PlannedContentListItemDestroyResponse = {
  data: Schemas.PlannedContentResource[];
};

export type PlannedContentListItemDestroyVariables = {
  body: Schemas.RemovePlannedContentListItemsRequest;
  pathParams: PlannedContentListItemDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchPlannedContentListItemDestroy = (
  variables: PlannedContentListItemDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    PlannedContentListItemDestroyResponse,
    PlannedContentListItemDestroyError,
    Schemas.RemovePlannedContentListItemsRequest,
    {},
    {},
    PlannedContentListItemDestroyPathParams
  >({
    url: '/projects/{project}/planned-content-lists/{plannedContentList}/remove-items',
    method: 'post',
    ...variables,
    signal,
  });

export const usePlannedContentListItemDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PlannedContentListItemDestroyResponse,
      PlannedContentListItemDestroyError,
      PlannedContentListItemDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    PlannedContentListItemDestroyResponse,
    PlannedContentListItemDestroyError,
    PlannedContentListItemDestroyVariables
  >({
    mutationFn: (variables: PlannedContentListItemDestroyVariables) =>
      fetchPlannedContentListItemDestroy({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProductsIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id' | 'position';
  sort_direction?: 'ASC' | 'DESC';
  filters?: {
    type?: Schemas.ProductType;
  };
};

export type ProductsIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProductsIndexResponse = {
  data: Schemas.ProductResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ProductsIndexVariables = {
  queryParams?: ProductsIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProductsIndex = (
  variables: ProductsIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProductsIndexResponse,
    ProductsIndexError,
    undefined,
    {},
    ProductsIndexQueryParams,
    {}
  >({ url: '/products', method: 'get', ...variables, signal });

export const useProductsIndex = <TData = ProductsIndexResponse,>(
  variables: ProductsIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProductsIndexResponse,
      ProductsIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<ProductsIndexResponse, ProductsIndexError, TData>({
    queryKey: queryKeyFn({
      path: '/products',
      operationId: 'productsIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProductsIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProductsShowPathParams = {
  /**
   * The product ID
   */
  product: number;
};

export type ProductsShowError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProductsShowResponse = {
  data: Schemas.ProductResource;
};

export type ProductsShowVariables = {
  pathParams: ProductsShowPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProductsShow = (
  variables: ProductsShowVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProductsShowResponse,
    ProductsShowError,
    undefined,
    {},
    {},
    ProductsShowPathParams
  >({ url: '/products/{product}', method: 'get', ...variables, signal });

export const useProductsShow = <TData = ProductsShowResponse,>(
  variables: ProductsShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ProductsShowResponse, ProductsShowError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<ProductsShowResponse, ProductsShowError, TData>({
    queryKey: queryKeyFn({
      path: '/products/{product}',
      operationId: 'productsShow',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProductsShow({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id';
  sort_direction?: 'ASC' | 'DESC';
};

export type ProjectsIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectsIndexResponse = {
  data: Schemas.ProjectResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ProjectsIndexVariables = {
  queryParams?: ProjectsIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsIndex = (
  variables: ProjectsIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsIndexResponse,
    ProjectsIndexError,
    undefined,
    {},
    ProjectsIndexQueryParams,
    {}
  >({ url: '/projects', method: 'get', ...variables, signal });

export const useProjectsIndex = <TData = ProjectsIndexResponse,>(
  variables: ProjectsIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsIndexResponse,
      ProjectsIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<ProjectsIndexResponse, ProjectsIndexError, TData>({
    queryKey: queryKeyFn({
      path: '/projects',
      operationId: 'projectsIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsStoreError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectsStoreResponse = {
  data: Schemas.ProjectResource;
};

export type ProjectsStoreVariables = {
  body: Schemas.CreateProjectRequest;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsStore = (
  variables: ProjectsStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsStoreResponse,
    ProjectsStoreError,
    Schemas.CreateProjectRequest,
    {},
    {},
    {}
  >({ url: '/projects', method: 'post', ...variables, signal });

export const useProjectsStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsStoreResponse,
      ProjectsStoreError,
      ProjectsStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectsStoreResponse,
    ProjectsStoreError,
    ProjectsStoreVariables
  >({
    mutationFn: (variables: ProjectsStoreVariables) =>
      fetchProjectsStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsShowPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectsShowError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectsShowResponse = {
  data: Schemas.ProjectResource;
};

export type ProjectsShowVariables = {
  pathParams: ProjectsShowPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsShow = (
  variables: ProjectsShowVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsShowResponse,
    ProjectsShowError,
    undefined,
    {},
    {},
    ProjectsShowPathParams
  >({ url: '/projects/{project}', method: 'get', ...variables, signal });

export const useProjectsShow = <TData = ProjectsShowResponse,>(
  variables: ProjectsShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ProjectsShowResponse, ProjectsShowError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<ProjectsShowResponse, ProjectsShowError, TData>({
    queryKey: queryKeyFn({
      path: '/projects/{project}',
      operationId: 'projectsShow',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsShow({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsUpdatePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectsUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectsUpdateResponse = {
  data: Schemas.ProjectResource;
};

export type ProjectsUpdateVariables = {
  body?: Schemas.UpdateProjectRequest;
  pathParams: ProjectsUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Domain and language can not be updated once a project has been created.
 */
export const fetchProjectsUpdate = (
  variables: ProjectsUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsUpdateResponse,
    ProjectsUpdateError,
    Schemas.UpdateProjectRequest,
    {},
    {},
    ProjectsUpdatePathParams
  >({ url: '/projects/{project}', method: 'put', ...variables, signal });

/**
 * Domain and language can not be updated once a project has been created.
 */
export const useProjectsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsUpdateResponse,
      ProjectsUpdateError,
      ProjectsUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectsUpdateResponse,
    ProjectsUpdateError,
    ProjectsUpdateVariables
  >({
    mutationFn: (variables: ProjectsUpdateVariables) =>
      fetchProjectsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsDestroyPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectsDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectsDestroyVariables = {
  pathParams: ProjectsDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsDestroy = (
  variables: ProjectsDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    ProjectsDestroyError,
    undefined,
    {},
    {},
    ProjectsDestroyPathParams
  >({ url: '/projects/{project}', method: 'delete', ...variables, signal });

export const useProjectsDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsDestroyError,
      ProjectsDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsDestroyError,
    ProjectsDestroyVariables
  >({
    mutationFn: (variables: ProjectsDestroyVariables) =>
      fetchProjectsDestroy({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectBulkTemplateIndexPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectBulkTemplateIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id' | 'name';
  sort_direction?: 'ASC' | 'DESC';
  filters?: {
    template_type?: Schemas.BulkGenerateMode;
  };
};

export type ProjectBulkTemplateIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectBulkTemplateIndexResponse = {
  data: Schemas.BulkGenerateResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ProjectBulkTemplateIndexVariables = {
  pathParams: ProjectBulkTemplateIndexPathParams;
  queryParams?: ProjectBulkTemplateIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectBulkTemplateIndex = (
  variables: ProjectBulkTemplateIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectBulkTemplateIndexResponse,
    ProjectBulkTemplateIndexError,
    undefined,
    {},
    ProjectBulkTemplateIndexQueryParams,
    ProjectBulkTemplateIndexPathParams
  >({
    url: '/projects/{project}/bulk-templates',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectBulkTemplateIndex = <
  TData = ProjectBulkTemplateIndexResponse,
>(
  variables: ProjectBulkTemplateIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectBulkTemplateIndexResponse,
      ProjectBulkTemplateIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectBulkTemplateIndexResponse,
    ProjectBulkTemplateIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/bulk-templates',
      operationId: 'projectBulkTemplateIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectBulkTemplateIndex(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectBulkTemplateStorePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectBulkTemplateStoreError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectBulkTemplateStoreResponse = {
  data: Schemas.BulkGenerateResource;
};

export type ProjectBulkTemplateStoreVariables = {
  body: Schemas.CreateProjectBulkTemplateRequest;
  pathParams: ProjectBulkTemplateStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectBulkTemplateStore = (
  variables: ProjectBulkTemplateStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectBulkTemplateStoreResponse,
    ProjectBulkTemplateStoreError,
    Schemas.CreateProjectBulkTemplateRequest,
    {},
    {},
    ProjectBulkTemplateStorePathParams
  >({
    url: '/projects/{project}/bulk-templates',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectBulkTemplateStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectBulkTemplateStoreResponse,
      ProjectBulkTemplateStoreError,
      ProjectBulkTemplateStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectBulkTemplateStoreResponse,
    ProjectBulkTemplateStoreError,
    ProjectBulkTemplateStoreVariables
  >({
    mutationFn: (variables: ProjectBulkTemplateStoreVariables) =>
      fetchProjectBulkTemplateStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectBulkTemplateShowPathParams = {
  /**
   * The bulk template ID
   */
  bulkTemplate: number;
};

export type ProjectBulkTemplateShowError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectBulkTemplateShowResponse = {
  data: Schemas.BulkGenerateResource;
};

export type ProjectBulkTemplateShowVariables = {
  pathParams: ProjectBulkTemplateShowPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectBulkTemplateShow = (
  variables: ProjectBulkTemplateShowVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectBulkTemplateShowResponse,
    ProjectBulkTemplateShowError,
    undefined,
    {},
    {},
    ProjectBulkTemplateShowPathParams
  >({
    url: '/bulk-templates/{bulkTemplate}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectBulkTemplateShow = <
  TData = ProjectBulkTemplateShowResponse,
>(
  variables: ProjectBulkTemplateShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectBulkTemplateShowResponse,
      ProjectBulkTemplateShowError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectBulkTemplateShowResponse,
    ProjectBulkTemplateShowError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/bulk-templates/{bulkTemplate}',
      operationId: 'projectBulkTemplateShow',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectBulkTemplateShow({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectBulkTemplateUpdatePathParams = {
  /**
   * The bulk template ID
   */
  bulkTemplate: number;
};

export type ProjectBulkTemplateUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectBulkTemplateUpdateResponse = {
  data: Schemas.BulkGenerateResource;
};

export type ProjectBulkTemplateUpdateVariables = {
  body: Schemas.ProjectBulkUpdateRequest;
  pathParams: ProjectBulkTemplateUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectBulkTemplateUpdate = (
  variables: ProjectBulkTemplateUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectBulkTemplateUpdateResponse,
    ProjectBulkTemplateUpdateError,
    Schemas.ProjectBulkUpdateRequest,
    {},
    {},
    ProjectBulkTemplateUpdatePathParams
  >({
    url: '/bulk-templates/{bulkTemplate}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectBulkTemplateUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectBulkTemplateUpdateResponse,
      ProjectBulkTemplateUpdateError,
      ProjectBulkTemplateUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectBulkTemplateUpdateResponse,
    ProjectBulkTemplateUpdateError,
    ProjectBulkTemplateUpdateVariables
  >({
    mutationFn: (variables: ProjectBulkTemplateUpdateVariables) =>
      fetchProjectBulkTemplateUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectBulkTemplateDestroyPathParams = {
  /**
   * The bulk template ID
   */
  bulkTemplate: number;
};

export type ProjectBulkTemplateDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectBulkTemplateDestroyVariables = {
  pathParams: ProjectBulkTemplateDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectBulkTemplateDestroy = (
  variables: ProjectBulkTemplateDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    ProjectBulkTemplateDestroyError,
    undefined,
    {},
    {},
    ProjectBulkTemplateDestroyPathParams
  >({
    url: '/bulk-templates/{bulkTemplate}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectBulkTemplateDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectBulkTemplateDestroyError,
      ProjectBulkTemplateDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectBulkTemplateDestroyError,
    ProjectBulkTemplateDestroyVariables
  >({
    mutationFn: (variables: ProjectBulkTemplateDestroyVariables) =>
      fetchProjectBulkTemplateDestroy({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsBrandVoicesStorePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectsBrandVoicesStoreError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectsBrandVoicesStoreResponse = {
  data: Schemas.BrandVoiceResource;
};

export type ProjectsBrandVoicesStoreVariables = {
  body: Schemas.CreateBrandVoiceRequest;
  pathParams: ProjectsBrandVoicesStorePathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Create a new brand voice.
 */
export const fetchProjectsBrandVoicesStore = (
  variables: ProjectsBrandVoicesStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsBrandVoicesStoreResponse,
    ProjectsBrandVoicesStoreError,
    Schemas.CreateBrandVoiceRequest,
    {},
    {},
    ProjectsBrandVoicesStorePathParams
  >({
    url: '/projects/{project}/brand-voices',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Create a new brand voice.
 */
export const useProjectsBrandVoicesStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsBrandVoicesStoreResponse,
      ProjectsBrandVoicesStoreError,
      ProjectsBrandVoicesStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectsBrandVoicesStoreResponse,
    ProjectsBrandVoicesStoreError,
    ProjectsBrandVoicesStoreVariables
  >({
    mutationFn: (variables: ProjectsBrandVoicesStoreVariables) =>
      fetchProjectsBrandVoicesStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsBrandVoicesUpdatePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The brand voice ID
   */
  brandVoice: number;
};

export type ProjectsBrandVoicesUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectsBrandVoicesUpdateResponse = {
  data: Schemas.BrandVoiceResource;
};

export type ProjectsBrandVoicesUpdateVariables = {
  body?: Schemas.UpdateBrandVoiceRequest;
  pathParams: ProjectsBrandVoicesUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Update a specific brand voice.
 */
export const fetchProjectsBrandVoicesUpdate = (
  variables: ProjectsBrandVoicesUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsBrandVoicesUpdateResponse,
    ProjectsBrandVoicesUpdateError,
    Schemas.UpdateBrandVoiceRequest,
    {},
    {},
    ProjectsBrandVoicesUpdatePathParams
  >({
    url: '/projects/{project}/brand-voices/{brandVoice}',
    method: 'put',
    ...variables,
    signal,
  });

/**
 * Update a specific brand voice.
 */
export const useProjectsBrandVoicesUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsBrandVoicesUpdateResponse,
      ProjectsBrandVoicesUpdateError,
      ProjectsBrandVoicesUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectsBrandVoicesUpdateResponse,
    ProjectsBrandVoicesUpdateError,
    ProjectsBrandVoicesUpdateVariables
  >({
    mutationFn: (variables: ProjectsBrandVoicesUpdateVariables) =>
      fetchProjectsBrandVoicesUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsBrandVoicesDestroyPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The brand voice ID
   */
  brandVoice: number;
};

export type ProjectsBrandVoicesDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectsBrandVoicesDestroyVariables = {
  pathParams: ProjectsBrandVoicesDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Delete a specific brand voice.
 */
export const fetchProjectsBrandVoicesDestroy = (
  variables: ProjectsBrandVoicesDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    ProjectsBrandVoicesDestroyError,
    undefined,
    {},
    {},
    ProjectsBrandVoicesDestroyPathParams
  >({
    url: '/projects/{project}/brand-voices/{brandVoice}',
    method: 'delete',
    ...variables,
    signal,
  });

/**
 * Delete a specific brand voice.
 */
export const useProjectsBrandVoicesDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsBrandVoicesDestroyError,
      ProjectsBrandVoicesDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsBrandVoicesDestroyError,
    ProjectsBrandVoicesDestroyVariables
  >({
    mutationFn: (variables: ProjectsBrandVoicesDestroyVariables) =>
      fetchProjectsBrandVoicesDestroy({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type BrandVoiceIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id' | 'created_at';
  sort_direction?: 'ASC' | 'DESC';
  filters?: {
    project_id?: number;
  };
  ['filters.project_id']?: string;
};

export type BrandVoiceIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type BrandVoiceIndexResponse = {
  data: Schemas.BrandVoiceResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type BrandVoiceIndexVariables = {
  queryParams?: BrandVoiceIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

/**
 * ### Filtering
 *
 * By default, it returns all brand voices that are not associated with any project.
 *
 * However, you can filter the brand voices by **filters[project_id]** query parameter.
 *
 * ### Broadcasting details
 *
 * Only when the returned brand voice's `status` is `in_progress` broadcasting regarding training will be happening.
 *
 * Broadcast channel: `projects.{projectId}`
 *
 * Broadcast event: `BrandVoiceTrainingUpdatedEvent`
 *
 * Broadcast event data:
 *
 * ```json
 * {"id": int}
 * ```
 */
export const fetchBrandVoiceIndex = (
  variables: BrandVoiceIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    BrandVoiceIndexResponse,
    BrandVoiceIndexError,
    undefined,
    {},
    BrandVoiceIndexQueryParams,
    {}
  >({ url: '/brand-voices', method: 'get', ...variables, signal });

/**
 * ### Filtering
 *
 * By default, it returns all brand voices that are not associated with any project.
 *
 * However, you can filter the brand voices by **filters[project_id]** query parameter.
 *
 * ### Broadcasting details
 *
 * Only when the returned brand voice's `status` is `in_progress` broadcasting regarding training will be happening.
 *
 * Broadcast channel: `projects.{projectId}`
 *
 * Broadcast event: `BrandVoiceTrainingUpdatedEvent`
 *
 * Broadcast event data:
 *
 * ```json
 * {"id": int}
 * ```
 */
export const useBrandVoiceIndex = <TData = BrandVoiceIndexResponse,>(
  variables: BrandVoiceIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      BrandVoiceIndexResponse,
      BrandVoiceIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    BrandVoiceIndexResponse,
    BrandVoiceIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/brand-voices',
      operationId: 'brandVoiceIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchBrandVoiceIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type BrandVoiceShowPathParams = {
  /**
   * The brand voice ID
   */
  brandVoice: number;
};

export type BrandVoiceShowError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type BrandVoiceShowResponse = {
  data: Schemas.BrandVoiceResource;
};

export type BrandVoiceShowVariables = {
  pathParams: BrandVoiceShowPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Show a specific brand voice.
 */
export const fetchBrandVoiceShow = (
  variables: BrandVoiceShowVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    BrandVoiceShowResponse,
    BrandVoiceShowError,
    undefined,
    {},
    {},
    BrandVoiceShowPathParams
  >({ url: '/brand-voices/{brandVoice}', method: 'get', ...variables, signal });

/**
 * Show a specific brand voice.
 */
export const useBrandVoiceShow = <TData = BrandVoiceShowResponse,>(
  variables: BrandVoiceShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      BrandVoiceShowResponse,
      BrandVoiceShowError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    BrandVoiceShowResponse,
    BrandVoiceShowError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/brand-voices/{brandVoice}',
      operationId: 'brandVoiceShow',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchBrandVoiceShow({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetGoogleSearchConsolePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type GetGoogleSearchConsoleError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type GetGoogleSearchConsoleResponse = {
  data: Schemas.ProjectConnectionGoogleSearchConsoleResource;
};

export type GetGoogleSearchConsoleVariables = {
  pathParams: GetGoogleSearchConsolePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchGetGoogleSearchConsole = (
  variables: GetGoogleSearchConsoleVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    GetGoogleSearchConsoleResponse,
    GetGoogleSearchConsoleError,
    undefined,
    {},
    {},
    GetGoogleSearchConsolePathParams
  >({
    url: '/projects/{project}/connection/google-search-console',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetGoogleSearchConsole = <
  TData = GetGoogleSearchConsoleResponse,
>(
  variables: GetGoogleSearchConsoleVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetGoogleSearchConsoleResponse,
      GetGoogleSearchConsoleError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    GetGoogleSearchConsoleResponse,
    GetGoogleSearchConsoleError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/connection/google-search-console',
      operationId: 'getGoogleSearchConsole',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetGoogleSearchConsole({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type SetupGoogleSearchConsolePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type SetupGoogleSearchConsoleError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example Failed to authenticate with Google Search Console
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type SetupGoogleSearchConsoleResponse = {
  data: Schemas.ProjectConnectionGoogleSearchConsoleResource;
};

export type SetupGoogleSearchConsoleVariables = {
  body: Schemas.AuthCodeExchangeRequest;
  pathParams: SetupGoogleSearchConsolePathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * This endpoint exchanges the auth code for an access token and creates a new Google Search Console connection.
 */
export const fetchSetupGoogleSearchConsole = (
  variables: SetupGoogleSearchConsoleVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    SetupGoogleSearchConsoleResponse,
    SetupGoogleSearchConsoleError,
    Schemas.AuthCodeExchangeRequest,
    {},
    {},
    SetupGoogleSearchConsolePathParams
  >({
    url: '/projects/{project}/connection/google-search-console',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * This endpoint exchanges the auth code for an access token and creates a new Google Search Console connection.
 */
export const useSetupGoogleSearchConsole = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      SetupGoogleSearchConsoleResponse,
      SetupGoogleSearchConsoleError,
      SetupGoogleSearchConsoleVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    SetupGoogleSearchConsoleResponse,
    SetupGoogleSearchConsoleError,
    SetupGoogleSearchConsoleVariables
  >({
    mutationFn: (variables: SetupGoogleSearchConsoleVariables) =>
      fetchSetupGoogleSearchConsole({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SelectSitePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type SelectSiteError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type SelectSiteResponse = {
  data: Schemas.ProjectConnectionGoogleSearchConsoleResource;
};

export type SelectSiteVariables = {
  body: Schemas.GoogleSearchConsoleSelectSiteRequest;
  pathParams: SelectSitePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchSelectSite = (
  variables: SelectSiteVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    SelectSiteResponse,
    SelectSiteError,
    Schemas.GoogleSearchConsoleSelectSiteRequest,
    {},
    {},
    SelectSitePathParams
  >({
    url: '/projects/{project}/connection/google-search-console/select-site',
    method: 'post',
    ...variables,
    signal,
  });

export const useSelectSite = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      SelectSiteResponse,
      SelectSiteError,
      SelectSiteVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    SelectSiteResponse,
    SelectSiteError,
    SelectSiteVariables
  >({
    mutationFn: (variables: SelectSiteVariables) =>
      fetchSelectSite({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectConnectionProjectConnectionGoogleDocsPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectConnectionProjectConnectionGoogleDocsError =
  Fetcher.ErrorWrapper<
    | {
        status: 401;
        payload: Responses.AuthenticationException;
      }
    | {
        status: 403;
        payload: Responses.AuthorizationException;
      }
    | {
        status: 404;
        payload: Responses.ModelNotFoundException;
      }
    | {
        status: 422;
        payload: Responses.ValidationException;
      }
  >;

export type ProjectConnectionProjectConnectionGoogleDocsResponse = {
  data: Schemas.ProjectConnectionGoogleDocsResource;
};

export type ProjectConnectionProjectConnectionGoogleDocsVariables = {
  body: Schemas.CreateProjectConnectionGoogleDocsRequest;
  pathParams: ProjectConnectionProjectConnectionGoogleDocsPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * This endpoint returns the URL users have to be redirected to in order to authenticate with Google credentials.
 */
export const fetchProjectConnectionProjectConnectionGoogleDocs = (
  variables: ProjectConnectionProjectConnectionGoogleDocsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectConnectionProjectConnectionGoogleDocsResponse,
    ProjectConnectionProjectConnectionGoogleDocsError,
    Schemas.CreateProjectConnectionGoogleDocsRequest,
    {},
    {},
    ProjectConnectionProjectConnectionGoogleDocsPathParams
  >({
    url: '/projects/{project}/connections/google-docs',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * This endpoint returns the URL users have to be redirected to in order to authenticate with Google credentials.
 */
export const useProjectConnectionProjectConnectionGoogleDocs = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectConnectionProjectConnectionGoogleDocsResponse,
      ProjectConnectionProjectConnectionGoogleDocsError,
      ProjectConnectionProjectConnectionGoogleDocsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectConnectionProjectConnectionGoogleDocsResponse,
    ProjectConnectionProjectConnectionGoogleDocsError,
    ProjectConnectionProjectConnectionGoogleDocsVariables
  >({
    mutationFn: (
      variables: ProjectConnectionProjectConnectionGoogleDocsVariables,
    ) =>
      fetchProjectConnectionProjectConnectionGoogleDocs({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectsConnectionsIndexPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectsConnectionsIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id' | 'type' | 'created_at' | 'updated_at';
  sort_direction?: 'ASC' | 'DESC';
};

export type ProjectsConnectionsIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectsConnectionsIndexResponse = {
  data: Schemas.ProjectConnectionResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ProjectsConnectionsIndexVariables = {
  pathParams: ProjectsConnectionsIndexPathParams;
  queryParams?: ProjectsConnectionsIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsConnectionsIndex = (
  variables: ProjectsConnectionsIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsConnectionsIndexResponse,
    ProjectsConnectionsIndexError,
    undefined,
    {},
    ProjectsConnectionsIndexQueryParams,
    ProjectsConnectionsIndexPathParams
  >({
    url: '/projects/{project}/connections',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsConnectionsIndex = <
  TData = ProjectsConnectionsIndexResponse,
>(
  variables: ProjectsConnectionsIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsConnectionsIndexResponse,
      ProjectsConnectionsIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectsConnectionsIndexResponse,
    ProjectsConnectionsIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/connections',
      operationId: 'projectsConnectionsIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsConnectionsIndex(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsConnectionsShowPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The connection ID
   */
  connection: number;
};

export type ProjectsConnectionsShowError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectsConnectionsShowResponse = {
  data: Schemas.ProjectConnectionResource;
};

export type ProjectsConnectionsShowVariables = {
  pathParams: ProjectsConnectionsShowPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsConnectionsShow = (
  variables: ProjectsConnectionsShowVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsConnectionsShowResponse,
    ProjectsConnectionsShowError,
    undefined,
    {},
    {},
    ProjectsConnectionsShowPathParams
  >({
    url: '/projects/{project}/connections/{connection}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsConnectionsShow = <
  TData = ProjectsConnectionsShowResponse,
>(
  variables: ProjectsConnectionsShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsConnectionsShowResponse,
      ProjectsConnectionsShowError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectsConnectionsShowResponse,
    ProjectsConnectionsShowError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/connections/{connection}',
      operationId: 'projectsConnectionsShow',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsConnectionsShow({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsConnectionsDestroyPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The connection ID
   */
  connection: number;
};

export type ProjectsConnectionsDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectsConnectionsDestroyVariables = {
  pathParams: ProjectsConnectionsDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsConnectionsDestroy = (
  variables: ProjectsConnectionsDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    ProjectsConnectionsDestroyError,
    undefined,
    {},
    {},
    ProjectsConnectionsDestroyPathParams
  >({
    url: '/projects/{project}/connections/{connection}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectsConnectionsDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsConnectionsDestroyError,
      ProjectsConnectionsDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsConnectionsDestroyError,
    ProjectsConnectionsDestroyVariables
  >({
    mutationFn: (variables: ProjectsConnectionsDestroyVariables) =>
      fetchProjectsConnectionsDestroy({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectConnectionWebflowStorePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectConnectionWebflowStoreError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectConnectionWebflowStoreResponse = {
  data: Schemas.ProjectConnectionWebflowRedirectResource;
};

export type ProjectConnectionWebflowStoreVariables = {
  body?: Record<string, any>;
  pathParams: ProjectConnectionWebflowStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectConnectionWebflowStore = (
  variables: ProjectConnectionWebflowStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectConnectionWebflowStoreResponse,
    ProjectConnectionWebflowStoreError,
    Record<string, any>,
    {},
    {},
    ProjectConnectionWebflowStorePathParams
  >({
    url: '/projects/{project}/connections/webflow',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectConnectionWebflowStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectConnectionWebflowStoreResponse,
      ProjectConnectionWebflowStoreError,
      ProjectConnectionWebflowStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectConnectionWebflowStoreResponse,
    ProjectConnectionWebflowStoreError,
    ProjectConnectionWebflowStoreVariables
  >({
    mutationFn: (variables: ProjectConnectionWebflowStoreVariables) =>
      fetchProjectConnectionWebflowStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectConnectionWebflowSitesPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The connection ID
   */
  connection: number;
};

export type ProjectConnectionWebflowSitesError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectConnectionWebflowSitesResponse = {
  data: Schemas.ProjectConnectionWebflowSiteResource;
};

export type ProjectConnectionWebflowSitesVariables = {
  pathParams: ProjectConnectionWebflowSitesPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectConnectionWebflowSites = (
  variables: ProjectConnectionWebflowSitesVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectConnectionWebflowSitesResponse,
    ProjectConnectionWebflowSitesError,
    undefined,
    {},
    {},
    ProjectConnectionWebflowSitesPathParams
  >({
    url: '/projects/{project}/connections/{connection}/webflow/sites',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectConnectionWebflowSites = <
  TData = ProjectConnectionWebflowSitesResponse,
>(
  variables: ProjectConnectionWebflowSitesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectConnectionWebflowSitesResponse,
      ProjectConnectionWebflowSitesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectConnectionWebflowSitesResponse,
    ProjectConnectionWebflowSitesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/connections/{connection}/webflow/sites',
      operationId: 'projectConnectionWebflowSites',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectConnectionWebflowSites(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectConnectionWebflowCollectionsPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The connection ID
   */
  connection: number;
};

export type ProjectConnectionWebflowCollectionsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectConnectionWebflowCollectionsResponse = {
  data: Schemas.ProjectConnectionWebflowCollectionResource;
};

export type ProjectConnectionWebflowCollectionsVariables = {
  pathParams: ProjectConnectionWebflowCollectionsPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectConnectionWebflowCollections = (
  variables: ProjectConnectionWebflowCollectionsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectConnectionWebflowCollectionsResponse,
    ProjectConnectionWebflowCollectionsError,
    undefined,
    {},
    {},
    ProjectConnectionWebflowCollectionsPathParams
  >({
    url: '/projects/{project}/connections/{connection}/webflow/collections',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectConnectionWebflowCollections = <
  TData = ProjectConnectionWebflowCollectionsResponse,
>(
  variables: ProjectConnectionWebflowCollectionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectConnectionWebflowCollectionsResponse,
      ProjectConnectionWebflowCollectionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectConnectionWebflowCollectionsResponse,
    ProjectConnectionWebflowCollectionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/connections/{connection}/webflow/collections',
      operationId: 'projectConnectionWebflowCollections',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectConnectionWebflowCollections(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectConnectionWebflowFieldsPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The connection ID
   */
  connection: number;
};

export type ProjectConnectionWebflowFieldsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectConnectionWebflowFieldsResponse = {
  data: Schemas.ProjectConnectionWebflowFieldResource;
};

export type ProjectConnectionWebflowFieldsVariables = {
  pathParams: ProjectConnectionWebflowFieldsPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectConnectionWebflowFields = (
  variables: ProjectConnectionWebflowFieldsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectConnectionWebflowFieldsResponse,
    ProjectConnectionWebflowFieldsError,
    undefined,
    {},
    {},
    ProjectConnectionWebflowFieldsPathParams
  >({
    url: '/projects/{project}/connections/{connection}/webflow/fields',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectConnectionWebflowFields = <
  TData = ProjectConnectionWebflowFieldsResponse,
>(
  variables: ProjectConnectionWebflowFieldsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectConnectionWebflowFieldsResponse,
      ProjectConnectionWebflowFieldsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectConnectionWebflowFieldsResponse,
    ProjectConnectionWebflowFieldsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/connections/{connection}/webflow/fields',
      operationId: 'projectConnectionWebflowFields',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectConnectionWebflowFields(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectConnectionWebflowUpdatePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The connection ID
   */
  connection: number;
};

export type ProjectConnectionWebflowUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectConnectionWebflowUpdateResponse = {
  data: Schemas.ProjectConnectionWebflowResource;
};

export type ProjectConnectionWebflowUpdateVariables = {
  body: Schemas.UpdateWebflowConnectionRequest;
  pathParams: ProjectConnectionWebflowUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectConnectionWebflowUpdate = (
  variables: ProjectConnectionWebflowUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectConnectionWebflowUpdateResponse,
    ProjectConnectionWebflowUpdateError,
    Schemas.UpdateWebflowConnectionRequest,
    {},
    {},
    ProjectConnectionWebflowUpdatePathParams
  >({
    url: '/projects/{project}/connections/{connection}/webflow',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectConnectionWebflowUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectConnectionWebflowUpdateResponse,
      ProjectConnectionWebflowUpdateError,
      ProjectConnectionWebflowUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectConnectionWebflowUpdateResponse,
    ProjectConnectionWebflowUpdateError,
    ProjectConnectionWebflowUpdateVariables
  >({
    mutationFn: (variables: ProjectConnectionWebflowUpdateVariables) =>
      fetchProjectConnectionWebflowUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectConnectionWordPressStorePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectConnectionWordPressStoreError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectConnectionWordPressStoreResponse = {
  data: Schemas.ProjectConnectionWordPressResource;
};

export type ProjectConnectionWordPressStoreVariables = {
  body: Schemas.CreateProjectConnectionWordPressRequest;
  pathParams: ProjectConnectionWordPressStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectConnectionWordPressStore = (
  variables: ProjectConnectionWordPressStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectConnectionWordPressStoreResponse,
    ProjectConnectionWordPressStoreError,
    Schemas.CreateProjectConnectionWordPressRequest,
    {},
    {},
    ProjectConnectionWordPressStorePathParams
  >({
    url: '/projects/{project}/connections/wordpress',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectConnectionWordPressStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectConnectionWordPressStoreResponse,
      ProjectConnectionWordPressStoreError,
      ProjectConnectionWordPressStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectConnectionWordPressStoreResponse,
    ProjectConnectionWordPressStoreError,
    ProjectConnectionWordPressStoreVariables
  >({
    mutationFn: (variables: ProjectConnectionWordPressStoreVariables) =>
      fetchProjectConnectionWordPressStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectConnectionWordPressUpdatePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The connection ID
   */
  connection: number;
};

export type ProjectConnectionWordPressUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectConnectionWordPressUpdateResponse = {
  data: Schemas.ProjectConnectionWordPressResource;
};

export type ProjectConnectionWordPressUpdateVariables = {
  body: Schemas.UpdateProjectConnectionWordPressRequest;
  pathParams: ProjectConnectionWordPressUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectConnectionWordPressUpdate = (
  variables: ProjectConnectionWordPressUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectConnectionWordPressUpdateResponse,
    ProjectConnectionWordPressUpdateError,
    Schemas.UpdateProjectConnectionWordPressRequest,
    {},
    {},
    ProjectConnectionWordPressUpdatePathParams
  >({
    url: '/projects/{project}/connections/{connection}/wordpress',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectConnectionWordPressUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectConnectionWordPressUpdateResponse,
      ProjectConnectionWordPressUpdateError,
      ProjectConnectionWordPressUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectConnectionWordPressUpdateResponse,
    ProjectConnectionWordPressUpdateError,
    ProjectConnectionWordPressUpdateVariables
  >({
    mutationFn: (variables: ProjectConnectionWordPressUpdateVariables) =>
      fetchProjectConnectionWordPressUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectDocumentWriteMoreStorePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type ProjectDocumentWriteMoreStoreError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectDocumentWriteMoreStoreVariables = {
  body?: Schemas.WriteMoreDocumentContentRequest;
  pathParams: ProjectDocumentWriteMoreStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectDocumentWriteMoreStore = (
  variables: ProjectDocumentWriteMoreStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    ProjectDocumentWriteMoreStoreError,
    Schemas.WriteMoreDocumentContentRequest,
    {},
    {},
    ProjectDocumentWriteMoreStorePathParams
  >({
    url: '/projects/{project}/documents/{document}/write-more-stream',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectDocumentWriteMoreStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectDocumentWriteMoreStoreError,
      ProjectDocumentWriteMoreStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectDocumentWriteMoreStoreError,
    ProjectDocumentWriteMoreStoreVariables
  >({
    mutationFn: (variables: ProjectDocumentWriteMoreStoreVariables) =>
      fetchProjectDocumentWriteMoreStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectDocumentWriteMoreStreamPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type ProjectDocumentWriteMoreStreamError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example Missing content context
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectDocumentWriteMoreStreamVariables = {
  pathParams: ProjectDocumentWriteMoreStreamPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Use the "Prepare content context" POST request before starting the stream
 */
export const fetchProjectDocumentWriteMoreStream = (
  variables: ProjectDocumentWriteMoreStreamVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    string,
    ProjectDocumentWriteMoreStreamError,
    undefined,
    {},
    {},
    ProjectDocumentWriteMoreStreamPathParams
  >({
    url: '/projects/{project}/documents/{document}/write-more-stream',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Use the "Prepare content context" POST request before starting the stream
 */
export const useProjectDocumentWriteMoreStream = <TData = string,>(
  variables: ProjectDocumentWriteMoreStreamVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      string,
      ProjectDocumentWriteMoreStreamError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    string,
    ProjectDocumentWriteMoreStreamError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/documents/{document}/write-more-stream',
      operationId: 'projectDocumentWriteMoreStream',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectDocumentWriteMoreStream(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsDomainUrlsIndexPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectsDomainUrlsIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id' | 'url' | 'title' | 'primary_keyword';
  sort_direction?: 'ASC' | 'DESC';
  filters?: {
    /**
     * @minLength 1
     * @maxLength 255
     */
    search?: string;
    is_active?: boolean;
  };
};

export type ProjectsDomainUrlsIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectsDomainUrlsIndexResponse = {
  data: Schemas.ProjectDomainUrlResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ProjectsDomainUrlsIndexVariables = {
  pathParams: ProjectsDomainUrlsIndexPathParams;
  queryParams?: ProjectsDomainUrlsIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsDomainUrlsIndex = (
  variables: ProjectsDomainUrlsIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsDomainUrlsIndexResponse,
    ProjectsDomainUrlsIndexError,
    undefined,
    {},
    ProjectsDomainUrlsIndexQueryParams,
    ProjectsDomainUrlsIndexPathParams
  >({
    url: '/projects/{project}/domain-urls',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsDomainUrlsIndex = <
  TData = ProjectsDomainUrlsIndexResponse,
>(
  variables: ProjectsDomainUrlsIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsDomainUrlsIndexResponse,
      ProjectsDomainUrlsIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectsDomainUrlsIndexResponse,
    ProjectsDomainUrlsIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/domain-urls',
      operationId: 'projectsDomainUrlsIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsDomainUrlsIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsDomainUrlsStorePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectsDomainUrlsStoreError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example Either the title or the keyword used is forbidden
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example URL limit reached
         */
        message: string;
      };
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectsDomainUrlsStoreResponse = {
  data: Schemas.ProjectDomainUrlResource;
};

export type ProjectsDomainUrlsStoreVariables = {
  body: Schemas.CreateProjectUrlRequest;
  pathParams: ProjectsDomainUrlsStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsDomainUrlsStore = (
  variables: ProjectsDomainUrlsStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsDomainUrlsStoreResponse,
    ProjectsDomainUrlsStoreError,
    Schemas.CreateProjectUrlRequest,
    {},
    {},
    ProjectsDomainUrlsStorePathParams
  >({
    url: '/projects/{project}/domain-urls',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsDomainUrlsStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsDomainUrlsStoreResponse,
      ProjectsDomainUrlsStoreError,
      ProjectsDomainUrlsStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectsDomainUrlsStoreResponse,
    ProjectsDomainUrlsStoreError,
    ProjectsDomainUrlsStoreVariables
  >({
    mutationFn: (variables: ProjectsDomainUrlsStoreVariables) =>
      fetchProjectsDomainUrlsStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsDomainUrlsUpdatePathParams = {
  /**
   * The project ID
   */
  project: number;
  domainUrl: string;
};

export type ProjectsDomainUrlsUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example The keyword used is forbidden
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example URL limit reached
         */
        message: string;
      };
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectsDomainUrlsUpdateVariables = {
  body?: Schemas.UpdateProjectUrlRequest;
  pathParams: ProjectsDomainUrlsUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsDomainUrlsUpdate = (
  variables: ProjectsDomainUrlsUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    string,
    ProjectsDomainUrlsUpdateError,
    Schemas.UpdateProjectUrlRequest,
    {},
    {},
    ProjectsDomainUrlsUpdatePathParams
  >({
    url: '/projects/{project}/domain-urls/{domainUrl}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsDomainUrlsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      ProjectsDomainUrlsUpdateError,
      ProjectsDomainUrlsUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    string,
    ProjectsDomainUrlsUpdateError,
    ProjectsDomainUrlsUpdateVariables
  >({
    mutationFn: (variables: ProjectsDomainUrlsUpdateVariables) =>
      fetchProjectsDomainUrlsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectProjectDomainUrlExportPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectProjectDomainUrlExportError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectProjectDomainUrlExportVariables = {
  body?: Schemas.ProjectUrlExportRequest;
  pathParams: ProjectProjectDomainUrlExportPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectProjectDomainUrlExport = (
  variables: ProjectProjectDomainUrlExportVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    string,
    ProjectProjectDomainUrlExportError,
    Schemas.ProjectUrlExportRequest,
    {},
    {},
    ProjectProjectDomainUrlExportPathParams
  >({
    url: '/projects/{project}/domain-urls/export',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectProjectDomainUrlExport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      ProjectProjectDomainUrlExportError,
      ProjectProjectDomainUrlExportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    string,
    ProjectProjectDomainUrlExportError,
    ProjectProjectDomainUrlExportVariables
  >({
    mutationFn: (variables: ProjectProjectDomainUrlExportVariables) =>
      fetchProjectProjectDomainUrlExport({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectProjectGenerateDocumentTitlePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectProjectGenerateDocumentTitleError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload:
        | {
            /**
             * Error overview.
             *
             * @example The chosen keyword is not allowed.
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example The chosen brief is not allowed.
             */
            message: string;
          };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectProjectGenerateDocumentTitleResponse = {
  data: Schemas.DocumentTitleResource[];
};

export type ProjectProjectGenerateDocumentTitleVariables = {
  body: Schemas.GenerateDocumentTitlesRequest;
  pathParams: ProjectProjectGenerateDocumentTitlePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectProjectGenerateDocumentTitle = (
  variables: ProjectProjectGenerateDocumentTitleVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectProjectGenerateDocumentTitleResponse,
    ProjectProjectGenerateDocumentTitleError,
    Schemas.GenerateDocumentTitlesRequest,
    {},
    {},
    ProjectProjectGenerateDocumentTitlePathParams
  >({
    url: '/projects/{project}/generate-titles',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectProjectGenerateDocumentTitle = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectProjectGenerateDocumentTitleResponse,
      ProjectProjectGenerateDocumentTitleError,
      ProjectProjectGenerateDocumentTitleVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectProjectGenerateDocumentTitleResponse,
    ProjectProjectGenerateDocumentTitleError,
    ProjectProjectGenerateDocumentTitleVariables
  >({
    mutationFn: (variables: ProjectProjectGenerateDocumentTitleVariables) =>
      fetchProjectProjectGenerateDocumentTitle({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectsProjectKeywordsIndexPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectsProjectKeywordsIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'created_at' | 'name' | 'search_volume' | 'difficulty';
  sort_direction?: 'ASC' | 'DESC';
  filters?: {
    /**
     * @minLength 1
     * @maxLength 255
     */
    search?: string;
    has_documents?: boolean | null;
    /**
     * @minimum 1
     */
    search_volume_min?: number;
    /**
     * @minimum 1
     * @maximum 100
     */
    difficulty_min?: number;
    /**
     * @minimum 1
     * @maximum 100
     */
    difficulty_max?: number;
  };
};

export type ProjectsProjectKeywordsIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectsProjectKeywordsIndexResponse = {
  data: Schemas.ProjectKeywordResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ProjectsProjectKeywordsIndexVariables = {
  pathParams: ProjectsProjectKeywordsIndexPathParams;
  queryParams?: ProjectsProjectKeywordsIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsProjectKeywordsIndex = (
  variables: ProjectsProjectKeywordsIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsProjectKeywordsIndexResponse,
    ProjectsProjectKeywordsIndexError,
    undefined,
    {},
    ProjectsProjectKeywordsIndexQueryParams,
    ProjectsProjectKeywordsIndexPathParams
  >({
    url: '/projects/{project}/project-keywords',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsProjectKeywordsIndex = <
  TData = ProjectsProjectKeywordsIndexResponse,
>(
  variables: ProjectsProjectKeywordsIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsProjectKeywordsIndexResponse,
      ProjectsProjectKeywordsIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectsProjectKeywordsIndexResponse,
    ProjectsProjectKeywordsIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/project-keywords',
      operationId: 'projectsProjectKeywordsIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsProjectKeywordsIndex(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsProjectKeywordsStorePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectsProjectKeywordsStoreError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example The chosen keyword is not allowed
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectsProjectKeywordsStoreResponse = {
  data: Schemas.ProjectKeywordResource;
};

export type ProjectsProjectKeywordsStoreVariables = {
  body?: Schemas.CreateProjectKeywordRequest;
  pathParams: ProjectsProjectKeywordsStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsProjectKeywordsStore = (
  variables: ProjectsProjectKeywordsStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsProjectKeywordsStoreResponse,
    ProjectsProjectKeywordsStoreError,
    Schemas.CreateProjectKeywordRequest,
    {},
    {},
    ProjectsProjectKeywordsStorePathParams
  >({
    url: '/projects/{project}/project-keywords',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsProjectKeywordsStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsProjectKeywordsStoreResponse,
      ProjectsProjectKeywordsStoreError,
      ProjectsProjectKeywordsStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectsProjectKeywordsStoreResponse,
    ProjectsProjectKeywordsStoreError,
    ProjectsProjectKeywordsStoreVariables
  >({
    mutationFn: (variables: ProjectsProjectKeywordsStoreVariables) =>
      fetchProjectsProjectKeywordsStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsProjectKeywordsShowPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The project keyword ID
   */
  projectKeyword: number;
};

export type ProjectsProjectKeywordsShowError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectsProjectKeywordsShowResponse = {
  data: Schemas.ProjectKeywordResource;
};

export type ProjectsProjectKeywordsShowVariables = {
  pathParams: ProjectsProjectKeywordsShowPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsProjectKeywordsShow = (
  variables: ProjectsProjectKeywordsShowVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsProjectKeywordsShowResponse,
    ProjectsProjectKeywordsShowError,
    undefined,
    {},
    {},
    ProjectsProjectKeywordsShowPathParams
  >({
    url: '/projects/{project}/project-keywords/{projectKeyword}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsProjectKeywordsShow = <
  TData = ProjectsProjectKeywordsShowResponse,
>(
  variables: ProjectsProjectKeywordsShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsProjectKeywordsShowResponse,
      ProjectsProjectKeywordsShowError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectsProjectKeywordsShowResponse,
    ProjectsProjectKeywordsShowError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/project-keywords/{projectKeyword}',
      operationId: 'projectsProjectKeywordsShow',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsProjectKeywordsShow(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsProjectKeywordsDestroyPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The project keyword ID
   */
  projectKeyword: number;
};

export type ProjectsProjectKeywordsDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectsProjectKeywordsDestroyVariables = {
  pathParams: ProjectsProjectKeywordsDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsProjectKeywordsDestroy = (
  variables: ProjectsProjectKeywordsDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    ProjectsProjectKeywordsDestroyError,
    undefined,
    {},
    {},
    ProjectsProjectKeywordsDestroyPathParams
  >({
    url: '/projects/{project}/project-keywords/{projectKeyword}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectsProjectKeywordsDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsProjectKeywordsDestroyError,
      ProjectsProjectKeywordsDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsProjectKeywordsDestroyError,
    ProjectsProjectKeywordsDestroyVariables
  >({
    mutationFn: (variables: ProjectsProjectKeywordsDestroyVariables) =>
      fetchProjectsProjectKeywordsDestroy({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ExportListAsCsvPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ExportListAsCsvError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ExportListAsCsvVariables = {
  body?: Schemas.TrackedKeywordsExportRequest;
  pathParams: ExportListAsCsvPathParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Returns a download response of a project's tracked keywords after applying the specified filters.
 */
export const fetchExportListAsCsv = (
  variables: ExportListAsCsvVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    string,
    ExportListAsCsvError,
    Schemas.TrackedKeywordsExportRequest,
    {},
    {},
    ExportListAsCsvPathParams
  >({
    url: '/projects/{project}/tracked-keywords/export',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Returns a download response of a project's tracked keywords after applying the specified filters.
 */
export const useExportListAsCsv = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      ExportListAsCsvError,
      ExportListAsCsvVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    string,
    ExportListAsCsvError,
    ExportListAsCsvVariables
  >({
    mutationFn: (variables: ExportListAsCsvVariables) =>
      fetchExportListAsCsv({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetSuggestionsPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type GetSuggestionsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type GetSuggestionsResponse = {
  data: Schemas.KeywordResource[];
};

export type GetSuggestionsVariables = {
  pathParams: GetSuggestionsPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchGetSuggestions = (
  variables: GetSuggestionsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    GetSuggestionsResponse,
    GetSuggestionsError,
    undefined,
    {},
    {},
    GetSuggestionsPathParams
  >({
    url: '/projects/{project}/rank-tracking-suggestions',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetSuggestions = <TData = GetSuggestionsResponse,>(
  variables: GetSuggestionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetSuggestionsResponse,
      GetSuggestionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    GetSuggestionsResponse,
    GetSuggestionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/rank-tracking-suggestions',
      operationId: 'getSuggestions',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetSuggestions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTrackedKeywordsIndexPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectsTrackedKeywordsIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?:
    | 'id'
    | 'keyword'
    | 'position'
    | 'change'
    | 'search_volume'
    | 'difficulty'
    | 'url'
    | 'estimated_traffic';
  sort_direction?: 'ASC' | 'DESC';
  filters?: {
    search?: string;
    /**
     * @minimum 1
     * @maximum 100
     */
    position_min?: number;
    /**
     * @minimum 1
     * @maximum 100
     */
    position_max?: number;
    /**
     * @minimum -100
     * @maximum 100
     */
    change_min?: number;
    /**
     * @minimum -100
     * @maximum 100
     */
    change_max?: number;
    /**
     * @minimum 0
     */
    search_volume_min?: number;
    /**
     * @minimum 0
     */
    search_volume_max?: number;
    /**
     * @minimum 0
     * @maximum 100
     */
    difficulty_min?: number;
    /**
     * @minimum 0
     * @maximum 100
     */
    difficulty_max?: number;
    list_id?: number;
  };
};

export type ProjectsTrackedKeywordsIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectsTrackedKeywordsIndexResponse = {
  data: Schemas.TrackedKeywordResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ProjectsTrackedKeywordsIndexVariables = {
  pathParams: ProjectsTrackedKeywordsIndexPathParams;
  queryParams?: ProjectsTrackedKeywordsIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

/**
 * ### Broadcasting details
 *
 * Broadcast channel: `projects.{projectId}`
 *
 * Broadcast event: `TrackedKeywordUpdatedEvent`
 *
 * Broadcast event data:
 *
 * ```json
 * {
 *   "id": 1,
 *   "keyword": "how to treat hemorrhoids"
 * }
 * ```
 */
export const fetchProjectsTrackedKeywordsIndex = (
  variables: ProjectsTrackedKeywordsIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsTrackedKeywordsIndexResponse,
    ProjectsTrackedKeywordsIndexError,
    undefined,
    {},
    ProjectsTrackedKeywordsIndexQueryParams,
    ProjectsTrackedKeywordsIndexPathParams
  >({
    url: '/projects/{project}/tracked-keywords',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * ### Broadcasting details
 *
 * Broadcast channel: `projects.{projectId}`
 *
 * Broadcast event: `TrackedKeywordUpdatedEvent`
 *
 * Broadcast event data:
 *
 * ```json
 * {
 *   "id": 1,
 *   "keyword": "how to treat hemorrhoids"
 * }
 * ```
 */
export const useProjectsTrackedKeywordsIndex = <
  TData = ProjectsTrackedKeywordsIndexResponse,
>(
  variables: ProjectsTrackedKeywordsIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTrackedKeywordsIndexResponse,
      ProjectsTrackedKeywordsIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectsTrackedKeywordsIndexResponse,
    ProjectsTrackedKeywordsIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/tracked-keywords',
      operationId: 'projectsTrackedKeywordsIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTrackedKeywordsIndex(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTrackedKeywordsStorePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectsTrackedKeywordsStoreError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example A valid project domain is required to track keywords.
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 402;
      payload: {
        /**
         * Error overview.
         *
         * @example Insufficient tracked keyword credits.
         */
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectsTrackedKeywordsStoreResponse = {
  data: Schemas.TrackedKeywordResource[];
};

export type ProjectsTrackedKeywordsStoreVariables = {
  body: Schemas.AddTrackedKeywordRequest;
  pathParams: ProjectsTrackedKeywordsStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsTrackedKeywordsStore = (
  variables: ProjectsTrackedKeywordsStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsTrackedKeywordsStoreResponse,
    ProjectsTrackedKeywordsStoreError,
    Schemas.AddTrackedKeywordRequest,
    {},
    {},
    ProjectsTrackedKeywordsStorePathParams
  >({
    url: '/projects/{project}/tracked-keywords',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsTrackedKeywordsStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsTrackedKeywordsStoreResponse,
      ProjectsTrackedKeywordsStoreError,
      ProjectsTrackedKeywordsStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProjectsTrackedKeywordsStoreResponse,
    ProjectsTrackedKeywordsStoreError,
    ProjectsTrackedKeywordsStoreVariables
  >({
    mutationFn: (variables: ProjectsTrackedKeywordsStoreVariables) =>
      fetchProjectsTrackedKeywordsStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectTrackedKeywordDeletePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectTrackedKeywordDeleteQueryParams = {
  tracked_keyword_ids: number[];
};

export type ProjectTrackedKeywordDeleteError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectTrackedKeywordDeleteVariables = {
  pathParams: ProjectTrackedKeywordDeletePathParams;
  queryParams: ProjectTrackedKeywordDeleteQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectTrackedKeywordDelete = (
  variables: ProjectTrackedKeywordDeleteVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    ProjectTrackedKeywordDeleteError,
    undefined,
    {},
    ProjectTrackedKeywordDeleteQueryParams,
    ProjectTrackedKeywordDeletePathParams
  >({
    url: '/projects/{project}/tracked-keywords',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectTrackedKeywordDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectTrackedKeywordDeleteError,
      ProjectTrackedKeywordDeleteVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectTrackedKeywordDeleteError,
    ProjectTrackedKeywordDeleteVariables
  >({
    mutationFn: (variables: ProjectTrackedKeywordDeleteVariables) =>
      fetchProjectTrackedKeywordDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsTrackedKeywordsShowPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The tracked keyword ID
   */
  trackedKeyword: number;
};

export type ProjectsTrackedKeywordsShowQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'date' | 'position' | 'change' | 'url';
  sort_direction?: 'ASC' | 'DESC';
};

export type ProjectsTrackedKeywordsShowError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectsTrackedKeywordsShowResponse = {
  data: Schemas.TrackedKeywordUpdateResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type ProjectsTrackedKeywordsShowVariables = {
  pathParams: ProjectsTrackedKeywordsShowPathParams;
  queryParams?: ProjectsTrackedKeywordsShowQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectsTrackedKeywordsShow = (
  variables: ProjectsTrackedKeywordsShowVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectsTrackedKeywordsShowResponse,
    ProjectsTrackedKeywordsShowError,
    undefined,
    {},
    ProjectsTrackedKeywordsShowQueryParams,
    ProjectsTrackedKeywordsShowPathParams
  >({
    url: '/projects/{project}/tracked-keywords/{trackedKeyword}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTrackedKeywordsShow = <
  TData = ProjectsTrackedKeywordsShowResponse,
>(
  variables: ProjectsTrackedKeywordsShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTrackedKeywordsShowResponse,
      ProjectsTrackedKeywordsShowError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectsTrackedKeywordsShowResponse,
    ProjectsTrackedKeywordsShowError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/tracked-keywords/{trackedKeyword}',
      operationId: 'projectsTrackedKeywordsShow',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTrackedKeywordsShow(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectTrackedKeywordGraphPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type ProjectTrackedKeywordGraphQueryParams = {
  /**
   * How far back to fetch data. Must be a string in the format of P{n}W, P{n}M, or P{n}Y, specifying a range of n weeks, months, or years.
   */
  range?: string;
  /**
   * The interval between each data point. Must be a string in the format of P{n}D, P{n}W, P{n}M, or P{n}Y specifying an interval of n days, weeks, months, or years.
   */
  interval?: string;
  /**
   * Must be an array of integers, each corresponding to a tracked keyword ID. If left empty, the request will return data for all tracked keywords.
   *
   * @minItems 1
   */
  tracked_keyword_ids?: number[];
};

export type ProjectTrackedKeywordGraphError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProjectTrackedKeywordGraphResponse = {
  data: Schemas.TrackedKeywordGraphDataResource[];
};

export type ProjectTrackedKeywordGraphVariables = {
  pathParams: ProjectTrackedKeywordGraphPathParams;
  queryParams?: ProjectTrackedKeywordGraphQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectTrackedKeywordGraph = (
  variables: ProjectTrackedKeywordGraphVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProjectTrackedKeywordGraphResponse,
    ProjectTrackedKeywordGraphError,
    undefined,
    {},
    ProjectTrackedKeywordGraphQueryParams,
    ProjectTrackedKeywordGraphPathParams
  >({
    url: '/projects/{project}/tracked-keywords-graph',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectTrackedKeywordGraph = <
  TData = ProjectTrackedKeywordGraphResponse,
>(
  variables: ProjectTrackedKeywordGraphVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectTrackedKeywordGraphResponse,
      ProjectTrackedKeywordGraphError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    ProjectTrackedKeywordGraphResponse,
    ProjectTrackedKeywordGraphError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/tracked-keywords-graph',
      operationId: 'projectTrackedKeywordGraph',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectTrackedKeywordGraph(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetTrackedKeywordPositionTimelinePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The tracked keyword ID
   */
  trackedKeyword: number;
};

export type GetTrackedKeywordPositionTimelineQueryParams = {
  /**
   * How far back to fetch data. Must be a string in the format of P{n}W, P{n}M, or P{n}Y, specifying a range of n weeks, months, or years.
   */
  range?: string;
  /**
   * The interval between each data point. Must be a string in the format of P{n}D, P{n}W, or P{n}M, specifying an interval of n days, weeks, or months.
   */
  interval?: string;
};

export type GetTrackedKeywordPositionTimelineError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type GetTrackedKeywordPositionTimelineResponse = {
  data: Schemas.TrackedKeywordTimelineEntryResource[];
};

export type GetTrackedKeywordPositionTimelineVariables = {
  pathParams: GetTrackedKeywordPositionTimelinePathParams;
  queryParams?: GetTrackedKeywordPositionTimelineQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchGetTrackedKeywordPositionTimeline = (
  variables: GetTrackedKeywordPositionTimelineVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    GetTrackedKeywordPositionTimelineResponse,
    GetTrackedKeywordPositionTimelineError,
    undefined,
    {},
    GetTrackedKeywordPositionTimelineQueryParams,
    GetTrackedKeywordPositionTimelinePathParams
  >({
    url: '/projects/{project}/tracked-keywords/{trackedKeyword}/graph',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetTrackedKeywordPositionTimeline = <
  TData = GetTrackedKeywordPositionTimelineResponse,
>(
  variables: GetTrackedKeywordPositionTimelineVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTrackedKeywordPositionTimelineResponse,
      GetTrackedKeywordPositionTimelineError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    GetTrackedKeywordPositionTimelineResponse,
    GetTrackedKeywordPositionTimelineError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/tracked-keywords/{trackedKeyword}/graph',
      operationId: 'getTrackedKeywordPositionTimeline',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTrackedKeywordPositionTimeline(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetTrackedKeywordSerpOverviewPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The tracked keyword ID
   */
  trackedKeyword: number;
};

export type GetTrackedKeywordSerpOverviewError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type GetTrackedKeywordSerpOverviewResponse = {
  data: Schemas.TrackedKeywordSerpResource[];
};

export type GetTrackedKeywordSerpOverviewVariables = {
  pathParams: GetTrackedKeywordSerpOverviewPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchGetTrackedKeywordSerpOverview = (
  variables: GetTrackedKeywordSerpOverviewVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    GetTrackedKeywordSerpOverviewResponse,
    GetTrackedKeywordSerpOverviewError,
    undefined,
    {},
    {},
    GetTrackedKeywordSerpOverviewPathParams
  >({
    url: '/projects/{project}/tracked-keywords/{trackedKeyword}/serp-overview',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetTrackedKeywordSerpOverview = <
  TData = GetTrackedKeywordSerpOverviewResponse,
>(
  variables: GetTrackedKeywordSerpOverviewVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTrackedKeywordSerpOverviewResponse,
      GetTrackedKeywordSerpOverviewError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    GetTrackedKeywordSerpOverviewResponse,
    GetTrackedKeywordSerpOverviewError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/tracked-keywords/{trackedKeyword}/serp-overview',
      operationId: 'getTrackedKeywordSerpOverview',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTrackedKeywordSerpOverview(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectTrackedKeywordForceUpdatePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The tracked keyword ID
   */
  trackedKeyword: number;
};

export type ProjectTrackedKeywordForceUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type ProjectTrackedKeywordForceUpdateVariables = {
  body?: Record<string, any>;
  pathParams: ProjectTrackedKeywordForceUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchProjectTrackedKeywordForceUpdate = (
  variables: ProjectTrackedKeywordForceUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    ProjectTrackedKeywordForceUpdateError,
    Record<string, any>,
    {},
    {},
    ProjectTrackedKeywordForceUpdatePathParams
  >({
    url: '/projects/{project}/tracked-keywords/{trackedKeyword}/force-update',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectTrackedKeywordForceUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectTrackedKeywordForceUpdateError,
      ProjectTrackedKeywordForceUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectTrackedKeywordForceUpdateError,
    ProjectTrackedKeywordForceUpdateVariables
  >({
    mutationFn: (variables: ProjectTrackedKeywordForceUpdateVariables) =>
      fetchProjectTrackedKeywordForceUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type RankTrackingListIndexPathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type RankTrackingListIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 100
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id';
  sort_direction?: 'ASC' | 'DESC';
};

export type RankTrackingListIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type RankTrackingListIndexResponse = {
  data: Schemas.ContentListResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type RankTrackingListIndexVariables = {
  pathParams: RankTrackingListIndexPathParams;
  queryParams?: RankTrackingListIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchRankTrackingListIndex = (
  variables: RankTrackingListIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    RankTrackingListIndexResponse,
    RankTrackingListIndexError,
    undefined,
    {},
    RankTrackingListIndexQueryParams,
    RankTrackingListIndexPathParams
  >({
    url: '/projects/{project}/rank-tracking-lists',
    method: 'get',
    ...variables,
    signal,
  });

export const useRankTrackingListIndex = <
  TData = RankTrackingListIndexResponse,
>(
  variables: RankTrackingListIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      RankTrackingListIndexResponse,
      RankTrackingListIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    RankTrackingListIndexResponse,
    RankTrackingListIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/rank-tracking-lists',
      operationId: 'rankTrackingListIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchRankTrackingListIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type RankTrackingListStorePathParams = {
  /**
   * The project ID
   */
  project: number;
};

export type RankTrackingListStoreError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type RankTrackingListStoreResponse = {
  data: Schemas.ContentListResource;
};

export type RankTrackingListStoreVariables = {
  body: Schemas.CreateListRequest;
  pathParams: RankTrackingListStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchRankTrackingListStore = (
  variables: RankTrackingListStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    RankTrackingListStoreResponse,
    RankTrackingListStoreError,
    Schemas.CreateListRequest,
    {},
    {},
    RankTrackingListStorePathParams
  >({
    url: '/projects/{project}/rank-tracking-lists',
    method: 'post',
    ...variables,
    signal,
  });

export const useRankTrackingListStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      RankTrackingListStoreResponse,
      RankTrackingListStoreError,
      RankTrackingListStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    RankTrackingListStoreResponse,
    RankTrackingListStoreError,
    RankTrackingListStoreVariables
  >({
    mutationFn: (variables: RankTrackingListStoreVariables) =>
      fetchRankTrackingListStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RankTrackingListUpdatePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The rank tracking list ID
   */
  rankTrackingList: number;
};

export type RankTrackingListUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type RankTrackingListUpdateResponse = {
  data: Schemas.ContentListResource;
};

export type RankTrackingListUpdateVariables = {
  body: Schemas.UpdateRankTrackingListRequest;
  pathParams: RankTrackingListUpdatePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchRankTrackingListUpdate = (
  variables: RankTrackingListUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    RankTrackingListUpdateResponse,
    RankTrackingListUpdateError,
    Schemas.UpdateRankTrackingListRequest,
    {},
    {},
    RankTrackingListUpdatePathParams
  >({
    url: '/projects/{project}/rank-tracking-lists/{rankTrackingList}',
    method: 'put',
    ...variables,
    signal,
  });

export const useRankTrackingListUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      RankTrackingListUpdateResponse,
      RankTrackingListUpdateError,
      RankTrackingListUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    RankTrackingListUpdateResponse,
    RankTrackingListUpdateError,
    RankTrackingListUpdateVariables
  >({
    mutationFn: (variables: RankTrackingListUpdateVariables) =>
      fetchRankTrackingListUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RankTrackingListDestroyPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The rank tracking list ID
   */
  rankTrackingList: number;
};

export type RankTrackingListDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
>;

export type RankTrackingListDestroyVariables = {
  pathParams: RankTrackingListDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchRankTrackingListDestroy = (
  variables: RankTrackingListDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    RankTrackingListDestroyError,
    undefined,
    {},
    {},
    RankTrackingListDestroyPathParams
  >({
    url: '/projects/{project}/rank-tracking-lists/{rankTrackingList}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useRankTrackingListDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RankTrackingListDestroyError,
      RankTrackingListDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    RankTrackingListDestroyError,
    RankTrackingListDestroyVariables
  >({
    mutationFn: (variables: RankTrackingListDestroyVariables) =>
      fetchRankTrackingListDestroy({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RankTrackingListItemStorePathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The rank tracking list ID
   */
  rankTrackingList: number;
};

export type RankTrackingListItemStoreError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type RankTrackingListItemStoreResponse = {
  data: Schemas.TrackedKeywordResource[];
};

export type RankTrackingListItemStoreVariables = {
  body: Schemas.AddRankTrackingListItemsRequest;
  pathParams: RankTrackingListItemStorePathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchRankTrackingListItemStore = (
  variables: RankTrackingListItemStoreVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    RankTrackingListItemStoreResponse,
    RankTrackingListItemStoreError,
    Schemas.AddRankTrackingListItemsRequest,
    {},
    {},
    RankTrackingListItemStorePathParams
  >({
    url: '/projects/{project}/rank-tracking-lists/{rankTrackingList}/add-items',
    method: 'post',
    ...variables,
    signal,
  });

export const useRankTrackingListItemStore = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      RankTrackingListItemStoreResponse,
      RankTrackingListItemStoreError,
      RankTrackingListItemStoreVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    RankTrackingListItemStoreResponse,
    RankTrackingListItemStoreError,
    RankTrackingListItemStoreVariables
  >({
    mutationFn: (variables: RankTrackingListItemStoreVariables) =>
      fetchRankTrackingListItemStore({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RankTrackingListItemDestroyPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The rank tracking list ID
   */
  rankTrackingList: number;
};

export type RankTrackingListItemDestroyError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type RankTrackingListItemDestroyResponse = {
  data: Schemas.TrackedKeywordResource[];
};

export type RankTrackingListItemDestroyVariables = {
  body: Schemas.RemoveRankTrackingListItemsRequest;
  pathParams: RankTrackingListItemDestroyPathParams;
} & OpenapiContext['fetcherOptions'];

export const fetchRankTrackingListItemDestroy = (
  variables: RankTrackingListItemDestroyVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    RankTrackingListItemDestroyResponse,
    RankTrackingListItemDestroyError,
    Schemas.RemoveRankTrackingListItemsRequest,
    {},
    {},
    RankTrackingListItemDestroyPathParams
  >({
    url: '/projects/{project}/rank-tracking-lists/{rankTrackingList}/remove-items',
    method: 'post',
    ...variables,
    signal,
  });

export const useRankTrackingListItemDestroy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      RankTrackingListItemDestroyResponse,
      RankTrackingListItemDestroyError,
      RankTrackingListItemDestroyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    RankTrackingListItemDestroyResponse,
    RankTrackingListItemDestroyError,
    RankTrackingListItemDestroyVariables
  >({
    mutationFn: (variables: RankTrackingListItemDestroyVariables) =>
      fetchRankTrackingListItemDestroy({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DocumentSubHeadingSuggestionsPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type DocumentSubHeadingSuggestionsQueryParams = {
  type?: Schemas.DocumentHeadingSuggestionsPromptType;
  /**
   * The context is used differently depending on the type. For type "elaborate" it needs a subheading to generate lower level suggestions (H2 would get H3). For type "paraphrase" and "variants" it needs the subheading that it rewrites.
   *
   * @minLength 1
   * @maxLength 1000
   */
  context?: string;
};

export type DocumentSubHeadingSuggestionsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload:
        | {
            /**
             * Error overview.
             *
             * @example The chosen keyword is not allowed
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example The chosen context is not allowed
             */
            message: string;
          };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type DocumentSubHeadingSuggestionsResponse = {
  data: Schemas.SubheadingResource[];
};

export type DocumentSubHeadingSuggestionsVariables = {
  pathParams: DocumentSubHeadingSuggestionsPathParams;
  queryParams?: DocumentSubHeadingSuggestionsQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentSubHeadingSuggestions = (
  variables: DocumentSubHeadingSuggestionsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    DocumentSubHeadingSuggestionsResponse,
    DocumentSubHeadingSuggestionsError,
    undefined,
    {},
    DocumentSubHeadingSuggestionsQueryParams,
    DocumentSubHeadingSuggestionsPathParams
  >({
    url: '/projects/{project}/documents/{document}/subheading-suggestions',
    method: 'get',
    ...variables,
    signal,
  });

export const useDocumentSubHeadingSuggestions = <
  TData = DocumentSubHeadingSuggestionsResponse,
>(
  variables: DocumentSubHeadingSuggestionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DocumentSubHeadingSuggestionsResponse,
      DocumentSubHeadingSuggestionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    DocumentSubHeadingSuggestionsResponse,
    DocumentSubHeadingSuggestionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/documents/{document}/subheading-suggestions',
      operationId: 'documentSubHeadingSuggestions',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchDocumentSubHeadingSuggestions(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type OrganisationOrganisationCustomerPortalLinkPathParams = {
  /**
   * The organisation ID
   */
  organisation: number;
};

export type OrganisationOrganisationCustomerPortalLinkQueryParams = {
  /**
   * @format uri
   */
  return_url?: string;
};

export type OrganisationOrganisationCustomerPortalLinkError =
  Fetcher.ErrorWrapper<
    | {
        status: 401;
        payload: Responses.AuthenticationException;
      }
    | {
        status: 403;
        payload: Responses.AuthorizationException;
      }
    | {
        status: 404;
        payload: Responses.ModelNotFoundException;
      }
    | {
        status: 422;
        payload: Responses.ValidationException;
      }
  >;

export type OrganisationOrganisationCustomerPortalLinkResponse = {
  data: Schemas.CustomerPortalLinkResource;
};

export type OrganisationOrganisationCustomerPortalLinkVariables = {
  pathParams: OrganisationOrganisationCustomerPortalLinkPathParams;
  queryParams?: OrganisationOrganisationCustomerPortalLinkQueryParams;
} & OpenapiContext['fetcherOptions'];

/**
 * Returns a URL for a stripe customer portal. Here the user can update payment methods, and see invoices.
 */
export const fetchOrganisationOrganisationCustomerPortalLink = (
  variables: OrganisationOrganisationCustomerPortalLinkVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    OrganisationOrganisationCustomerPortalLinkResponse,
    OrganisationOrganisationCustomerPortalLinkError,
    undefined,
    {},
    OrganisationOrganisationCustomerPortalLinkQueryParams,
    OrganisationOrganisationCustomerPortalLinkPathParams
  >({
    url: '/organisations/{organisation}/customer-portal-link',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Returns a URL for a stripe customer portal. Here the user can update payment methods, and see invoices.
 */
export const useOrganisationOrganisationCustomerPortalLink = <
  TData = OrganisationOrganisationCustomerPortalLinkResponse,
>(
  variables: OrganisationOrganisationCustomerPortalLinkVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OrganisationOrganisationCustomerPortalLinkResponse,
      OrganisationOrganisationCustomerPortalLinkError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    OrganisationOrganisationCustomerPortalLinkResponse,
    OrganisationOrganisationCustomerPortalLinkError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/organisations/{organisation}/customer-portal-link',
      operationId: 'organisationOrganisationCustomerPortalLink',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOrganisationOrganisationCustomerPortalLink(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type DocumentTitleSuggestionsPathParams = {
  /**
   * The project ID
   */
  project: number;
  /**
   * The document ID
   */
  document: number;
};

export type DocumentTitleSuggestionsQueryParams = {
  type?: Schemas.DocumentTitleSuggestionsPromptType;
  /**
   * @minLength 1
   * @maxLength 1000
   */
  context?: string;
};

export type DocumentTitleSuggestionsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload:
        | {
            /**
             * Error overview.
             *
             * @example The chosen keyword is not allowed
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example The content you have written is not allowed
             */
            message: string;
          };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 404;
      payload: Responses.ModelNotFoundException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type DocumentTitleSuggestionsResponse = {
  data: Schemas.DocumentTitleResource[];
};

export type DocumentTitleSuggestionsVariables = {
  pathParams: DocumentTitleSuggestionsPathParams;
  queryParams?: DocumentTitleSuggestionsQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchDocumentTitleSuggestions = (
  variables: DocumentTitleSuggestionsVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    DocumentTitleSuggestionsResponse,
    DocumentTitleSuggestionsError,
    undefined,
    {},
    DocumentTitleSuggestionsQueryParams,
    DocumentTitleSuggestionsPathParams
  >({
    url: '/projects/{project}/documents/{document}/title-suggestions',
    method: 'get',
    ...variables,
    signal,
  });

export const useDocumentTitleSuggestions = <
  TData = DocumentTitleSuggestionsResponse,
>(
  variables: DocumentTitleSuggestionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DocumentTitleSuggestionsResponse,
      DocumentTitleSuggestionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    DocumentTitleSuggestionsResponse,
    DocumentTitleSuggestionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{project}/documents/{document}/title-suggestions',
      operationId: 'documentTitleSuggestions',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchDocumentTitleSuggestions(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProfileShowError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.AuthenticationException;
}>;

export type ProfileShowResponse = {
  data: Schemas.UserResource;
};

export type ProfileShowVariables = OpenapiContext['fetcherOptions'];

export const fetchProfileShow = (
  variables: ProfileShowVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<ProfileShowResponse, ProfileShowError, undefined, {}, {}, {}>({
    url: '/profile',
    method: 'get',
    ...variables,
    signal,
  });

export const useProfileShow = <TData = ProfileShowResponse,>(
  variables: ProfileShowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ProfileShowResponse, ProfileShowError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<ProfileShowResponse, ProfileShowError, TData>({
    queryKey: queryKeyFn({
      path: '/profile',
      operationId: 'profileShow',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProfileShow({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProfileUpdateError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProfileUpdateResponse = {
  data: Schemas.UserResource;
};

export type ProfileUpdateVariables = {
  body: Schemas.UpdateProfileRequest;
} & OpenapiContext['fetcherOptions'];

export const fetchProfileUpdate = (
  variables: ProfileUpdateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    ProfileUpdateResponse,
    ProfileUpdateError,
    Schemas.UpdateProfileRequest,
    {},
    {},
    {}
  >({ url: '/profile', method: 'put', ...variables, signal });

export const useProfileUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProfileUpdateResponse,
      ProfileUpdateError,
      ProfileUpdateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    ProfileUpdateResponse,
    ProfileUpdateError,
    ProfileUpdateVariables
  >({
    mutationFn: (variables: ProfileUpdateVariables) =>
      fetchProfileUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProfileChangePasswordError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ProfileChangePasswordVariables = {
  body: Schemas.UpdatePasswordRequest;
} & OpenapiContext['fetcherOptions'];

export const fetchProfileChangePassword = (
  variables: ProfileChangePasswordVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    ProfileChangePasswordError,
    Schemas.UpdatePasswordRequest,
    {},
    {},
    {}
  >({ url: '/profile/change-password', method: 'patch', ...variables, signal });

export const useProfileChangePassword = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProfileChangePasswordError,
      ProfileChangePasswordVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    ProfileChangePasswordError,
    ProfileChangePasswordVariables
  >({
    mutationFn: (variables: ProfileChangePasswordVariables) =>
      fetchProfileChangePassword({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AuthAppInitializeError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type AuthAppInitializeResponse = {
  data: Schemas.AppInitializeResource;
};

export type AuthAppInitializeVariables = {
  body?: Schemas.AppInitializeRequest;
} & OpenapiContext['fetcherOptions'];

/**
 * ### Broadcasting details
 *
 * Only when the frontend bundle has changed, broadcasting regarding the frontend bundle change will be happening.
 *
 * Broadcast channel: `global`
 *
 * Broadcast event: `.FrontendBundleChangedEvent`
 *
 * Broadcast event data:
 *
 * ```json
 * {
 *     "newHash": string,
 *     "oldHash": string
 * }
 * ```
 */
export const fetchAuthAppInitialize = (
  variables: AuthAppInitializeVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    AuthAppInitializeResponse,
    AuthAppInitializeError,
    Schemas.AppInitializeRequest,
    {},
    {},
    {}
  >({ url: '/app-initialize', method: 'post', ...variables, signal });

/**
 * ### Broadcasting details
 *
 * Only when the frontend bundle has changed, broadcasting regarding the frontend bundle change will be happening.
 *
 * Broadcast channel: `global`
 *
 * Broadcast event: `.FrontendBundleChangedEvent`
 *
 * Broadcast event data:
 *
 * ```json
 * {
 *     "newHash": string,
 *     "oldHash": string
 * }
 * ```
 */
export const useAuthAppInitialize = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      AuthAppInitializeResponse,
      AuthAppInitializeError,
      AuthAppInitializeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    AuthAppInitializeResponse,
    AuthAppInitializeError,
    AuthAppInitializeVariables
  >({
    mutationFn: (variables: AuthAppInitializeVariables) =>
      fetchAuthAppInitialize({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type TaxCalculationError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload:
        | string[]
        | {
            /**
             * Error overview.
             *
             * @example Products should have the same interval
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example You must specify a plan when modifying addons
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example Addons are not supported by this plan
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example The "plan" can only have a quantity of 1
             */
            message: string;
          }
        | {
            /**
             * Error overview.
             *
             * @example You must specify the organisation_id to calculate taxes
             */
            message: string;
          };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type TaxCalculationResponse = {
  data: {
    lines: string;
    sub_total: string;
    total: string;
    tax: string;
    tax_percentage: number;
  };
};

export type TaxCalculationVariables = {
  body: Schemas.TaxCalculationRequest;
} & OpenapiContext['fetcherOptions'];

export const fetchTaxCalculation = (
  variables: TaxCalculationVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    TaxCalculationResponse,
    TaxCalculationError,
    Schemas.TaxCalculationRequest,
    {},
    {},
    {}
  >({ url: '/payment/tax-calculations', method: 'post', ...variables, signal });

export const useTaxCalculation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      TaxCalculationResponse,
      TaxCalculationError,
      TaxCalculationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    TaxCalculationResponse,
    TaxCalculationError,
    TaxCalculationVariables
  >({
    mutationFn: (variables: TaxCalculationVariables) =>
      fetchTaxCalculation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AnalyzePageError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type AnalyzePageResponse = {
  data: Schemas.AnalyzedPageResource;
};

export type AnalyzePageVariables = {
  body: Schemas.AnalyzePageRequest;
} & OpenapiContext['fetcherOptions'];

/**
 * Analyzes a URL and returns it's meta data and page content.
 */
export const fetchAnalyzePage = (
  variables: AnalyzePageVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    AnalyzePageResponse,
    AnalyzePageError,
    Schemas.AnalyzePageRequest,
    {},
    {},
    {}
  >({ url: '/analyze-page', method: 'post', ...variables, signal });

/**
 * Analyzes a URL and returns it's meta data and page content.
 */
export const useAnalyzePage = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      AnalyzePageResponse,
      AnalyzePageError,
      AnalyzePageVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    AnalyzePageResponse,
    AnalyzePageError,
    AnalyzePageVariables
  >({
    mutationFn: (variables: AnalyzePageVariables) =>
      fetchAnalyzePage({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type LanguagesIndexQueryParams = {
  /**
   * @minimum 1
   * @maximum 1000
   */
  limit?: number;
  /**
   * @minimum 1
   */
  page?: number;
  sort_by?: 'id' | 'name';
  sort_direction?: 'ASC' | 'DESC';
  filters?: {
    /**
     * @minLength 1
     * @maxLength 255
     */
    search?: string;
  };
};

export type LanguagesIndexError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type LanguagesIndexResponse = {
  data: Schemas.LanguageResource[];
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    /**
     * Base path for paginator generated URLs.
     */
    path: string | null;
    /**
     * Number of items shown per page.
     */
    per_page: number;
    /**
     * Number of the last item in the slice.
     */
    to: number | null;
    /**
     * Total number of items being paginated.
     */
    total: number;
    /**
     * Whether there are more items after the current page.
     */
    has_more: boolean;
    /**
     * Whether there are more items before the current page.
     */
    has_less: boolean;
    /**
     * Number of the next page.
     */
    next_page: number | null;
    /**
     * Number of the previous page.
     */
    previous_page: number | null;
  };
};

export type LanguagesIndexVariables = {
  queryParams?: LanguagesIndexQueryParams;
} & OpenapiContext['fetcherOptions'];

export const fetchLanguagesIndex = (
  variables: LanguagesIndexVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    LanguagesIndexResponse,
    LanguagesIndexError,
    undefined,
    {},
    LanguagesIndexQueryParams,
    {}
  >({ url: '/languages', method: 'get', ...variables, signal });

export const useLanguagesIndex = <TData = LanguagesIndexResponse,>(
  variables: LanguagesIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      LanguagesIndexResponse,
      LanguagesIndexError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOpenapiContext(options);
  return reactQuery.useQuery<
    LanguagesIndexResponse,
    LanguagesIndexError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/languages',
      operationId: 'languagesIndex',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchLanguagesIndex({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ModerateError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        /**
         * Error overview.
         *
         * @example The text is not allowed.
         */
        message: string;
      };
    }
  | {
      status: 401;
      payload: Responses.AuthenticationException;
    }
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type ModerateVariables = {
  body: Schemas.ModerateRequest;
} & OpenapiContext['fetcherOptions'];

export const fetchModerate = (
  variables: ModerateVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<undefined, ModerateError, Schemas.ModerateRequest, {}, {}, {}>({
    url: '/moderate',
    method: 'post',
    ...variables,
    signal,
  });

export const useModerate = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ModerateError, ModerateVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<undefined, ModerateError, ModerateVariables>({
    mutationFn: (variables: ModerateVariables) =>
      fetchModerate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GuestLogErrorError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Responses.AuthorizationException;
    }
  | {
      status: 422;
      payload: Responses.ValidationException;
    }
>;

export type GuestLogErrorVariables = {
  body?: Schemas.LogErrorRequest;
} & OpenapiContext['fetcherOptions'];

export const fetchGuestLogError = (
  variables: GuestLogErrorVariables,
  signal?: AbortSignal,
) =>
  openapiFetch<
    undefined,
    GuestLogErrorError,
    Schemas.LogErrorRequest,
    {},
    {},
    {}
  >({ url: '/log-error', method: 'post', ...variables, signal });

export const useGuestLogError = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      GuestLogErrorError,
      GuestLogErrorVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useOpenapiContext();
  return reactQuery.useMutation<
    undefined,
    GuestLogErrorError,
    GuestLogErrorVariables
  >({
    mutationFn: (variables: GuestLogErrorVariables) =>
      fetchGuestLogError({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type QueryOperation =
  | {
      path: '/projects/{project}/content-scanner';
      operationId: 'listUrls';
      variables: ListUrlsVariables;
    }
  | {
      path: '/projects/{project}/documents';
      operationId: 'listDocuments';
      variables: ListDocumentsVariables;
    }
  | {
      path: '/projects/{project}/documents/{document}';
      operationId: 'getDocument';
      variables: GetDocumentVariables;
    }
  | {
      path: '/projects/{project}/documents/{document}/generate-stream';
      operationId: 'projectDocumentGenerateStream';
      variables: ProjectDocumentGenerateStreamVariables;
    }
  | {
      path: '/content-types';
      operationId: 'listContentTypes';
      variables: ListContentTypesVariables;
    }
  | {
      path: '/document-elements/categories';
      operationId: 'listDocumentElementByCategory';
      variables: ListDocumentElementByCategoryVariables;
    }
  | {
      path: '/document-elements';
      operationId: 'listDocumentElements';
      variables: ListDocumentElementsVariables;
    }
  | {
      path: '/projects/{project}/document-lists';
      operationId: 'projectDocumentListsIndex';
      variables: ProjectDocumentListsIndexVariables;
    }
  | {
      path: '/document-templates';
      operationId: 'listDocumentTemplates';
      variables: ListDocumentTemplatesVariables;
    }
  | {
      path: '/document-templates/{documentTemplate}';
      operationId: 'viewDocumentTemplate';
      variables: ViewDocumentTemplateVariables;
    }
  | {
      path: '/projects/{project}/documents/{document}/keyword-gaps';
      operationId: 'projectKeywordGaps';
      variables: ProjectKeywordGapsVariables;
    }
  | {
      path: '/projects/{project}/documents/{document}/meta-description-suggestions';
      operationId: 'documentMetaDescriptionSuggestions';
      variables: DocumentMetaDescriptionSuggestionsVariables;
    }
  | {
      path: '/projects/{project}/documents/{document}/meta-title-suggestions';
      operationId: 'documentMetaTitleSuggestions';
      variables: DocumentMetaTitleSuggestionsVariables;
    }
  | {
      path: '/documents/{document}/chat';
      operationId: 'documentsChatShow';
      variables: DocumentsChatShowVariables;
    }
  | {
      path: '/documents/{document}/chat-messages';
      operationId: 'documentsChatMessagesIndex';
      variables: DocumentsChatMessagesIndexVariables;
    }
  | {
      path: '/documents/{document}/chat/stream';
      operationId: 'documentChatStream';
      variables: DocumentChatStreamVariables;
    }
  | {
      path: '/projects/{project}/scanned-keywords';
      operationId: 'listScannedKeywords';
      variables: ListScannedKeywordsVariables;
    }
  | {
      path: '/projects/{project}/scanned-keywords/{rankTrackingList}/grouped';
      operationId: 'listScannedKeywordsGrouped';
      variables: ListScannedKeywordsGroupedVariables;
    }
  | {
      path: '/projects/{project}/documents/{document}/optimise-title-suggestions';
      operationId: 'documentOptimiseTitleSuggestions';
      variables: DocumentOptimiseTitleSuggestionsVariables;
    }
  | {
      path: '/organisations';
      operationId: 'organisationsIndex';
      variables: OrganisationsIndexVariables;
    }
  | {
      path: '/organisations/{organisation}';
      operationId: 'organisationsShow';
      variables: OrganisationsShowVariables;
    }
  | {
      path: '/organisations/{organisation}/organisation-invites';
      operationId: 'organisationsOrganisationInvitesIndex';
      variables: OrganisationsOrganisationInvitesIndexVariables;
    }
  | {
      path: '/organisations/{organisation}/metrics';
      operationId: 'organisationOrganisationMetrics';
      variables: OrganisationOrganisationMetricsVariables;
    }
  | {
      path: '/organisations/{organisation}/payment-setup';
      operationId: 'organisationPaymentSetup';
      variables: OrganisationPaymentSetupVariables;
    }
  | {
      path: '/organisations/{organisation}/subscriptions';
      operationId: 'organisationSubscriptionList';
      variables: OrganisationSubscriptionListVariables;
    }
  | {
      path: '/organisations/{organisation}/subscription';
      operationId: 'organisationSubscriptionIndex';
      variables: OrganisationSubscriptionIndexVariables;
    }
  | {
      path: '/organisations/{organisation}/subscription/cancel-reasons';
      operationId: 'organisationSubscriptionCancelReasonsIndex';
      variables: OrganisationSubscriptionCancelReasonsIndexVariables;
    }
  | {
      path: '/organisations/{organisation}/tags';
      operationId: 'organisationsTagsIndex';
      variables: OrganisationsTagsIndexVariables;
    }
  | {
      path: '/organisations/{organisation}/tags/{tag}';
      operationId: 'organisationsTagsShow';
      variables: OrganisationsTagsShowVariables;
    }
  | {
      path: '/organisations/{organisation}/users';
      operationId: 'organisationsUsersIndex';
      variables: OrganisationsUsersIndexVariables;
    }
  | {
      path: '/projects/{project}/planned-content';
      operationId: 'list';
      variables: ListVariables;
    }
  | {
      path: '/projects/{project}/planned-content-lists';
      operationId: 'plannedContentListIndex';
      variables: PlannedContentListIndexVariables;
    }
  | {
      path: '/products';
      operationId: 'productsIndex';
      variables: ProductsIndexVariables;
    }
  | {
      path: '/products/{product}';
      operationId: 'productsShow';
      variables: ProductsShowVariables;
    }
  | {
      path: '/projects';
      operationId: 'projectsIndex';
      variables: ProjectsIndexVariables;
    }
  | {
      path: '/projects/{project}';
      operationId: 'projectsShow';
      variables: ProjectsShowVariables;
    }
  | {
      path: '/projects/{project}/bulk-templates';
      operationId: 'projectBulkTemplateIndex';
      variables: ProjectBulkTemplateIndexVariables;
    }
  | {
      path: '/bulk-templates/{bulkTemplate}';
      operationId: 'projectBulkTemplateShow';
      variables: ProjectBulkTemplateShowVariables;
    }
  | {
      path: '/brand-voices';
      operationId: 'brandVoiceIndex';
      variables: BrandVoiceIndexVariables;
    }
  | {
      path: '/brand-voices/{brandVoice}';
      operationId: 'brandVoiceShow';
      variables: BrandVoiceShowVariables;
    }
  | {
      path: '/projects/{project}/connection/google-search-console';
      operationId: 'getGoogleSearchConsole';
      variables: GetGoogleSearchConsoleVariables;
    }
  | {
      path: '/projects/{project}/connections';
      operationId: 'projectsConnectionsIndex';
      variables: ProjectsConnectionsIndexVariables;
    }
  | {
      path: '/projects/{project}/connections/{connection}';
      operationId: 'projectsConnectionsShow';
      variables: ProjectsConnectionsShowVariables;
    }
  | {
      path: '/projects/{project}/connections/{connection}/webflow/sites';
      operationId: 'projectConnectionWebflowSites';
      variables: ProjectConnectionWebflowSitesVariables;
    }
  | {
      path: '/projects/{project}/connections/{connection}/webflow/collections';
      operationId: 'projectConnectionWebflowCollections';
      variables: ProjectConnectionWebflowCollectionsVariables;
    }
  | {
      path: '/projects/{project}/connections/{connection}/webflow/fields';
      operationId: 'projectConnectionWebflowFields';
      variables: ProjectConnectionWebflowFieldsVariables;
    }
  | {
      path: '/projects/{project}/documents/{document}/write-more-stream';
      operationId: 'projectDocumentWriteMoreStream';
      variables: ProjectDocumentWriteMoreStreamVariables;
    }
  | {
      path: '/projects/{project}/domain-urls';
      operationId: 'projectsDomainUrlsIndex';
      variables: ProjectsDomainUrlsIndexVariables;
    }
  | {
      path: '/projects/{project}/project-keywords';
      operationId: 'projectsProjectKeywordsIndex';
      variables: ProjectsProjectKeywordsIndexVariables;
    }
  | {
      path: '/projects/{project}/project-keywords/{projectKeyword}';
      operationId: 'projectsProjectKeywordsShow';
      variables: ProjectsProjectKeywordsShowVariables;
    }
  | {
      path: '/projects/{project}/rank-tracking-suggestions';
      operationId: 'getSuggestions';
      variables: GetSuggestionsVariables;
    }
  | {
      path: '/projects/{project}/tracked-keywords';
      operationId: 'projectsTrackedKeywordsIndex';
      variables: ProjectsTrackedKeywordsIndexVariables;
    }
  | {
      path: '/projects/{project}/tracked-keywords/{trackedKeyword}';
      operationId: 'projectsTrackedKeywordsShow';
      variables: ProjectsTrackedKeywordsShowVariables;
    }
  | {
      path: '/projects/{project}/tracked-keywords-graph';
      operationId: 'projectTrackedKeywordGraph';
      variables: ProjectTrackedKeywordGraphVariables;
    }
  | {
      path: '/projects/{project}/tracked-keywords/{trackedKeyword}/graph';
      operationId: 'getTrackedKeywordPositionTimeline';
      variables: GetTrackedKeywordPositionTimelineVariables;
    }
  | {
      path: '/projects/{project}/tracked-keywords/{trackedKeyword}/serp-overview';
      operationId: 'getTrackedKeywordSerpOverview';
      variables: GetTrackedKeywordSerpOverviewVariables;
    }
  | {
      path: '/projects/{project}/rank-tracking-lists';
      operationId: 'rankTrackingListIndex';
      variables: RankTrackingListIndexVariables;
    }
  | {
      path: '/projects/{project}/documents/{document}/subheading-suggestions';
      operationId: 'documentSubHeadingSuggestions';
      variables: DocumentSubHeadingSuggestionsVariables;
    }
  | {
      path: '/organisations/{organisation}/customer-portal-link';
      operationId: 'organisationOrganisationCustomerPortalLink';
      variables: OrganisationOrganisationCustomerPortalLinkVariables;
    }
  | {
      path: '/projects/{project}/documents/{document}/title-suggestions';
      operationId: 'documentTitleSuggestions';
      variables: DocumentTitleSuggestionsVariables;
    }
  | {
      path: '/profile';
      operationId: 'profileShow';
      variables: ProfileShowVariables;
    }
  | {
      path: '/languages';
      operationId: 'languagesIndex';
      variables: LanguagesIndexVariables;
    };
