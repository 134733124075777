import * as Table from '@/Components/Table';
import dayjs from 'dayjs';
import { useTable } from '@/Components/Table/hooks';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { ProjectDomainUrlResource } from '@/api/openapiSchemas';
import { ProjectUrlAddButton, ProjectUrlCheckBoxButton } from './components';
import { useAppStore } from '../../AppLoader/stores';
import { Files } from 'lucide-react';
import { ItemSelector } from '@/Components/Table/components/ItemSelector';
import { useQueryClient } from '@tanstack/react-query';
import { EditableText } from '@/Components/EditableText';
import { Button } from '@/Components/v2/Button';
import {
  useProjectProjectDomainUrlExport,
  useProjectsDomainUrlsIndex,
  useProjectsDomainUrlsUpdate,
} from '@/api/openapiComponents';
import { downloadCSV } from '@/utils';
import { useNavigate } from '@tanstack/react-router';
import { Subheading } from '../components/Subheading';

export default function ProjectUrls() {
  const appState = useAppStore();
  const navigate = useNavigate();
  const {
    mutateAsync: exportProjectUrls,
    isPending: isLoadingProjectUrlExport,
  } = useProjectProjectDomainUrlExport();

  const client = useQueryClient();

  const { tableState, setFilters, setPage, setSorting } = useTable<
    'id' | 'url' | 'title' | 'primary_keyword',
    { search: string; is_active: any }
  >();

  const updateProjectUrlMutation = useProjectsDomainUrlsUpdate();

  const handleTargetKeywordChange = (
    value: string,
    item: ProjectDomainUrlResource,
  ) => {
    client.setQueriesData<any>(
      {
        predicate: (query) =>
          query.queryKey.includes('projectsDomainUrlsIndex'),
      },
      (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          data: prev.data.map((currentItem) => {
            if (item.id === currentItem.id) {
              return { ...currentItem, primary_keyword: value };
            }
            return currentItem;
          }),
        };
      },
    );
  };

  const totalQuery = useProjectsDomainUrlsIndex({
    pathParams: {
      project: appState.currentProject!.id,
    },
  });

  const domainQuery = useProjectsDomainUrlsIndex({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      limit: 25,
      ...tableState,
    },
  });

  appState.pageTitle('Website urls');

  return (
    <>
      <Subheading
        text={
          <>
            We've indexed <b>{totalQuery.data?.meta.total}</b> Website URLs on
            the domain <b>{appState.currentProject!.domain}</b> to be used for{' '}
            <a
              className={'text-blue-500 underline'}
              href={'https://docs.seo.ai/a-13-internal-linking'}
              target={'_blank'}
              rel="noreferrer"
            >
              internal linking
            </a>
            .
            <br />
            <br />
            Index status:{' '}
            <i>
              {appState.currentProject!.last_crawled_at
                ? 'Last update ' +
                  dayjs(appState.currentProject!.last_crawled_at).format(
                    'YYYY-MM-DD HH:mm',
                  )
                : 'In progress'}
            </i>
          </>
        }
      />

      <div className="flex justify-end">
        <ProjectUrlAddButton projectId={appState.currentProject!.id} />
      </div>

      <Table.Root
        meta={domainQuery.data?.meta}
        onSortChange={setSorting}
        sorting={tableState}
        isLoading={domainQuery.isLoading || domainQuery.isFetching}
        items={domainQuery.data?.data}
        columns={[
          {
            property: 'title',
            sortableHeader: true,
          },
          {
            property: 'url',
            sortableHeader: true,
            render: (item) => {
              let url = item.url;
              try {
                url = new URL(item.url).pathname;
              } catch (e) {}
              return (
                <a
                  className="inline-block max-w-xs cursor-pointer truncate text-ellipsis pr-2"
                  href={item.url}
                  title={item.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {url}
                </a>
              );
            },
          },
          {
            property: 'primary_keyword',
            sortableHeader: true,
            heading: 'Target keyword',
            render: (item) => (
              <div>
                <p className="flex items-center gap-1">
                  <EditableText
                    text={item.primary_keyword ?? ''}
                    onChange={(value) => {
                      handleTargetKeywordChange(value, item);
                    }}
                    onDebounceChange={(value) => {
                      updateProjectUrlMutation.mutate({
                        pathParams: {
                          domainUrl: item.id.toString(),
                          project: appState.currentProject!.id,
                        },
                        body: {
                          primary_keyword: value
                        },
                      });
                    }}
                  />
                </p>
              </div>
            ),
          },
          {
            property: 'is_active',
            heading: 'Ignored',
            render: (item) => (
              <ProjectUrlCheckBoxButton
                projectId={appState.currentProject!.id}
                item={item}
                tableState={tableState}
              />
            ),
          },
          {
            render: (item) => (
              <Button
                onClick={() => {
                  navigate({
                    to: '/create-content/import',
                    search: (prev) => ({ ...prev, url: item.url }),
                  });
                }}
                size="xs"
                dense
                variant="outline"
                color="primary"
                text="Optimise"
                prependIcon={Files}
              />
            ),
          },
        ]}
      >
        <Table.Header
          onExportCSV={{
            isLoading: isLoadingProjectUrlExport,
            onClick: () => {
              exportProjectUrls({
                pathParams: { project: appState.currentProject!.id },
                body: {
                  filter_search: tableState.filters?.search,
                  filter_is_active: tableState.filters?.is_active,
                  sort_by: tableState.sortBy,
                  sort_direction: tableState.sortDirection,
                },
              }).then((response) => {
                downloadCSV(response, 'website_urls.csv');
              });
            },
          }}
          search={tableState.filters?.search}
          onSearchChange={(value) => setFilters({ search: value })}
        >
          <Table.FilterPopover
            name="Show active urls"
            filterName={
              tableState.filters?.is_active !== undefined
                ? `Show ignored urls`
                : undefined
            }
            onRemoveFilter={() =>
              setFilters({
                is_active: undefined,
              })
            }
          >
            <ItemSelector
              items={[
                {
                  name: 'Show ignored urls',
                  value: 0,
                },
              ]}
              value={tableState.filters?.is_active}
              onAccept={({ value }) => setFilters({ is_active: value })}
            />
          </Table.FilterPopover>
        </Table.Header>
        <Table.Footer>
          <Pagination
            currentPage={tableState.page}
            setCurrentPage={setPage}
            lastPage={domainQuery.data?.meta.last_page}
          />
        </Table.Footer>
      </Table.Root>
    </>
  );
}
