import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { Tabs } from '@/Components/v2/Tabs/Tabs';
import { ContentListResource } from '@/api/openapiSchemas';
import { MoreVertical, Pencil, Plus, Trash2 } from 'lucide-react';
import { EditListDialog } from './EditListDialog';
import { RemoveListDialog } from './RemoveListDialog';
import { AddListDialog } from './AddListDialog';
import { Button } from '@/Components/v2/Button';

type Controller = {
  list: ContentListResource | undefined;
  handleSubmit: (name?: string) => void;
  error?: any;
  open: (list: ContentListResource) => void;
  isLoading: boolean;
};

type Props = {
  isLoading: boolean;
  onChange: (index: number) => void;
  selectedTabIndex: number;
  selectedList: ContentListResource | undefined;
  data: ContentListResource[] | undefined;
  editController: Controller;
  deleteController: Controller;
  addController: Omit<Controller, 'list' | 'isLoading' | 'open'> & {
    isOpen: boolean;
    open: () => void;
  };
  onCloseDialog: () => void;
};

export const ListTab = ({
  isLoading,
  selectedTabIndex,
  data,
  onChange,
  selectedList,
  onCloseDialog,
  editController,
  deleteController,
  addController,
}: Props) => {
  return (
    <>
      <EditListDialog
        editedList={editController.list}
        error={editController.error}
        handleSubmit={editController.handleSubmit}
        key={editController.list?.id}
        onClose={onCloseDialog}
      />
      <RemoveListDialog
        handleDeleteSubmit={deleteController.handleSubmit}
        isLoading={deleteController.isLoading}
        key={deleteController.list?.id}
        onClose={onCloseDialog}
        deletedList={deleteController.list}
      />
      <AddListDialog
        key={addController.isOpen ? 'open' : 'close'}
        error={addController.error}
        isOpen={addController.isOpen}
        onClose={onCloseDialog}
        handleSubmit={addController.handleSubmit}
      />
      <div className="mb-2">
        {isLoading ? (
          <div className="mt-1">
            <SkeletonLoader height="xs" />
          </div>
        ) : (
          <div className="flex gap-2 border-b">
            <Tabs
              noBorder
              color="gray"
              onChange={onChange}
              value={selectedTabIndex}
              tabs={[
                {
                  text: 'All',
                },
                ...(data?.map((list) => ({
                  text: list.name,
                  appendIcon:
                    list.id === selectedList?.id ? (
                      <PopoverMenu
                        key={list.id}
                        trigger={
                          <div className="relative">
                            <div className="absolute -left-1.5 -top-4 w-0">
                              <IconButton size="sm" dense icon={MoreVertical} />
                            </div>
                          </div>
                        }
                        items={[
                          {
                            title: 'Edit list',
                            prependIcon: Pencil,
                            onClick: () => editController.open(list),
                          },
                          {
                            title: 'Delete list',
                            prependIcon: Trash2,
                            onClick: () => deleteController.open(list),
                          },
                        ]}
                      />
                    ) : null,
                })) ?? []),
              ]}
            />
            <Button
              dense
              appendIcon={Plus}
              variant="ghost"
              text="Add list"
              size="sm"
              disabled={(data?.length ?? 0) >= 100}
              onClick={addController.open}
            />
          </div>
        )}
      </div>
    </>
  );
};
