import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import SimpleTitle from '@/Components/SimpleTitle';
import { CardSection } from '../components/CardSection';
import { ActionCard } from '../components/ActionCard';
import { useNavigate, useSearch } from '@tanstack/react-router';
import {
  useCreateDocumentTemplate,
  useDeleteDocumentTemplate,
  useDocumentTemplateDuplicate,
  useListDocumentTemplates,
} from '@/api/openapiComponents';
import { useAppStore } from '../AppLoader/stores';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { useState } from 'react';

type Template = { id: number; description: string; name: string };
const initialDeleteState = { isOpen: false, template: null };
export const Templates = () => {
  const navigate = useNavigate();
  const projectId = useAppStore((state) => state.currentProject!.id);
  const [deleteDialog, setDeleteDialog] = useState<{
    isOpen: boolean;
    template: Template | null;
  }>(initialDeleteState);

  const search = useSearch({ from: '/templates' });
  const customTemplatesQuery = useListDocumentTemplates({
    queryParams: {
      limit: 9,
      page: search.page ?? 1,
      filters: {
        project_id: projectId,
      },
    },
  });

  const preDefinedTemplatesQuery = useListDocumentTemplates({});

  const deleteTemplateMutation = useDeleteDocumentTemplate({
    onSuccess: () => {
      customTemplatesQuery.refetch();
    },
  });

  const duplicateTemplatesMutation = useDocumentTemplateDuplicate({
    onSuccess: () => {
      customTemplatesQuery.refetch();
      preDefinedTemplatesQuery.refetch();
    },
  });

  const createTemplateMutation = useCreateDocumentTemplate();

  const handleEditTemplate = (template: Template) => {
    navigate({
      to: '/templates/template',
      search: { templateId: template.id },
    });
  };

  const customMenuItems = (template: Template) => [
    {
      title: 'Edit',
      onClick: () => handleEditTemplate(template),
    },
    {
      title: 'Duplicate',
      onClick: () => {
        duplicateTemplatesMutation.mutate({
          pathParams: {
            documentTemplate: template.id,
          },
          body: {
            project_id: projectId,
          },
        });
      },
    },
    {
      title: 'Delete',
      onClick: () => {
        setDeleteDialog((prev) => ({ ...prev, isOpen: true, template }));
      },
    },
  ];

  const preDefinedMenuItems = (template: Template) => [
    {
      title: 'Duplicate',
      onClick: () => {
        duplicateTemplatesMutation.mutate({
          pathParams: {
            documentTemplate: template.id,
          },
          body: {
            project_id: projectId,
          },
        });
      },
    },
  ];

  const handleAddNewTemplate = () => {
    createTemplateMutation.mutate(
      {
        pathParams: {
          project: projectId,
        },
      },
      {
        onSuccess: (data) => {
          navigate({
            to: '/templates/template',
            search: { templateId: data.data.id },
          });
        },
      },
    );
  };

  return (
    <>
      <ConfirmDialog
        isOpen={deleteDialog.isOpen}
        title={'Delete template'}
        onClose={() => setDeleteDialog(initialDeleteState)}
        onCancel={() => setDeleteDialog(initialDeleteState)}
        content={`Are you sure you want to delete ${deleteDialog.template?.name}?`}
        onConfirm={() => {
          deleteTemplateMutation
            .mutateAsync({
              pathParams: {
                documentTemplate: deleteDialog.template!.id,
              },
            })
            .then(() => {
              setDeleteDialog(initialDeleteState);
            });
        }}
        isLoading={deleteTemplateMutation.isPending}
      />
      <Menus>
        <PageContainer>
          <SimpleTitle>AI draft templates</SimpleTitle>
          <CardSection
            title="Custom templates"
            buttonProps={{
              toolTip: {
                description: 'Delete a template to create a new one',
                title: 'Maximum reached',
              },
              text: 'CREATE TEMPLATE',
              isLoading: createTemplateMutation.isPending,
              disabled: (customTemplatesQuery.data?.data.length ?? 0) >= 100,
              onClick: handleAddNewTemplate,
            }}
            isLoading={customTemplatesQuery.isLoading}
            isEmptyProps={{
              buttonText: 'CREATE TEMPLATE',
              isEmpty: customTemplatesQuery.data?.data.length === 0,
              text: 'No custom templates created yet',
              onClick: handleAddNewTemplate,
            }}
            paginationProps={{
              currentPage: search.page ?? 1,
              setCurrentPage: (currentPage) =>
                navigate({
                  search: (prev) => ({ ...prev, page: currentPage }),
                }),
              lastPage: customTemplatesQuery.data?.meta.last_page,
            }}
          >
            {customTemplatesQuery.data?.data.map((template) => (
              <ActionCard
                items={customMenuItems({
                  id: template.id,
                  description: template.description,
                  name: template.name,
                })}
                onTitleClick={() => handleEditTemplate(template)}
                title={template.name}
                description={template.description}
              />
            ))}
          </CardSection>
          <CardSection
            title="Pre-defined templates"
            isLoading={preDefinedTemplatesQuery.isLoading}
          >
            {preDefinedTemplatesQuery.data?.data.map((template) => (
              <ActionCard
                items={preDefinedMenuItems({
                  id: template.id,
                  description: template.description,
                  name: template.name,
                })}
                title={template.name ? template.name : 'Untitled'}
                description={template.description}
              />
            ))}
          </CardSection>
        </PageContainer>
      </Menus>
    </>
  );
};
