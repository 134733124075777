import {
  Gamepad,
  Link,
  MessageCircleQuestion,
  Pencil,
  Plus,
  RefreshCcw,
  SearchX,
  Table2,
} from 'lucide-react';
import * as React from 'react';
import { CurrentSlideOver } from '@/types';
import { PlateEditor } from '@udecode/plate-core';
import { serializeToMarkdown } from '../PlateEditor/lib/serialize';
import {
  ReportSectionKeyword,
  ReportSectionRecommendationsContent,
} from './components';
import SimpleButton from '@/Components/SimpleButton';
import { ScoreBar } from '@/Components/ScoreBar';
import {
  DocumentResource,
  ProjectResource,
  UserResource,
} from '@/api/openapiSchemas';
import { Dialog } from '@/Components/Dialog';
import { CompetitorTable } from './components/CompetitorTable';
import * as Table from '@/Components/Table';
import Lottie from 'react-lottie';
import lottieReportLoader from '../../../../../../public/lottie/seo.ai_lottie.json';
import { TextLoader } from './components/TextLoader';
import { useDocumentStore, useInternalLinkingStore } from '../../stores';
import { Divider } from '@/Components/Divider';
import { AddKeywordsDialog } from './components/AddKeywordsDialog';
import { KeywordLabel } from './components';
import { tracking } from '@/Services/tracking/Tracking';
import { useGetLinkSuggestions } from '../../hooks';
import { useFeatureFlagging } from '@/Pages/AppLoader';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Tooltip } from '@/Components/v2/Tooltip';
import { useRefreshKeywordReport } from '@/api/openapiComponents';
import { RefreshReportDialog } from './components/RefreshReportDialog';
import dayjs from 'dayjs';
import { ChangeKeywordDialog } from './components/ChangeKeywordDialog';
import { useQuery } from '@tanstack/react-query';
import { useAppStore } from '@/Pages/AppLoader/stores';

type Props = {
  currentProject: ProjectResource;
  auth: { user: UserResource & { org_id: number } };
  targetKeyword: string;
  document?: DocumentResource;
  isLoadingReport?: boolean;
  isGenerating?: boolean;
  editor: PlateEditor | null;
  setCurrentSlideOver: React.Dispatch<
    React.SetStateAction<CurrentSlideOver | undefined>
  >;
};

export function ReportSection({
  currentProject,
  isLoadingReport,
  targetKeyword,
  document,
  editor,
  setCurrentSlideOver,
}: Props) {
  const heading = useDocumentStore((state) => state.heading);
  const { data: isGenerating } = useQuery<boolean>({
    queryKey: ['autoGenerationStream'],
  });

  const appState = useAppStore();

  const [showChangeKeywordDialog, setShowChangeKeywordDialog] =
    React.useState(false);
  const [showRefreshReportDialog, setShowRefreshReportDialog] =
    React.useState(false);
  const [showAddKeywordDialog, setShowAddKeywordDialog] = React.useState(false);
  const [showKeywordDistribution, setShowKeywordDistribution] =
    React.useState(false);
  const [showSemanticKeywordDistribution, setShowSemanticKeywordDistribution] =
    React.useState(false);
  const [showSerpTable, setShowSerpTable] = React.useState(false);

  const getLinkSuggestions = useGetLinkSuggestions();

  const isCached = useInternalLinkingStore((state) => state.isCached);

  const { hasFeature } = useFeatureFlagging();

  const handleLinkSuggestion = () => {
    if (editor) {
      if (!isCached) {
        getLinkSuggestions({
          pathParams: {
            document: document!.id,
            project: currentProject.id,
          },
          body: {
            document: serializeToMarkdown(editor.children),
          },
        });
      }
      tracking.event('link_suggestions_opened');
      setCurrentSlideOver('internal-linking');
    }
  };

  if (isLoadingReport || !document?.document_report) {
    return (
      <div className="flex h-full w-full max-w-md flex-col justify-center gap-4">
        <div className="flex flex-grow flex-col items-center justify-center pr-6">
          <Lottie
            width={'80%'}
            height={'auto'}
            isClickToPauseDisabled
            style={{ cursor: 'default' }}
            options={{
              animationData: lottieReportLoader,
              loop: true,
              autoplay: true,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
          />
          <TextLoader
            texts={[
              'Fetching Google Results',
              'Identifying competing pages',
              'Analysing competitors content',
              'Generating semantic keywords',
              'Finding link recommendations',
              'Finalising audit report',
            ]}
            durations={[7000, 7000, 11500, 7000, 6500, 16700]}
          />
        </div>
      </div>
    );
  }

  const isReportNewerThan7Days =
    !document.document_report ||
    dayjs(document.document_report.generated_at).diff(dayjs(), 'day') > -7;

  return (
    <>
      <RefreshReportDialog
        isOpen={showRefreshReportDialog}
        onClose={() => setShowRefreshReportDialog(false)}
        documentId={document.id}
      />
      <ChangeKeywordDialog
        isOpen={showChangeKeywordDialog}
        onClose={() => setShowChangeKeywordDialog(false)}
        documentId={document.id}
        projectId={currentProject.id}
        keyword={document.keyword.name}
      />
      <AddKeywordsDialog
        isOpen={showAddKeywordDialog}
        onClose={() => setShowAddKeywordDialog(false)}
        documentId={document.id}
        initialKeywords={document.document_report.secondary_keywords}
        projectId={currentProject.id}
      />
      <Dialog
        title="Competitor overview"
        size={'xl'}
        isOpen={showKeywordDistribution}
        handleClose={() => setShowKeywordDistribution(false)}
      >
        <CompetitorTable
          keyword={document.keyword.name}
          competitors={document?.document_report?.competitors ?? []}
          keywordProperty="keywords"
          keywords={document.document_report.missing_keywords}
        />
      </Dialog>
      <Dialog
        title="Competitor overview"
        size={'xl'}
        isOpen={showSemanticKeywordDistribution}
        handleClose={() => setShowSemanticKeywordDistribution(false)}
      >
        <CompetitorTable
          keyword={document.keyword.name}
          competitors={document?.document_report?.competitors ?? []}
          keywordProperty="semantic_keywords"
          hideTarget
          keywords={document.document_report.semantic_keywords}
        />
      </Dialog>
      <div className="h-full w-full max-w-md">
        <div className="mr-6 p-1 pb-40">
          <div className="text-md mt-2 flex items-center rounded py-1 font-bold normal-case text-gray-500">
            {targetKeyword} <div className="w-4" />
            <IconButton
              size="sm"
              icon={Pencil}
              disabled={isGenerating}
              onClick={() => setShowChangeKeywordDialog(true)}
            />
            <Tooltip
              title="Report is too recent. It must be 7 days old to run it again."
              disabled={!isReportNewerThan7Days}
              side="bottom"
            >
              <IconButton
                size="sm"
                icon={RefreshCcw}
                disabled={isReportNewerThan7Days || isGenerating}
                onClick={() => setShowRefreshReportDialog(true)}
              />
            </Tooltip>
          </div>
          <ScoreBar
            text="Reach {{targetPercentage}}% to average competitors"
            percentage={document.document_report.total_score}
            targetPercentage={document.document_report.total_target_score}
          />
          <ReportSectionKeyword isFirst>
            <SimpleButton
              disabled={isGenerating}
              className="h-8 w-full rounded-md bg-white p-2  font-bold shadow-lg"
              onClick={() => {
                // tracking.event('meta_title_opened'); TODO: Uncomment this line when it works
                setCurrentSlideOver('meta-title');
              }}
            >
              <ReportSectionRecommendationsContent
                title="SEO Title"
                optimised={
                  document.document_report.meta_title_competitiveness_score
                    .is_completed
                }
              />
            </SimpleButton>
            <SimpleButton
              disabled={isGenerating}
              className="h-8 w-full rounded-md bg-white p-2  font-bold shadow-lg"
              onClick={() => {
                tracking.event('title_suggestions_opened', {
                  click_source: 'sidebar_button',
                });
                setCurrentSlideOver('title-score');
              }}
            >
              <ReportSectionRecommendationsContent
                title="H1"
                optimised={
                  document.document_report.title_competitiveness_score
                    .is_completed
                }
              />
            </SimpleButton>
            <SimpleButton
              disabled={isGenerating}
              className="h-8 w-full rounded-md bg-white p-2  font-bold shadow-lg"
              onClick={() => {
                tracking.event('subheading_suggestions_opened');
                setCurrentSlideOver('outline-builder');
              }}
            >
              <ReportSectionRecommendationsContent
                title="Subheadings"
                optimised={document.document_report.subheadings?.is_completed}
              />
            </SimpleButton>
            <SimpleButton
              disabled={isGenerating}
              className="h-8 w-full rounded-md bg-white p-2  font-bold shadow-lg"
              onClick={() => {
                tracking.event('content_length_opened');
                setCurrentSlideOver('document-length');
              }}
            >
              <ReportSectionRecommendationsContent
                title="Content Length"
                optimised={document.document_report.word_count.is_completed}
              />
            </SimpleButton>
            <SimpleButton
              disabled={isGenerating}
              className="h-8 w-full rounded-md bg-white p-2  font-bold shadow-lg"
              onClick={() => {
                tracking.event('metadescription_suggestions_opened');
                setCurrentSlideOver('meta-description');
              }}
            >
              <ReportSectionRecommendationsContent
                title="Meta description"
                optimised={
                  document.document_report.meta_description.is_completed
                }
              />
            </SimpleButton>
            <SimpleButton
              disabled={isGenerating}
              className="h-8 w-full rounded-md bg-white p-2  font-bold shadow-lg"
              onClick={handleLinkSuggestion}
            >
              <ReportSectionRecommendationsContent
                title="Internal links"
                optimised={document.document_report.internal_links.is_completed}
              />
            </SimpleButton>
          </ReportSectionKeyword>

          {/* Use keywords more */}
          <ReportSectionKeyword title="USE THESE KEYWORDS MORE" useToolTip>
            {document.document_report.missing_keywords
              .toSorted((a, b) =>
                !document.ignored_keywords?.includes(a.keyword) ? -1 : 1,
              )
              .map((keyword) => (
                <KeywordLabel
                  disabled={!!isGenerating}
                  currentProject={currentProject}
                  document={document}
                  keywordType="missing"
                  keyword={keyword}
                  ignored={document.ignored_keywords?.includes(keyword.keyword)}
                  setCurrentSlideOver={setCurrentSlideOver}
                />
              ))}
          </ReportSectionKeyword>
          <SimpleButton
            disabled={isGenerating}
            className="mt-4 w-full justify-center p-1 text-center text-sm font-normal"
            onClick={() => {
              tracking.event('competitor_keyword_overview_opened', {
                keyword_type: 'missing',
              });
              setShowKeywordDistribution(true);
            }}
          >
            Competitor overview
          </SimpleButton>

          {/* Semantic keywords */}
          <ReportSectionKeyword
            title="CONSIDER SEMANTIC RICH KEYWORDS"
            useToolTip
          >
            {document?.document_report?.semantic_keywords
              .toSorted((a, b) =>
                !document.ignored_keywords?.includes(a.keyword) ? -1 : 1,
              )
              .map((keyword) => (
                <KeywordLabel
                  disabled={!!isGenerating}
                  currentProject={currentProject}
                  document={document}
                  keywordType="semantic"
                  keyword={keyword}
                  ignored={document.ignored_keywords?.includes(keyword.keyword)}
                  setCurrentSlideOver={setCurrentSlideOver}
                />
              ))}
          </ReportSectionKeyword>

          <SimpleButton
            disabled={isGenerating}
            className="mt-4 w-full justify-center p-1 text-center text-sm font-normal"
            onClick={() => {
              tracking.event('competitor_keyword_overview_opened', {
                keyword_type: 'semantic',
              });
              setShowSemanticKeywordDistribution(true);
            }}
          >
            Competitor overview
          </SimpleButton>

          {/* Secondary keywords */}
          <>
            {document.document_report.secondary_keywords.length === 0 ? (
              <div className="mt-4">
                <Divider
                  onClick={
                    !isGenerating
                      ? () => setShowAddKeywordDialog(true)
                      : undefined
                  }
                  leftIcon={<Plus size={18} className="text-gray-600" />}
                  text="ADD KEYWORDS"
                />
              </div>
            ) : (
              <>
                <ReportSectionKeyword title="ADDED KEYWORDS" useToolTip>
                  {document.document_report.secondary_keywords.map(
                    (keyword) => (
                      <KeywordLabel
                        disabled={!!isGenerating}
                        canIgnore={false}
                        currentProject={currentProject}
                        document={document}
                        keywordType="secondary"
                        keyword={keyword}
                        setCurrentSlideOver={setCurrentSlideOver}
                      />
                    ),
                  )}

                  <SimpleButton
                    disabled={isGenerating}
                    className="mt-4 w-full justify-center p-1 text-center text-sm font-normal"
                    onClick={() => setShowAddKeywordDialog(true)}
                  >
                    Manage keywords
                  </SimpleButton>
                </ReportSectionKeyword>
              </>
            )}
          </>

          <Dialog
            isOpen={showSerpTable}
            handleClose={() => {
              setShowSerpTable(false);
            }}
            size="xl"
          >
            <div className="h-[80vh]">
              <h1 className="mb-4 text-xl font-bold">
                SERP benchmark for "{document.keyword.name ?? ''}"
              </h1>
              <Table.Root
                items={[
                  ...document.document_report.competitors,
                  {
                    rank: 'You',
                    title: heading,
                    word_count: document.document_report.word_count.actual,
                    score: document.document_report.total_score,
                    title_competitiveness_score:
                      document.document_report.title_competitiveness_score
                        .score,
                    external_link_count:
                      document.document_report.external_link_count,
                    url: currentProject.domain,
                    tableRowClassNames: 'bg-gray-100',
                  } as any,
                ].map((doc, i) => ({
                  rank: doc.rank ?? i + 1,
                  wordCount:
                    doc.word_count?.toLocaleString().replaceAll(',', '.') || 0,
                  externalLinks: doc.external_link_count,
                  titleScore: doc.title_competitiveness_score,
                  seoScore: doc.score || 0,
                  title: doc.title,
                  url: doc.url,
                  tableRowClassNames: doc.tableRowClassNames,
                }))}
                columns={[
                  {
                    property: 'rank',
                    heading: 'Rank',
                    render: (item) => (
                      <p className="text-center">{item.rank}</p>
                    ),
                  },
                  {
                    property: 'title',
                    heading: ' ',
                    render: (item) => (
                      <div className="ml-6 flex flex-col items-start overflow-ellipsis pr-6">
                        <a
                          target="_blank"
                          href={item.url}
                          className="text-indigo-500"
                          rel="noreferrer"
                        >
                          {item.title}
                        </a>
                        <div className="relative w-full">
                          &nbsp;
                          <a
                            target="_blank"
                            href={item.url}
                            className="absolute inset-0 max-w-xs overflow-hidden text-ellipsis whitespace-nowrap text-zinc-500"
                            rel="noreferrer"
                          >
                            {item.url}
                          </a>
                        </div>
                      </div>
                    ),
                  },
                  {
                    property: 'wordCount',
                    heading: 'Word count',
                  },
                  {
                    property: 'externalLinks',
                    heading: 'External links',
                  },
                  {
                    property: 'titleScore',
                    heading: 'Title score',
                  },
                  {
                    property: 'seoScore',
                    heading: 'SEO score',
                  },
                ]}
              />
            </div>
          </Dialog>
          <ReportSectionKeyword title="TOOLS">
            <SimpleButton
              disabled={isGenerating}
              className="h-8 w-full rounded-md bg-white p-2  font-bold shadow-lg"
              onClick={() => {
                tracking.event('questions_opened');
                setCurrentSlideOver('questions');
              }}
            >
              <ReportSectionRecommendationsContent
                title="Related questions"
                icon={<MessageCircleQuestion size={20} />}
              />
            </SimpleButton>
            {hasFeature('keyword-gaps') && (
              <div className="w-full">
                <Tooltip
                  asChild
                  side="left"
                  title="Upgrade to a higher plan to get access to this feature."
                  navLink="/account/subscription"
                  navLinkText="See pricing"
                  disabled={appState.subscription?.permissions.keyword_gaps}
                >
                  <SimpleButton
                    disabled={
                      isGenerating ||
                      !appState.subscription?.permissions.keyword_gaps
                    }
                    className="h-8 w-full rounded-md bg-white p-2  font-bold shadow-lg"
                    onClick={() => {
                      tracking.event('keyword_gaps_opened');
                      setCurrentSlideOver('keyword-gap');
                    }}
                  >
                    <ReportSectionRecommendationsContent
                      title="Keyword gaps"
                      icon={<SearchX size={20} />}
                    />
                  </SimpleButton>
                </Tooltip>
              </div>
            )}
            <SimpleButton
              disabled={isGenerating}
              className="h-8 w-full rounded-md bg-white p-2  font-bold shadow-lg"
              onClick={() => {
                tracking.event('competitor_overview_opened');
                setShowSerpTable(true);
              }}
            >
              <ReportSectionRecommendationsContent
                title="COMPETITOR BENCHMARK TABLE"
                icon={<Table2 size={20} />}
              />
            </SimpleButton>
            <SimpleButton
              disabled={isGenerating}
              className="h-8 w-full rounded-md bg-white p-2  font-bold shadow-lg"
              onClick={() => setCurrentSlideOver('external-links')}
            >
              <ReportSectionRecommendationsContent
                title="External links"
                icon={<Link size={20} />}
              />
            </SimpleButton>
          </ReportSectionKeyword>
        </div>
      </div>
    </>
  );
}
