import { ReactNode, createContext, useRef, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';

export const PopoverContext = createContext(() => {});

type Props = {
  trigger: ReactNode;
  children: ReactNode;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  align?: 'start' | 'center';
};

export const SimplePopover = ({
  children,
  trigger,
  onOpen,
  onClose,
  isOpen,
  align = 'center',
}: Props) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const handleOpen = () => {
    if (isOpen === true) return;
    setInternalIsOpen(true);
    if (onOpen) onOpen();
  };

  const handleClose = () => {
    if (isOpen === true || internalIsOpen === true) {
      setInternalIsOpen(false);
      if (onClose) onClose();
    }
  };

  return (
    <PopoverContext.Provider value={handleClose}>
      <Popover.Root open={isOpen ?? internalIsOpen}>
        <Popover.Trigger>
          <button
            ref={triggerRef}
            className="flex items-center text-sm"
            onClick={handleOpen}
          >
            {trigger}
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            align={align}
            className={`z-[9999] ${align === 'center' ? 'mx-4' : 'ml-0 mr-4'}`}
            onInteractOutside={(e) => {
              if (
                triggerRef.current &&
                triggerRef.current.contains(e.target as Node)
              )
                return;
              handleClose();
            }}
            onOpenAutoFocus={(e) => e.preventDefault()}
          >
            <div className="mt-2 animate-fade rounded-lg border border-gray-200 bg-white shadow-lg">
              {children}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </PopoverContext.Provider>
  );
};
