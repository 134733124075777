import { URL, getHeaders, parseSuccessBody } from '@/Components/Utils/v2/api';
import { tracking } from '@/Services/tracking/Tracking';
import { SEO_AI_AUTH } from '@/api/openapiFetcher';
import { ProjectResource } from '@/api/openapiSchemas';
import Echo from 'laravel-echo';
import {
  AppInitializeResource,
  OrganisationResource,
  SubscriptionResource,
  UserResource,
} from '@/api/openapiSchemas';

import { create } from 'zustand';

type Auth = {
  user: UserResource & {
    org_id: number;
  };
};

type State = {
  auth?: Auth;
  organisation?: OrganisationResource;
  currentProject?: ProjectResource;
  projects?: ProjectResource[];
  subscription?: SubscriptionResource;
  notifications: number;
  initialized: boolean;
  refreshSubscription: () => Promise<void>;
  refreshCurrentProject: () => Promise<void>;
  handleDeleteProject: (projectId: number) => void;
  handleCreateProject: (project: ProjectResource) => void;
  handleEditProject: (project: ProjectResource) => void;
  setCurrentProject: (project: ProjectResource) => void;
  initialize: (data: AppInitializeResource | undefined) => Promise<void>;
  pageTitle: (title: string) => void;
  logout: () => Promise<void>;
};

const initialState = {
  initialized: false,
  notifications: 0,
  organisation: undefined,
  auth: undefined,
  currentProject: undefined,
  subscription: undefined,
  projects: undefined,
};

export const useAppStore = create<State>((set, get) => ({
  ...initialState,
  setCurrentProject: (project) => {
    localStorage.setItem('current_project', `${project.id}`);
    set({ currentProject: project });
  },
  handleDeleteProject: async (projectId) => {
    const newProjects = get().projects!.filter(
      (project) => project.id !== projectId,
    );

    set({
      projects: newProjects,
      currentProject: newProjects[0],
    });

    const projectsResponse = await fetch(`${URL}/projects`, {
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${localStorage.getItem(SEO_AI_AUTH)}`,
      },
    });

    const projects = (await projectsResponse.json()).data as ProjectResource[];
    set({ projects });
  },
  handleEditProject: async (project) => {
    const newProjects = [...get().projects!];

    const index = newProjects.findIndex((project) => project.id === project.id);

    newProjects[index] = project;

    set({
      projects: newProjects,
      currentProject: project,
    });

    const projectsResponse = await fetch(`${URL}/projects`, {
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${localStorage.getItem(SEO_AI_AUTH)}`,
      },
    });

    const projects = (await projectsResponse.json()).data as ProjectResource[];
    set({ projects });
  },
  handleCreateProject: async (project) => {
    const newProjects = [...get().projects!, project];

    set({
      projects: newProjects,
      currentProject: project,
    });

    const projectsResponse = await fetch(`${URL}/projects`, {
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${localStorage.getItem(SEO_AI_AUTH)}`,
      },
    });

    const projects = (
      await parseSuccessBody<{ data: ProjectResource[] }>(projectsResponse)
    )?.data!;
    set({ projects });
  },
  refreshCurrentProject: async () => {
    const currentProjectId = get().currentProject?.id;

    if (currentProjectId) {
      const currentProjectResponse = await fetch(
        `${URL}/projects/${currentProjectId}`,
        {
          headers: {
            ...getHeaders(),
            Authorization: `Bearer ${localStorage.getItem(SEO_AI_AUTH)}`,
          },
        },
      );

      if (currentProjectResponse.ok) {
        set({ currentProject: (await currentProjectResponse.json())?.data });
      }
    }
  },
  refreshSubscription: async () => {
    const subscriptionResponse = await fetch(
      `${URL}/organisations/${get().organisation?.id}/subscription`,
      {
        headers: {
          ...getHeaders(),
          Authorization: `Bearer ${localStorage.getItem(SEO_AI_AUTH)}`,
        },
      },
    );

    const subscription = (
      await parseSuccessBody<{ data: SubscriptionResource }>(
        subscriptionResponse,
      )
    )?.data;

    if (subscription) {
      set({ subscription });
    }
  },
  initialize: async (state) => {
    if (state?.user) {
      tracking.identify(state.user.id);
    }

    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: import.meta.env.VITE_PUSHER_APP_KEY,
      cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
      forceTLS: true,
      auth: {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem(SEO_AI_AUTH)}`,
        },
      },
    });

    set({
      initialized: true,
      auth: state?.user
        ? { user: { ...state.user, org_id: state.organisation.id } }
        : undefined,
      currentProject: state?.current_project,
      organisation: state?.organisation,
      projects: state?.projects,
      subscription: state?.subscription,
    });
  },
  pageTitle: (title) => {
    document.title = `${
      get().notifications > 0 ? `(${get().notifications})` : ''
    } ${title} - SEO.ai`;
  },
  logout: async () => set({ ...initialState, initialized: true }),
}));
