import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import SimpleTitle from '@/Components/SimpleTitle';
import { AddKeywordsToListDialog, ListTab } from '../components';
import { useEffect, useState } from 'react';
import { useRankTrackingLists } from '../RankTracking/hooks';
import { useTableSelection } from '@/Components/Table/hooks';
import { RemoveKeywordsFromListDialog } from '../components/RemoveKeywordsFromListDialog';
import {
  useListScannedKeywords,
  useListScannedKeywordsGrouped,
  useProjectScannedKeywordScan,
} from '@/api/openapiComponents';
import { useAppStore } from '../AppLoader/stores';
import Toggle from '@/Components/Toggle';
import { useNavigate, useSearch } from '@tanstack/react-router';
import {
  KeywordResource,
  ScannedKeywordGroupResource,
  TrackedKeywordResource,
  TrackedKeywordUpdateResource,
  TrackedKeywordUpdateResultResource,
} from '@/api/openapiSchemas';
import { Button } from '@/Components/v2/Button';
import { AddTrackedKeywordsDialog } from '../RankTracking/components/AddTrackedKeywordsDialog';
import { GroupedKeywordsTable } from './components/GroupedKeywordTable';
import { KeywordsTable } from './components/KeywordsTable';
import { Tooltip } from '@/Components/v2/Tooltip';
import { useSnackbar } from '@/Components/v2/Snackbar';
import { UrlsSlideover } from './components/UrlsSlideover';
import { DeleteKeywordsDialog } from '../RankTracking/components/DeleteKeywordsDialog';

export const KeywordScannner = () => {
  const appState = useAppStore();

  const search = useSearch({ from: '/keyword-scanner' });
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();

  const [isGrouped, setIsGrouped] = useState(false);
  const scanKeywordsMutation = useProjectScannedKeywordScan({});

  const {
    addController,
    addToListController,
    closeDialogs,
    deleteController,
    editController,
    listsQuery,
    removeFromListController,
    selectedTabIndex,
    setSelectedTabIndex,
    setShowAddKeywordDialog,
    setTrackingToBeAddedToList,
    setTrackingToBeDeleted,
    setTrackingToBeRemovedFromList,
    showAddKeywordDialog,
    trackingToBeAddedToList,
    trackingToBeRemovedFromList,
    trackingToBeDeleted,
  } = useRankTrackingLists<
    ScannedKeywordGroupResource | TrackedKeywordResource
  >({
    onSuccess: (type) => {
      if (type === 'remove-keyword-from-list') {
        (isGrouped ? groupedKeywordsQuery : keywordsQuery).refetch();
        (isGrouped
          ? groupedKeywordSelection
          : keywordSelection
        ).resetSelection();
      }
    },
  });

  const [urlsSlideover, setUrlsSlideover] = useState({
    isOpen: false,
    keyword: undefined as KeywordResource | undefined,
    results: undefined as TrackedKeywordUpdateResultResource[] | undefined,
  });
  const [isScanning, setIsScanning] = useState(
    appState.currentProject!.is_content_scanning,
  );
  const selectedList = listsQuery.data?.data[selectedTabIndex - 1];

  const isGroupedMode = isGrouped && !!selectedList;

  const keywordsQuery = useListScannedKeywords(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        limit: 100,
        page: search.page,
        ...(selectedList ? { filters: { list_id: selectedList.id } } : {}),
      },
    },
    {
      enabled: !isGroupedMode,
    },
  );

  const groupedKeywordsQuery = useListScannedKeywordsGrouped(
    {
      pathParams: {
        project: appState.currentProject!.id,
        rankTrackingList: selectedList?.id,
      },
    },
    {
      enabled: isGroupedMode,
    },
  );

  const keywordSelection = useTableSelection();
  const groupedKeywordSelection = useTableSelection({
    selectionIdentifiedBy: 'main_keyword.id',
  });

  useEffect(() => {
    const callBack = () => {
      (isGroupedMode ? groupedKeywordsQuery : keywordsQuery)
        .refetch()
        .then(() => {
          setIsScanning(false);
          showSnackbar({
            message: `Scan complete`,
            color: 'secondary',
          });
        });
    };
    const channel = window.Echo.private(
      `projects.${appState.currentProject?.id}`,
    ).listen('.ContentScanCompleted', callBack);

    return () => {
      channel.stopListening('.ContentScanCompleted', callBack);
    };
  }, []);

  const handleStartScan = () => {
    scanKeywordsMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: () => {
          setIsScanning(true);
          appState.refreshCurrentProject();
        },
      },
    );
  };

  const isGroupedKeyword = (
    item: ScannedKeywordGroupResource | TrackedKeywordResource,
  ): item is ScannedKeywordGroupResource => {
    return 'main_keyword' in item;
  };

  appState.pageTitle('Keyword scanner');

  return (
    <Menus>
      <UrlsSlideover
        results={urlsSlideover.results}
        isOpen={urlsSlideover.isOpen}
        onClose={() => setUrlsSlideover({ ...urlsSlideover, isOpen: false })}
        keyword={urlsSlideover.keyword}
      />
      <AddTrackedKeywordsDialog
        key={'keywordadddialog' + showAddKeywordDialog}
        onSuccess={(listId) => {
          if (!listId) {
            setSelectedTabIndex(0);
          } else {
            (isGroupedMode ? groupedKeywordsQuery : keywordsQuery)
              .refetch()
              .then((response) => {
                setSelectedTabIndex(
                  response.data?.data?.findIndex((list) => list.id === listId) +
                    1,
                );
              });
          }
        }}
        trackedKeywords={[]}
        isOpen={showAddKeywordDialog}
        onClose={() => {
          setShowAddKeywordDialog(false);
        }}
      />

      <DeleteKeywordsDialog
        isOpen={trackingToBeDeleted.length > 0}
        keywords={trackingToBeDeleted.map((item) => ({
          id: isGroupedKeyword(item) ? item.main_keyword.id : item.id,
          name: isGroupedKeyword(item)
            ? item.main_keyword.keyword?.name ?? ''
            : item.keyword?.name ?? '',
        }))}
        onClose={() => setTrackingToBeDeleted([])}
        onSuccess={(keywords) => {
          (isGroupedMode ? groupedKeywordsQuery : keywordsQuery)
            .refetch()
            .then(() => {
              (isGroupedMode
                ? groupedKeywordSelection
                : keywordSelection
              ).resetSelection();
              setTrackingToBeDeleted([]);
              showSnackbar({
                color: 'secondary',
                message: `Deleted ${keywords.length} tracked keyword`,
              });
            });
        }}
      />
      <AddKeywordsToListDialog
        error={addController.error}
        isPending={addToListController.isLoading || addController.isLoading}
        key={trackingToBeAddedToList.length + 'add'}
        keywords={trackingToBeAddedToList.map((item) => ({
          id: isGroupedKeyword(item) ? item.main_keyword.id : item.id,
          name: isGroupedKeyword(item)
            ? item.main_keyword.keyword?.name ?? ''
            : item.keyword?.name ?? '',
        }))}
        listItems={(listsQuery.data?.data ?? []).map((value) => ({
          title: value.name,
          value: parseInt(value.id),
        }))}
        handleSubmit={addToListController.handleSubmit}
        onClose={() => {
          setTrackingToBeAddedToList([]);
        }}
        isDisabled={listsQuery.isLoading}
      />
      <RemoveKeywordsFromListDialog
        key={trackingToBeRemovedFromList.length + 'remove'}
        list={selectedList}
        keywordsToBeDeleted={trackingToBeRemovedFromList.map((item) => ({
          id: isGroupedKeyword(item) ? item.main_keyword.id : item.id,
          name: isGroupedKeyword(item)
            ? item.main_keyword.keyword?.name ?? ''
            : item.keyword?.name ?? '',
        }))}
        onClose={() => {
          setTrackingToBeRemovedFromList([]);
        }}
        isLoading={removeFromListController.isLoading}
        error={removeFromListController.error}
        onSubmit={removeFromListController.handleSubmit}
      />
      <PageContainer>
        <SimpleTitle>Keyword scanner</SimpleTitle>
        <div className={`mb-4 mt-8 flex items-center justify-end gap-2 `}>
          <div
            className={`flex items-center gap-2 ${
              !selectedList ? 'opacity-50' : ''
            }`}
          >
            <Toggle
              value={isGrouped && !!selectedList}
              onChange={setIsGrouped}
              disabled={!selectedList}
            />
            Group by similarity
          </div>
          <Tooltip title="Content is currently scanning" disabled={!isScanning}>
            <Button
              color="secondary"
              text="Start scanning"
              onClick={handleStartScan}
              isLoading={scanKeywordsMutation.isPending}
              disabled={isScanning}
            />
          </Tooltip>
          <Button
            text="Add keywords"
            color="secondary"
            onClick={() => setShowAddKeywordDialog(true)}
          />
        </div>
        <ListTab
          deleteController={deleteController}
          editController={editController}
          onCloseDialog={closeDialogs}
          data={listsQuery.data?.data}
          isLoading={listsQuery.isLoading}
          selectedTabIndex={selectedTabIndex}
          selectedList={selectedList}
          addController={addController}
          onChange={(tabIndex) => {
            navigate({ search: { page: 1 } });
            setSelectedTabIndex(tabIndex);
          }}
        />
        {isGroupedMode ? (
          <GroupedKeywordsTable
            onOpenUrlsSlideover={(keyword, results) =>
              setUrlsSlideover({ isOpen: true, keyword, results })
            }
            selectedList={selectedList}
            isLoading={groupedKeywordsQuery.isFetching}
            items={groupedKeywordsQuery.data?.data}
            selection={
              groupedKeywordSelection.selection as ScannedKeywordGroupResource[]
            }
            onSelectionChange={groupedKeywordSelection.setSelection}
            pagination={{
              page: Number(search.page),
              lastPage: keywordsQuery.data?.meta.last_page ?? 0,
              onPageChange: (page) => navigate({ search: { page } }),
            }}
            selectionActions={{
              isLoading: deleteController.isLoading,
              onAddToList: () =>
                setTrackingToBeAddedToList(groupedKeywordSelection.selection),
              onRemoveFromList: () =>
                setTrackingToBeRemovedFromList(
                  groupedKeywordSelection.selection,
                ),
              onDelete: () =>
                setTrackingToBeDeleted(groupedKeywordSelection.selection),
            }}
          />
        ) : (
          <KeywordsTable
            onOpenUrlsSlideover={(keyword, results) =>
              setUrlsSlideover({ isOpen: true, keyword, results })
            }
            selectedList={selectedList}
            isLoading={keywordsQuery.isFetching}
            items={keywordsQuery.data?.data ?? []}
            selection={keywordSelection.selection as TrackedKeywordResource[]}
            onSelectionChange={keywordSelection.setSelection}
            pagination={{
              page: Number(search.page),
              lastPage: keywordsQuery.data?.meta.last_page ?? 0,
              onPageChange: (page) => navigate({ search: { page } }),
            }}
            selectionActions={{
              isLoading: deleteController.isLoading,
              onAddToList: () =>
                setTrackingToBeAddedToList(keywordSelection.selection),
              onRemoveFromList: () =>
                setTrackingToBeRemovedFromList(keywordSelection.selection),
              onDelete: () =>
                setTrackingToBeDeleted(keywordSelection.selection),
            }}
          />
        )}
      </PageContainer>
    </Menus>
  );
};
