import { DocumentElementResource } from '@/api/openapiSchemas';

import { useEffect, useState } from 'react';
import { TemplateCollapsable } from './TemplateCollapsable';

type Props = {
  noShrink?: boolean;
  el: DocumentElementResource;
};

export const TemplateDragItem = ({ el, noShrink }: Props) => {
  const [shrink, setShrink] = useState(false);

  useEffect(() => {
    const listener = () => {
      setShrink(false);
    };

    window.addEventListener('mouseup', listener);
    setShrink(true);

    return () => {
      window.removeEventListener('mouseup', listener);
    };
  }, []);

  return (
    <div
      className="flex max-w-[calc(100vw-28rem)] justify-center rounded-md bg-white p-1.5 shadow-base-small"
      style={{
        width: shrink && !noShrink ? '80%' : '100%',
        margin: shrink && !noShrink ? '0 auto' : '',
      }}
    >
      <TemplateCollapsable el={el} />
    </div>
  );
};
