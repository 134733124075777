/* eslint-disable react-hooks/rules-of-hooks */
import { Suspense, useEffect } from 'react';
import {
  Outlet,
  RouterProvider,
  createRouter,
  createRootRouteWithContext,
  useNavigate,
  useRouterState,
} from '@tanstack/react-router';
import { routes as keywordRoutes } from '@/Pages/Keywords/routes';
import { routes as accountRoutes } from '@/Pages/Account/routes';
import { routes as contentRoutes } from '@/Pages/Content/routes';
import { routes as rootRoutes } from '@/Pages/routes';
import { routes as authRoutes } from '@/Pages/Auth/routes';
import { routes as documentRoutes } from '@/Pages/Document/routes';
import { routes as rankTrackingRoutes } from '@/Pages/RankTracking/routes';
import { routes as createContentRoutes } from '@/Pages/CreateNewContent/routes';
import { routes as contentScannerRoutes } from '@/Pages/ContentScanner/routes';
import { routes as keywordScannerRoutes } from '@/Pages/KeywordScanner/routes';
import { RouterContext } from './types';
import svg404 from '../../../../public/svg/404.svg';
import { tracking } from '@/Services/tracking/Tracking';
import { Button } from '@/Components/v2/Button';
import { UpdatePushNotification } from '@/Components/v2/PushNotification';
import { useAppStore } from '../AppLoader/stores';

const disableTracking = ['/signup/external'].some((path) =>
  window.location.pathname.startsWith(path),
);

export const rootRoute = createRootRouteWithContext<RouterContext>()({
  component: () => {
    const router = useRouterState();

    useEffect(() => {
      if (!disableTracking) {
        setTimeout(() => {
          tracking.event('page', {
            screen_size: window.screen.width + 'x' + window.screen.height,
            screen_width: window.screen.width,
            screen_height: window.screen.height,
          });
        }, 100);
      }
    }, [router.location.pathname]);

    return (
      <>
        <UpdatePushNotification />
        <Outlet />
      </>
    );
  },
});

const routeTree = rootRoute.addChildren([
  ...keywordRoutes,
  ...accountRoutes,
  ...contentRoutes,
  ...rootRoutes,
  ...authRoutes,
  ...documentRoutes,
  ...rankTrackingRoutes,
  ...createContentRoutes,
  ...contentScannerRoutes,
  ...keywordScannerRoutes,
]);

export const router = createRouter({
  routeTree,
  context: {
    isAuthenticated: false,
    hasProject: false,
    hasActiveSubscription: false,
    hasOrganisation: false,
    hasUnpaidInvoices: false,
    hasFeature: () => false,
  },
  defaultNotFoundComponent: () => {
    const navigate = useNavigate();

    return (
      <div className="flex h-screen w-screen flex-col items-center justify-center gap-4">
        <img src={svg404} />
        <p className="text-3xl">Page not found</p>
        <Button
          variant="outline"
          color="gray"
          text="Go to dashboard"
          onClick={() => navigate({ to: '/' })}
        />
      </div>
    );
  },
});

declare module '@tanstack/react-router' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Register {
    router: typeof router;
  }
}

export default function Router(props: RouterContext) {
  const projectId = useAppStore((state) => state.currentProject?.id);
  const refreshCurrentProject = useAppStore(
    (state) => state.refreshCurrentProject,
  );

  useEffect(() => {
    const callBack = () => {
      refreshCurrentProject();
    };
    const channel = window.Echo.private(`projects.${projectId}`).listen(
      '.ContentScanCompleted',
      callBack,
    );

    return () => {
      channel.stopListening('.ContentScanCompleted', callBack);
    };
  }, []);

  return (
    <Suspense fallback={<div />}>
      <RouterProvider key={projectId || 0} router={router} context={props} />
    </Suspense>
  );
}
