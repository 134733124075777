import {
  ContentListResource,
  KeywordResource,
  ScannedKeywordGroupResource,
  TrackedKeywordUpdateResultResource,
} from '@/api/openapiSchemas';
import * as Table from '@/Components/Table';
import { SelectionActions } from '@/Components/Table/components/SelectionActions';
import { useCopyTable } from '@/Components/Table/hooks';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { formatThousandSeperator } from '@/utils';
import { Trash2, Copy, ListPlus, ListMinus } from 'lucide-react';
import { SimilarityIndicator } from './SimilarityIndicator';
import { TaskDot } from '@/Pages/ContentScanner/components/TaskDot';
import { Tooltip } from '@/Components/v2/Tooltip';

type Props = {
  selection: ScannedKeywordGroupResource[];
  onSelectionChange: (
    added: ScannedKeywordGroupResource[],
    removed: ScannedKeywordGroupResource[],
  ) => void;
  isLoading: boolean;
  items: ScannedKeywordGroupResource[];
  selectedList?: ContentListResource;
  selectionActions: {
    isLoading: boolean;
    onDelete: (selection: ScannedKeywordGroupResource[]) => void;
    onAddToList: (selection: ScannedKeywordGroupResource[]) => void;
    onRemoveFromList: (selection: ScannedKeywordGroupResource[]) => void;
  };
  onOpenUrlsSlideover: (
    keyword: KeywordResource,
    results: TrackedKeywordUpdateResultResource[],
  ) => void;
};

export const GroupedKeywordsTable = ({
  isLoading,
  items,
  onSelectionChange,
  selection,
  selectedList,
  selectionActions,
  onOpenUrlsSlideover,
}: Props) => {
  const handleCopyToClipboard = useCopyTable(
    selection.map((item) => item.main_keyword.keyword?.name).join('\n'),
  );

  const data = items?.map((item) => ({
    ...item,
    total_volume:
      item.keywords.reduce(
        (prev, curr) => prev + (curr.keyword?.search_volume ?? 0),
        0,
      ) + (item.main_keyword.keyword?.search_volume ?? 0),
  }));

  data?.sort((a, b) => b.total_volume - a.total_volume);

  return (
    <Table.Root
      selection={selection}
      selectionIdentifyBy="main_keyword.id"
      onSelectionChange={onSelectionChange}
      isLoading={isLoading}
      items={data}
      subRows={{
        rows: (item) =>
          item.keywords.filter(
            (keyword) => keyword.keyword.id !== item.main_keyword.id,
          ),
        columns: [
          {
            render: (item, row) => (
              <div className="flex items-center gap-2">
                {item.keyword?.name}{' '}
                {<SimilarityIndicator mainKeyword={row} keyword={item} />}
              </div>
            ),
          },
          {
            rightAlign: true,
            render: (item) => (
              <Tooltip
                disabled={!item.latest_update?.position}
                title={item.latest_update?.meta_title ?? ''}
                link={{
                  text: item.latest_update?.path ?? '',
                  url: item.latest_update?.url ?? '',
                }}
              >
                <div>
                  {item.latest_update?.position ? (
                    item.latest_update.position
                  ) : (
                    <div className="pr-1">-</div>
                  )}
                </div>
              </Tooltip>
            ),
          },
          {
            render: () => <div />,
          },
          {
            rightAlign: true,
            render: (item) => (
              <div>
                {formatThousandSeperator(item.keyword?.search_volume ?? 0)}
              </div>
            ),
          },
          {
            render: (item) => (
              <div className="flex items-center gap-2">
                {(item.keyword?.keyword_data?.length ?? 0) > 0 ? (
                  <>
                    <a
                      className="cursor-pointer hover:opacity-60"
                      href={item.keyword?.keyword_data?.[0].project_url?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.keyword?.keyword_data?.[0].project_url?.path}
                    </a>
                    {(item.keyword?.keyword_data?.length ?? 0) > 1 && (
                      <p
                        className="cursor-pointer text-primary-400 hover:opacity-60"
                        onClick={() => onOpenUrlsSlideover(item.keyword!)}
                      >
                        (+{(item.keyword?.keyword_data?.length ?? 0) - 1} more)
                      </p>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </div>
            ),
          },
          {
            render: (item) =>
              item?.keyword?.keyword_data === undefined ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <Tooltip
                  side="top"
                  title={
                    item.keyword.keyword_data?.[0]?.project_url?.meta_title
                  }
                  disabled={(item.keyword.keyword_data?.length ?? 0) === 0}
                >
                  <TaskDot
                    completed={
                      (item.keyword.keyword_data?.[0]?.in_meta_title_count ??
                        0) > 0
                    }
                  />
                </Tooltip>
              ),
          },
          {
            render: (item) =>
              item?.keyword?.keyword_data === undefined ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <TaskDot
                  completed={
                    (item.keyword.keyword_data?.[0]?.in_title_count ?? 0) > 0
                  }
                />
              ),
          },
          {
            render: (item) =>
              item?.keyword?.keyword_data === undefined ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <TaskDot
                  completed={
                    (item.keyword.keyword_data?.[0]?.in_h2_count ?? 0) > 0
                  }
                />
              ),
          },
          {
            render: (item) =>
              item?.keyword?.keyword_data === undefined ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <TaskDot
                  completed={
                    (item.keyword.keyword_data?.[0]?.in_h3_count ?? 0) > 0
                  }
                />
              ),
          },
          {
            render: (item) =>
              item?.keyword?.keyword_data === undefined ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <TaskDot
                  completed={
                    (item.keyword.keyword_data?.[0]?.in_text_count ?? 0) > 0
                  }
                />
              ),
          },
          {
            render: (item) =>
              item?.keyword?.keyword_data === undefined ? (
                <SkeletonLoader height="2xs" />
              ) : (
                <TaskDot
                  completed={
                    (item.keyword.keyword_data?.[0]
                      ?.in_meta_description_count ?? 0) > 0
                  }
                />
              ),
          },
        ],
      }}
      columns={[
        {
          heading: 'Keyword',
          render: (item, _, { hasSubrows, subrowCount, toggleSubRows }) => (
            <div className="flex items-center gap-2">
              {item.main_keyword?.keyword?.name}{' '}
              {hasSubrows ? (
                <div
                  className="cursor-pointer text-sm text-primary-600 hover:opacity-50"
                  onClick={toggleSubRows}
                >
                  (+{subrowCount})
                </div>
              ) : null}
            </div>
          ),
        },
        {
          heading: 'Position',
          rightAlign: true,
          render: (item) =>
            !item ? (
              <SkeletonLoader height="xs" />
            ) : (
              <Tooltip
                side="top"
                disabled={!item?.main_keyword?.latest_update?.position}
                title={item.main_keyword.latest_update?.meta_title ?? ''}
                link={{
                  text: item.main_keyword.latest_update?.path ?? '',
                  url: item.main_keyword.latest_update?.url ?? '',
                }}
              >
                <div>
                  {item.main_keyword.latest_update?.position ? (
                    item.main_keyword.latest_update.position
                  ) : (
                    <div className="pr-1">-</div>
                  )}
                </div>
              </Tooltip>
            ),
        },
        {
          heading: 'Total Volume',
          rightAlign: true,
          render: (item) =>
            !item.main_keyword.keyword ? (
              <SkeletonLoader height="xs" />
            ) : (
              <div>{formatThousandSeperator(item.total_volume)}</div>
            ),
        },
        {
          heading: 'Volume',
          rightAlign: true,
          render: (item) =>
            !item.main_keyword.keyword ? (
              <SkeletonLoader height="xs" />
            ) : (
              <div>
                {formatThousandSeperator(
                  item.main_keyword?.keyword?.search_volume ?? 0,
                )}
              </div>
            ),
        },
        {
          heading: 'Best match url',
          render: (item) =>
            !item.main_keyword?.keyword?.keyword_data ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <div className="flex items-center gap-2">
                {item.main_keyword?.keyword?.keyword_data.length > 0 ? (
                  <>
                    <a
                      className="cursor-pointer hover:opacity-60"
                      href={
                        item.main_keyword?.keyword?.keyword_data[0].project_url
                          ?.url
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {
                        item.main_keyword?.keyword?.keyword_data[0].project_url
                          ?.path
                      }
                    </a>
                    {item.main_keyword?.keyword?.keyword_data.length > 1 && (
                      <p
                        className="cursor-pointer text-primary-400 hover:opacity-60"
                        onClick={() =>
                          onOpenUrlsSlideover(
                            item.main_keyword.keyword!,
                            item.main_keyword.latest_update?.results ?? [],
                          )
                        }
                      >
                        (+{item.main_keyword?.keyword?.keyword_data.length - 1}{' '}
                        more)
                      </p>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </div>
            ),
        },
        {
          heading: 'T',
          render: (item) =>
            item.main_keyword?.keyword?.keyword_data === undefined ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <Tooltip
                side="top"
                title={
                  item.main_keyword.keyword.keyword_data[0]?.project_url
                    ?.meta_title
                }
                disabled={
                  (item.main_keyword.keyword.keyword_data?.length ?? 0) === 0
                }
              >
                <TaskDot
                  completed={
                    (item.main_keyword.keyword.keyword_data[0]
                      ?.in_meta_title_count ?? 0) > 0
                  }
                />
              </Tooltip>
            ),
        },
        {
          heading: 'H1',
          render: (item) =>
            item.main_keyword?.keyword?.keyword_data === undefined ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <TaskDot
                completed={
                  (item.main_keyword.keyword.keyword_data[0]?.in_title_count ??
                    0) > 0
                }
              />
            ),
        },
        {
          heading: 'H2',
          render: (item) =>
            item.main_keyword?.keyword?.keyword_data === undefined ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <TaskDot
                completed={
                  (item.main_keyword.keyword.keyword_data[0]?.in_h2_count ??
                    0) > 0
                }
              />
            ),
        },
        {
          heading: 'H3',
          render: (item) =>
            item.main_keyword?.keyword?.keyword_data === undefined ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <TaskDot
                completed={
                  (item.main_keyword.keyword.keyword_data[0]?.in_h3_count ??
                    0) > 0
                }
              />
            ),
        },
        {
          heading: 'P',
          render: (item) =>
            item.main_keyword?.keyword?.keyword_data === undefined ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <TaskDot
                completed={
                  (item.main_keyword.keyword.keyword_data[0]?.in_text_count ??
                    0) > 0
                }
              />
            ),
        },
        {
          heading: 'MD',
          render: (item) =>
            item.main_keyword?.keyword?.keyword_data === undefined ? (
              <SkeletonLoader height="2xs" />
            ) : (
              <TaskDot
                completed={
                  (item.main_keyword.keyword.keyword_data[0]
                    ?.in_meta_description_count ?? 0) > 0
                }
              />
            ),
        },
      ]}
    >
      <Table.Header>
        {selection.length > 0 && (
          <SelectionActions
            selection={selection}
            actions={[
              {
                prependIcon: Trash2,
                size: 'sm',
                dense: true,
                text: 'Delete',
                variant: 'ghost',
                onClick: () => selectionActions.onDelete(selection),
                disabled: selectionActions.isLoading,
              },
              {
                prependIcon: Copy,
                size: 'sm',
                dense: true,
                text: 'Copy',
                variant: 'ghost',
                onClick: handleCopyToClipboard,
              },
              {
                prependIcon: ListPlus,
                size: 'sm',
                dense: true,
                text: 'Add to list',
                variant: 'ghost',
                onClick: () => selectionActions.onAddToList(selection),
              },
              ...(selectedList
                ? [
                    {
                      prependIcon: ListMinus,
                      size: 'sm' as const,
                      dense: true,
                      text: 'Remove from list',
                      variant: 'ghost' as const,
                      onClick: () =>
                        selectionActions.onRemoveFromList(selection),
                    },
                  ]
                : []),
            ]}
          />
        )}
      </Table.Header>
    </Table.Root>
  );
};
