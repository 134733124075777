import { Card } from '@/Components/v2/Card';
import { DocumentElementResource } from '@/api/openapiSchemas';
import { useDroppable } from '@dnd-kit/core';
import { Move } from 'lucide-react';

type Props = { el: DocumentElementResource | null; isEmpty?: boolean };
export const TemplateDrop = ({ el, isEmpty }: Props) => {
  const { isOver, setNodeRef } = useDroppable({
    id: 'add-new-droppable',
    data: { el },
  });

  const color = isOver ? 'secondary' : 'white';

  const variant = isOver ? 'filled-outline' : 'outline';

  return (
    <div ref={setNodeRef}>
      <Card border="dashed" variant={variant} color={color}>
        <div className="flex flex-col rounded-xl border border-dashed border-gray-300">
          <div
            className={`flex h-full flex-col overflow-hidden ${
              isEmpty ? 'p-6' : 'p-0'
            }`}
          >
            <div
              className={`flex items-center justify-center gap-2 opacity-75 ${
                isEmpty ? 'py-4' : 'py-3'
              }`}
            >
              <Move className="stroke-primary" />
              <p className="font-extrabold text-primary">
                {isEmpty ? 'DRAG BODY ELEMENTS HERE' : 'ADD MORE ELEMENTS'}
              </p>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
