import React, { useEffect, useState } from 'react';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import {
  ProductResource,
  SubscriptionProductResource,
  SubscriptionResource,
} from '@/api/openapiSchemas';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useNavigate } from '@tanstack/react-router';
import { AlertCircle } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import {
  useOrganisationSubscriptionEndTrial,
  useOrganisationSubscriptionUpdate,
  useProductsIndex,
  useTaxCalculation,
} from '@/api/openapiComponents';
import { addonFilter } from '../util';

type Props = {
  productToBeChangedTo?: ProductResource;
  subscription?: SubscriptionResource;
  plan?: SubscriptionProductResource;
  intervalFilter: string;
  addonsEnabled?: boolean;
  setProductToBeChangedTo: (product: ProductResource | undefined) => void;
};

export const ChangeSubscriptionDialog = ({
  plan,
  productToBeChangedTo,
  subscription,
  intervalFilter,
  setProductToBeChangedTo,
  addonsEnabled,
}: Props) => {
  const [actualPrice, setActualPrice] = useState<{
    total: string;
    tax: string;
    sub_total: string;
    tax_percentage: number;
    lines: {
      id: number;
      name: string;
      price: number;
      quantity: number;
      sub_total: number;
    }[];
  }>();
  const [chosenAddons, setChosenAddons] = useState<Record<string, number>>({});
  const client = useQueryClient();
  const appState = useAppStore();
  const navigate = useNavigate();

  const action = (() => {
    if (subscription?.is_trialing) {
      return 'start paid plan';
    }
    if (productToBeChangedTo?.id === plan?.id) {
      return 'update credits';
    }
    return productToBeChangedTo?.price! > plan?.price!
      ? 'upgrade to'
      : 'downgrade to';
  })();
  const addonsQuery = useProductsIndex({
    queryParams: {
      filters: {
        type: 'addon',
      },
      sort_by: 'position',
      sort_direction: 'ASC',
    },
  });
  const endTrialMutation = useOrganisationSubscriptionEndTrial();
  const updateSubscriptionMutation = useOrganisationSubscriptionUpdate();

  const taxCalculationMutation = useTaxCalculation();

  useEffect(() => {
    setActualPrice(undefined);
    if (productToBeChangedTo) {
      taxCalculationMutation.mutate(
        {
          body: {
            organisation_id: appState.auth!.user.org_id,
            products: [
              {
                product_id: productToBeChangedTo.id,
                quantity: 1,
              },
              ...Object.keys(chosenAddons).map((addonId) => ({
                product_id: Number(addonId),
                quantity: chosenAddons[addonId],
              })),
            ],
          },
        },
        {
          onSuccess: (data) => setActualPrice(data.data as any),
        },
      );
    }
  }, [productToBeChangedTo, chosenAddons]);

  const addons = addonsQuery.data?.data.filter((addon) =>
    addonFilter(
      productToBeChangedTo,
      addon,
      intervalFilter as 'month' | 'year',
    ),
  );

  const getintervals = (product: ProductResource) => {
    if (product.name === 'Additional AI articles') {
      return autoGenerateCredits;
    }
    if (product.name === 'Additional websites') {
      return projectIntervals;
    }
    if (product.name === 'Additional content audits') {
      return contentCreditsIntervals;
    }
    if (product.name === 'Additional tracked keywords') {
      return trackedKeywordCredits;
    }
    if (product.name === 'Additional users') {
      return userCredits;
    }
    if (product.name === 'Additional organisation urls') {
      return urlCredits;
    }
    return [];
  };

  const isMonthlyAddition = (product: ProductResource) => {
    return ['Additional AI articles', 'Additional content audits'].includes(
      product.name,
    );
  };

  const projectIntervals = Array.from({ length: 100 }, (_, i) => i + 1);

  const userCredits = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const contentCreditsIntervals = [
    10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600,
    650, 700, 750, 800, 850, 900, 950, 1000,
  ];

  const autoGenerateCredits = [
    10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600,
    650, 700, 750, 800, 850, 900, 950, 1000,
  ];

  const trackedKeywordCredits = [500, 1000, 1500, 2000, 2500, 3000];

  const urlCredits = Array.from({ length: 100 }, (_, i) => i + 1);

  const isLoading =
    updateSubscriptionMutation.isPending ||
    endTrialMutation.isPending ||
    (updateSubscriptionMutation.isSuccess && !endTrialMutation.isError) ||
    (endTrialMutation.isSuccess && !updateSubscriptionMutation.isError);

  useEffect(() => {
    if (plan && plan?.id === productToBeChangedTo?.id) {
      const addons = {};

      for (const product of subscription!.products) {
        if (product.type === 'addon') {
          addons[product.id] = product.quantity;
        }
      }

      setChosenAddons(addons);
    } else {
      setChosenAddons({});
    }
  }, [productToBeChangedTo]);

  const handleFinishUpgrade = () => {
    client.invalidateQueries({
      predicate: (query) =>
        query.queryKey.includes('organisationSubscriptionIndex'),
    });
    updateSubscriptionMutation.reset();
    appState.refreshSubscription();
    setProductToBeChangedTo(undefined);
    navigate({ to: '/account' });
  };

  const handleChangeSubscription = async () => {
    const addons = addonsEnabled ? chosenAddons : {};

    if (subscription?.is_trialing) {
      await endTrialMutation.mutateAsync({
        pathParams: {
          organisation: appState.auth!.user.org_id,
        },
      });

      if (
        Object.keys(addons).length === 0 &&
        productToBeChangedTo?.name === 'basic'
      ) {
        handleFinishUpgrade();
        return;
      }
    }

    updateSubscriptionMutation.mutate(
      {
        pathParams: {
          organisation: appState.auth!.user.org_id,
        },
        body: {
          products: [
            {
              quantity: 1,
              product_id: productToBeChangedTo?.id!,
            },
            ...Object.keys(addons).map((addonId) => ({
              quantity: addons[addonId],
              product_id: Number(addonId),
            })),
          ],
        },
      },
      {
        onSuccess: handleFinishUpgrade,
      },
    );
  };

  const handleChangeChosenAddons = (
    e: React.ChangeEvent<HTMLSelectElement>,
    addon: ProductResource,
  ) => {
    if (
      Object.keys(chosenAddons).includes(addon.id.toString()) &&
      e.target.value === '0'
    ) {
      const newAddons = { ...chosenAddons };

      delete newAddons[addon.id];
      setChosenAddons(newAddons);
    } else {
      setChosenAddons({
        ...chosenAddons,
        [addon.id]: e.target.value === '0' ? undefined : Number(e.target.value),
      });
    }
  };

  // Check if the subscription to be downgraded to allows fewer projects than the user currently have
  const addonWebsiteCount =
    actualPrice?.lines.find((line) => line.name === 'Additional websites')
      ?.quantity ?? 0;
  const productWebsiteCount = Number(
    productToBeChangedTo?.settings?.find(
      (setting) => setting.setting?.name === 'organisation_project_limit',
    )?.default_value,
  );

  const mustDeleteProjects =
    (appState.projects?.length ?? 0) > addonWebsiteCount + productWebsiteCount;

  // Warning message: Also displays a warning if the user attempts to buy more autogenerate credits than content audits
  const warning = (() => {
    if (mustDeleteProjects) {
      return {
        critical: true,
        text: 'You will need to delete some websites to change to this plan.',
      };
    }
    const contentCreditAddons =
      actualPrice?.lines.find(
        (line) => line.name === 'Additional content audits',
      )?.quantity ?? 0;
    const contentCreditsPlan =
      productToBeChangedTo?.settings?.find(
        (setting) => setting.setting?.name === 'seo_audit_limit',
      )?.default_value ?? 0;
    const contentAudits = contentCreditAddons + contentCreditsPlan;

    const autoGenerateAddons =
      actualPrice?.lines.find((line) => line.name === 'Additional AI articles')
        ?.quantity ?? 0;
    const autoGeneratePlan =
      productToBeChangedTo?.settings?.find(
        (setting) => setting.setting?.name === 'generated_article_limit',
      )?.default_value ?? 0;
    const autoGenerateCredits = autoGeneratePlan + autoGenerateAddons;

    if (autoGenerateCredits > contentAudits) {
      return {
        critical: false,
        text: 'You will need more content audits to use all AI article credits on new content',
      };
    }
  })();

  return (
    <ConfirmDialog
      isOpen={!!productToBeChangedTo}
      isLoading={isLoading}
      size={productToBeChangedTo && addonsEnabled ? 'md' : 'sm'}
      title={
        productToBeChangedTo && addonsEnabled
          ? 'Buy more credits'
          : 'Change subscription'
      }
      content={
        <>
          <div className={`mb-6 mt-4 flex justify-between gap-4`}>
            {productToBeChangedTo && addonsEnabled && (
              <div className="flex flex-col ">
                {addons?.map((addon) => (
                  <div className="mb-4 flex flex-col" key={`${addon.id}addon`}>
                    <p className=" text-gray-600">{addon.name}</p>
                    <select
                      value={chosenAddons[addon.id] ?? 0}
                      onChange={(e) => handleChangeChosenAddons(e, addon)}
                      className={`rounded border-gray-400 ring-0 focus:border-green-400 ${
                        (chosenAddons[addon.id] ?? 0) > 0
                          ? 'text-gray-800'
                          : 'text-gray-400'
                      }`}
                    >
                      <option value={0}>No credits selected</option>
                      {getintervals(addon).map((quantity) => (
                        <option
                          key={quantity}
                          value={quantity}
                        >{`${(addon.name === 'Additional organisation urls'
                          ? quantity * 1000
                          : quantity
                        ).toLocaleString()} ${
                          isMonthlyAddition(addon) ? 'monthly ' : ''
                        }($${(
                          quantity * (addon?.price ?? 0)
                        ).toLocaleString()}/${addon.interval})`}</option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
            )}
            <div className="flex w-full flex-grow flex-col items-end overflow-hidden rounded-lg bg-gray-100 p-4">
              {actualPrice ? (
                <>
                  {actualPrice.lines.map((line) => (
                    <p
                      className="mt-1 flex w-full justify-between"
                      key={`${line.id}line`}
                    >
                      {line.type === 'plan' && 'Plan: '}
                      {line.name}{' '}
                      <div className="flex">
                        ${line.sub_total.toLocaleString()} /{' '}
                        {productToBeChangedTo?.interval}
                      </div>
                    </p>
                  ))}
                  <div className="mt-4 flex flex-grow flex-col items-end gap-2 border-t border-gray-300">
                    <p className="">
                      Subtotal: ${actualPrice.sub_total.toLocaleString()} /{' '}
                      {productToBeChangedTo?.interval}
                    </p>
                    <p className="">
                      Tax ({actualPrice.tax_percentage}%): $
                      {actualPrice.tax.toLocaleString()} /{' '}
                      {productToBeChangedTo?.interval}
                    </p>
                  </div>
                  <p className="border-t border-gray-300 font-bold">
                    Total: ${actualPrice.total.toLocaleString()} /{' '}
                    {productToBeChangedTo?.interval}
                  </p>
                </>
              ) : (
                <>
                  {Object.keys(chosenAddons).map((addonId) => (
                    <div className="m-1 mb-1 h-4 w-full rounded bg-gray-300" />
                  ))}
                  <div className="m-1 mb-1 h-4 w-full rounded bg-gray-300" />
                  <div className="mt-4 flex flex-grow flex-col items-end gap-2 border-t border-gray-300 py-1">
                    <div className="h-4 w-28 rounded bg-gray-300" />
                    <div className="h-4 w-20 rounded bg-gray-300" />
                  </div>
                  <div className="h-1 w-16 border-t border-gray-300" />
                  <div className="h-4 w-16 rounded bg-gray-300" />
                </>
              )}
              <div className={`mt-4 text-sm italic text-gray-500 `}>
                New price for next period. You'll be prorated the amount
                immediately for the current subscription period.
              </div>
            </div>
          </div>
          {warning && (
            <div
              className={`flex items-end items-center gap-2 rounded-md  p-2  ${
                warning.critical
                  ? 'bg-red-50 text-red-500'
                  : 'bg-amber-50 text-amber-500'
              }`}
            >
              <AlertCircle /> {warning.text}
            </div>
          )}
        </>
      }
      confirmText={action}
      disabled={mustDeleteProjects}
      onClose={() => setProductToBeChangedTo(undefined)}
      onConfirm={handleChangeSubscription}
      error={updateSubscriptionMutation.error ?? endTrialMutation.error}
    />
  );
};
