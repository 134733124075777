import { createRoute } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  hasActiveSubscription,
  hasFeature,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import { ContentScanner } from './ContentScanner';
import { validateTanStackSearch } from '@/utils';
import { ListQueryParams, ListUrlsQueryParams } from '@/api/openapiComponents';

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    validateSearch: (
      search: Record<string, keyof ListUrlsQueryParams['filters']>,
    ) =>
      validateTanStackSearch<unknown, ListUrlsQueryParams['filters']>(search, {
        keyword_clicks_max: search.keyword_clicks_max,
        keyword_clicks_min: search.keyword_clicks_min,
        keyword_impressions_max: search.keyword_impressions_max,
        keyword_impressions_min: search.keyword_impressions_min,
        opportunity_score_max: search.opportunity_score_max,
        opportunity_score_min: search.opportunity_score_min,
        total_clicks_max: search.total_clicks_max,
        total_clicks_min: search.total_clicks_min,
        keyword_position_max: search.keyword_position_max,
        keyword_position_min: search.keyword_position_min,
        search: search.search,
      }),
    beforeLoad: ({ context }) => {
      guards(context, [
        isAuthenticated,
        hasProject,
        hasActiveSubscription,
        (context) => hasFeature(context, undefined, 'content-scanner'),
      ]);
    },
    path: '/content-scanner',
    component: () => <ContentScanner />,
  }),
];
