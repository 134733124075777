import { useState } from 'react';
import { ProductItem } from './components/SubscriptionItem';
import { UsageItem } from './components/UsageItem';
import dayjs from 'dayjs';
import { ChangePasswordDialog } from './components/ChangePasswordDialog';
import { UserAction } from './components/UserAction';
import { InviteUserDialog } from './components/InviteUserDialog';
import ToggleButton from '@/Components/ToggleButton';
import ValidationErrors from '@/Components/ValidationErrors';
import {
  ProductResource,
  SubscriptionProductResource,
} from '@/api/openapiSchemas';
import { Menus } from '@/Components/Menus';
import { useAppStore } from '../AppLoader/stores';
import { useNavigate } from '@tanstack/react-router';
import LogoutLink from '@/Pages/Auth/Finalize/LogoutLink';
import { ChangeSubscriptionDialog } from './components/ChangeSubscriptionDialog';
import { Check } from 'lucide-react';
import { useOpenStripePage } from './hooks';
import { Button } from '@/Components/v2/Button';
import { useFeatureFlagging } from '../AppLoader';
import { Input } from '@/Components/v2/Input/Input';
import { Card } from '@/Components/v2/Card';
import SimpleTitle from '@/Components/SimpleTitle';
import PageContainer from '@/Components/PageContainer';
import {
  useOrganisationSubscriptionCancelDowngrade,
  useOrganisationSubscriptionIndex,
  useOrganisationSubscriptionResume,
  useOrganisationsOrganisationInvitesIndex,
  useOrganisationsUpdate,
  useOrganisationsUsersIndex,
  useProductsIndex,
  useProfileUpdate,
} from '@/api/openapiComponents';
import { CardBody } from '@/Components/v2/Card/components/CardBody';
import { CardHeader } from '@/Components/v2/Card/components/CardTitle';
import { FeatureItem } from './components/FeatureItem';
import { isAddonsEnabled } from './util';

export const Account = () => {
  const appState = useAppStore();
  const navigate = useNavigate();

  const { hasFeature } = useFeatureFlagging();

  const [name, setName] = useState(appState.auth!.user.name);
  const [email, setEmail] = useState(appState.auth!.user.email);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [showInviteUserDialog, setShowInviteUserDialog] = useState(false);
  const [organisationName, setOrganisationName] = useState(
    appState.organisation?.name ?? '',
  );
  const [changeAddonsPlan, setChangeAddonsPlan] =
    useState<SubscriptionProductResource>();
  const [organisationEmail, setOrganisationEmail] = useState(
    appState.organisation?.billing_email ?? '',
  );
  const [tableTab, setTableTab] = useState<'users' | 'invites'>('users');

  // **** Subscription hooks ****

  const productsQuery = useProductsIndex({
    queryParams: {
      limit: 100,
      sort_by: 'position',
      sort_direction: 'ASC',
    },
  });

  const subscriptionQuery = useOrganisationSubscriptionIndex({
    pathParams: {
      organisation: appState.auth!.user.org_id,
    },
  });

  const resumeSubscriptionMutation = useOrganisationSubscriptionResume({
    onSuccess: () => subscriptionQuery.refetch(),
  });

  const cancelDowngradeMutation = useOrganisationSubscriptionCancelDowngrade({
    onSuccess: () => subscriptionQuery.refetch(),
  });

  const products = productsQuery.data?.data;
  const subscription = subscriptionQuery.data?.data;

  // **** Profile hooks ****

  const updateProfileMutation = useProfileUpdate();
  const { openStripePage: handleViewInvoices, isLoading: isLoadingInvoices } =
    useOpenStripePage(appState.auth!.user.org_id, 'A');
  const { openStripePage: handleAddTaxId, isLoading: isLoadingAddTaxId } =
    useOpenStripePage(appState.auth!.user.org_id, 'B');

  // **** Organisation hooks ****

  const organisationUpdateMutation = useOrganisationsUpdate();

  const invitesQuery = useOrganisationsOrganisationInvitesIndex({
    pathParams: {
      organisation: appState.auth!.user.org_id,
    },
    queryParams: {
      limit: 100,
    },
  });
  const invites = invitesQuery.data?.data;

  const organisationUsersQuery = useOrganisationsUsersIndex({
    pathParams: {
      organisation: appState.auth!.user.org_id,
    },
    queryParams: {
      limit: 100,
    },
  });
  const users = organisationUsersQuery.data?.data;

  // **** Event handlers ****

  const handleCurrentSubscription = () => {
    if (subscription?.is_canceled) {
      resumeSubscriptionMutation.mutate({
        pathParams: {
          organisation: appState.auth!.user.org_id,
        },
      });
      return;
    }
    if (subscription?.has_scheduled_update) {
      cancelDowngradeMutation.mutate({
        pathParams: {
          organisation: appState.auth!.user.org_id,
        },
      });
      return;
    }
    navigate({ to: '/account/subscription' });
  };

  const handleUserAction = (type: 'user' | 'invite') => {
    if (type === 'user') {
      organisationUsersQuery.refetch();
    }
    if (type === 'invite') {
      invitesQuery.refetch();
    }
  };

  const handleInvite = () => {
    setShowInviteUserDialog(false);
    invitesQuery.refetch();
  };

  const handleSaveProfile = () => {
    updateProfileMutation.mutate({
      body: {
        email,
        name,
      },
    });
  };

  const handleSaveOrganisationDetails = () => {
    organisationUpdateMutation.mutate({
      pathParams: {
        organisation: appState.auth!.user.org_id,
      },

      body: {
        name: organisationName,
        billing_email: organisationEmail,
      },
    });
  };

  const creditResetText = `Resets in ${dayjs(
    subscription?.usage.period_end,
  ).diff(dayjs(), 'days')} days`;

  const plan = subscription?.products.find(
    (product) => product.type === 'plan',
  );

  const getmanageSubscriptionButtonText = () => {
    if (subscription?.is_canceled) {
      return 'Resume';
    }
    if (subscription?.has_scheduled_update) {
      return 'Cancel downgrade';
    }
    return 'Change';
  };

  appState.pageTitle('Account');

  return (
    <Menus hideBanners>
      <ChangeSubscriptionDialog
        addonsEnabled={isAddonsEnabled(
          changeAddonsPlan as unknown as ProductResource,
        )}
        productToBeChangedTo={changeAddonsPlan as unknown as ProductResource}
        intervalFilter={plan?.interval ?? 'month'}
        plan={plan}
        subscription={subscription}
        setProductToBeChangedTo={() => setChangeAddonsPlan(undefined)}
      />
      <PageContainer>
        <div className="flex flex-col gap-4">
          <header className="flex items-center justify-between">
            <SimpleTitle>Account</SimpleTitle>

            <LogoutLink />
          </header>

          {/* Subscription section */}
          {subscriptionQuery.isSuccess && (
            <>
              {/* User has a subscription */}
              {subscription && (
                <Card>
                  <CardBody>
                    <div className={'flex items-center'}>
                      <div className="flex h-full flex-grow items-center justify-center">
                        <div className=" mr-6 flex w-full flex-col">
                          {subscription.usage.credits.content.limit < 1000 && (
                            <UsageItem
                              description={creditResetText}
                              usage={subscription.usage.credits.content}
                              name="Content audits"
                            />
                          )}
                          <UsageItem
                            description={creditResetText}
                            usage={subscription.usage.credits.generated_article}
                            name="AI articles"
                          />
                          <UsageItem
                            usage={subscription.usage.credits.projects}
                            name="Websites"
                          />
                          <UsageItem
                            usage={subscription.usage.credits.urls}
                            name="Internal linking URLs"
                          />
                          <UsageItem
                            usage={subscription.usage.credits.users}
                            name="Users"
                          />
                          <UsageItem
                            usage={subscription.usage.credits.tracked_keywords}
                            name="Tracked keywords"
                          />
                        </div>
                      </div>

                      <div>
                        <div className={'mb-1 font-bold'}>Subscription</div>
                        <div className={'mb-4 text-sm italic'}>
                          {subscription.is_active &&
                            !subscription.has_scheduled_update &&
                            !subscription.is_canceled &&
                            'Renews'}
                          {subscription.is_canceled && 'Cancelling'}
                          {!subscription.is_canceled &&
                            subscription.has_scheduled_update &&
                            'Downgrades'}
                          &nbsp;
                          {dayjs(subscription.current_period_ending_at).format(
                            'DD MMM YYYY',
                          )}
                        </div>

                        {/* Current subscription */}
                        <ProductItem
                          isTrial={subscription.is_trialing}
                          product={subscription.products.find(
                            (product) => product.type === 'plan',
                          )}
                          price={subscription.products.reduce(
                            (prev, curr) => prev + curr.price * curr.quantity,
                            0,
                          )}
                          buttonPosition="bottom"
                          features={[
                            <FeatureItem
                              feature={`${subscription.usage.credits.generated_article.limit} AI articles per month`}
                            />,
                            <FeatureItem
                              feature={`${subscription.usage.credits.content.limit} content audits per month`}
                              isUnlimitedAfter={999}
                            />,
                            <FeatureItem feature="Unlimited AI Chat & AI-writing" />,
                            <FeatureItem
                              feature={`${subscription.usage.credits.tracked_keywords.limit} tracked keyword positions`}
                            />,
                            <FeatureItem
                              feature={`${subscription.usage.credits.urls.limit} internal linking URLs`}
                            />,
                            <FeatureItem
                              feature={`${subscription.usage.credits.projects.limit} websites / ${subscription.usage.credits.users.limit} users`}
                            />,
                            <>&nbsp;</>,
                            <FeatureItem feature="Brand voice training" />,
                            <FeatureItem feature="AI keyword research tools" />,
                            ...(hasFeature('keyword-gaps')
                              ? [
                                  <FeatureItem
                                    feature="Keyword gaps (Search Console)"
                                    disabled={
                                      !subscription.permissions.keyword_gaps
                                    }
                                  />,
                                ]
                              : []),
                            ...(hasFeature('bulk-auto-generate')
                              ? [
                                  <FeatureItem
                                    feature="Bulk generate"
                                    disabled={
                                      !subscription.permissions
                                        .bulk_document_generate
                                    }
                                  />,
                                ]
                              : []),
                            ...(hasFeature('create-custom-templates')
                              ? [
                                  <FeatureItem
                                    feature="Custom templates"
                                    disabled={
                                      !subscription.permissions
                                        .custom_document_templates
                                    }
                                  />,
                                ]
                              : []),
                            ...(hasFeature('content-scanner')
                              ? [
                                  <FeatureItem
                                    feature="Content scanner"
                                    disabled={
                                      !subscription.permissions.content_scanner
                                    }
                                  />,
                                ]
                              : []),
                          ]}
                          button={
                            <div className="flex flex-col gap-2">
                              <Button
                                onClick={handleCurrentSubscription}
                                text={getmanageSubscriptionButtonText()}
                                color="secondary"
                                variant={
                                  subscription.is_canceled ||
                                  subscription.has_scheduled_update
                                    ? 'fill'
                                    : 'outline'
                                }
                                isLoading={
                                  resumeSubscriptionMutation.isPending ||
                                  cancelDowngradeMutation.isPending
                                }
                              />
                              {subscription.permissions.subscription_addons &&
                                !subscription.is_canceled &&
                                !subscription.has_scheduled_update && (
                                  <Button
                                    onClick={() => setChangeAddonsPlan(plan)}
                                    variant="outline"
                                    color="secondary"
                                    text="Buy more credits"
                                  />
                                )}
                            </div>
                          }
                        />
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <Button
                        size="sm"
                        isLoading={isLoadingInvoices}
                        onClick={handleViewInvoices}
                        text="View invoices"
                        color="secondary"
                        variant="outline"
                      />
                    </div>
                  </CardBody>
                </Card>
              )}
            </>
          )}

          {/* Profile section */}
          <Card>
            <CardHeader title="Profile" />
            <CardBody>
              <div className="flex w-full flex-col">
                <div className="mb-8 flex w-full items-end gap-4">
                  <div className="flex w-full flex-col">
                    <h2 className="mb-2 ml-3 mt-4 text-sm">Email</h2>
                    <Input value={email} onChange={setEmail} />
                  </div>
                  <div className="flex w-full flex-col">
                    <h2 className="mb-2 ml-3 mt-4 text-sm">Name</h2>
                    <Input value={name} onChange={setName} />
                  </div>
                </div>
                <ChangePasswordDialog
                  isOpen={showPasswordDialog}
                  onClose={() => setShowPasswordDialog(false)}
                />
                <div className="flex w-full">
                  <ValidationErrors
                    errors={updateProfileMutation.error?.errors}
                    apiError={updateProfileMutation.error}
                  />
                </div>
                <div className="flex justify-end gap-4">
                  <Button
                    onClick={() => setShowPasswordDialog(true)}
                    text="Change password"
                    color="gray"
                    variant="outline"
                  />

                  <Button
                    onClick={handleSaveProfile}
                    text="Save profile"
                    color="secondary"
                    variant="outline"
                    isLoading={updateProfileMutation.isPending}
                  />
                </div>
              </div>
            </CardBody>
          </Card>

          {/* Organisation section */}
          {appState.organisation && (
            <Card>
              <CardHeader title="Organisation" />
              <CardBody>
                <div className={'text-md font-bold'}>Organisation</div>
                <div className={`flex items-center gap-2 `}>
                  Tax verification status:
                  <p
                    className={`${
                      appState.organisation.tax_status === 'verified'
                        ? 'text-green-400'
                        : ''
                    }`}
                  >
                    {appState.organisation.tax_status}
                  </p>
                  {appState.organisation.tax_status === 'verified' && (
                    <Check className="-ml-1 text-green-400" size={16} />
                  )}
                  {appState.organisation.tax_status !== 'verified' && (
                    <Button
                      onClick={handleAddTaxId}
                      isLoading={isLoadingAddTaxId}
                      text="Add tax id"
                      color="gray"
                      size="sm"
                      dense
                      variant="outline"
                    />
                  )}
                </div>
                <div className="flex items-start justify-between gap-4">
                  <div className="flex w-full flex-col">
                    <h2 className="mb-2 ml-3 mt-4 text-sm">
                      Organisation name
                    </h2>
                    <Input
                      value={organisationName}
                      onChange={setOrganisationName}
                    />
                  </div>
                  <div className="flex w-full flex-col ">
                    <h2 className="mb-2 mt-4 w-full pl-3 text-sm">
                      Billing email
                    </h2>
                    <Input
                      value={organisationEmail}
                      onChange={setOrganisationEmail}
                    />
                    <div className="mt-6 flex justify-end">
                      <Button
                        onClick={handleSaveOrganisationDetails}
                        isLoading={organisationUpdateMutation.isPending}
                        text="Save organisation"
                        color="secondary"
                        variant="outline"
                      />
                    </div>
                  </div>
                </div>
                <ValidationErrors
                  errors={organisationUpdateMutation.error?.errors}
                  apiError={organisationUpdateMutation.error}
                />
                <h1 className="mt-8">Team</h1>
                <div className="mb-2 flex w-full justify-center">
                  <ToggleButton
                    className="h-8"
                    value={tableTab}
                    onChange={setTableTab}
                    options={[
                      {
                        value: 'users',
                        displayName: 'Users',
                      },
                      {
                        value: 'invites',
                        displayName: 'Invites',
                      },
                    ]}
                  />
                </div>
                <div className="rounded-lg border border-gray-300 p-4">
                  <table className="w-full">
                    <thead className="border-b border-gray-300 text-gray-600">
                      <th className="text-left">Name</th>
                      <th className="text-left">Email</th>
                      <th className="text-left"></th>
                    </thead>
                    <tbody>
                      {tableTab === 'invites' && (
                        <>
                          {invites?.map((invite, index) => (
                            <tr
                              key={'invite' + invite.id}
                              className={index % 2 === 0 ? '' : 'bg-gray-50'}
                            >
                              <td className="p-2">{invite.name}</td>
                              <td className="p-2">{invite.email}</td>
                              <td className="flex items-center justify-end pr-3 pt-3">
                                <UserAction
                                  auth={appState.auth!}
                                  invite={invite}
                                  onClose={handleUserAction}
                                />
                              </td>
                            </tr>
                          ))}
                          {invites?.length === 0 && (
                            <tr>
                              <td
                                colSpan={3}
                                className="pt-6 text-center text-gray-500"
                              >
                                No invites pending
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                      {tableTab === 'users' &&
                        users?.map((user, index) => (
                          <tr
                            key={'user' + user.id}
                            className={index % 2 === 0 ? '' : 'bg-gray-50'}
                          >
                            <td className="p-2">{user.name}</td>
                            <td className="p-2">{user.email}</td>
                            <td className="flex items-center justify-end pr-3 pt-3">
                              <UserAction
                                isAdmin={users.some(
                                  (u) => u.id === appState.auth!.user.id,
                                )}
                                auth={appState.auth!}
                                user={user}
                                onClose={handleUserAction}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="flex justify-end">
                    <InviteUserDialog
                      organisationId={appState.auth!.user.org_id}
                      isOpen={showInviteUserDialog}
                      onClose={handleInvite}
                    />
                    {tableTab === 'invites' && (
                      <Button
                        onClick={() => setShowInviteUserDialog(true)}
                        color="secondary"
                        text="Invite user"
                        variant="outline"
                      />
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          )}
        </div>
      </PageContainer>
    </Menus>
  );
};
