import { rootRoute } from '@/Pages/Router/Router';
import { createRoute } from '@tanstack/react-router';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import { ChooseCreationMethod } from './ChooseCreationMethod';
import { CreateBlankDocument } from './pages/CreateBlankDocument/CreateBlankDocument';
import { ImportFromUrl } from './pages/ImportFromUrl/ImportFromUrl';
import { AiGenerated } from './pages/AiGenerated/AiGenerated';
import { TitleEditor } from './pages/TitleEditor/TitleEditor';
import { OutlineEditor } from './pages/OutlineEditor/OutlineEditor';

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasActiveSubscription, hasProject]);
    },
    path: '/create-content',
    component: () => <ChooseCreationMethod />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasActiveSubscription, hasProject]);
    },
    path: '/create-content/blank',
    validateSearch: (search: Record<string, string>) => ({
      keyword: search.keyword as string | undefined,
    }),
    component: () => <CreateBlankDocument />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasActiveSubscription, hasProject]);
    },
    validateSearch: (search: Record<string, string>) => ({
      url: search.url as string | undefined,
    }),
    path: '/create-content/import',
    component: () => <ImportFromUrl />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasActiveSubscription, hasProject]);
    },
    validateSearch: (
      search: Record<string, string>,
    ): {
      review_subheadings: boolean;
      live_generate: boolean;
      is_new?: boolean;
    } => ({
      review_subheadings: Boolean(search.review_subheadings),
      live_generate: Boolean(search.live_generate),
      is_new: Boolean(search.is_new) as boolean | undefined,
    }),
    path: '/create-content/title-editor/$documentId',
    component: () => <TitleEditor />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasActiveSubscription, hasProject]);
    },
    validateSearch: (search: Record<string, string>) => ({
      review_title: Boolean(search.review_title) as boolean | undefined,
      live_generate: Boolean(search.live_generate),
      is_new: Boolean(search.is_new) as boolean | undefined,
    }),
    path: '/create-content/outline-editor/$documentId',
    component: () => <OutlineEditor />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasActiveSubscription, hasProject]);
    },
    validateSearch: (
      search: Record<string, string>,
    ): { documentId?: number; keyword?: string; is_new?: boolean } => ({
      documentId: search.documentId ? Number(search.documentId) : undefined,
      keyword: search.keyword as string | undefined,
      is_new: Boolean(search.is_new),
    }),
    path: '/create-content/ai-generated',
    component: () => <AiGenerated />,
  }),
];
