import { Button } from '@/Components/v2/Button';
import { Autocomplete } from '@/Components/v2/Select/AutoComplete';
import { Tooltip } from '@/Components/v2/Tooltip';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useListDocuments, useTestSection } from '@/api/openapiComponents';
import { DocumentElementResource } from '@/api/openapiSchemas';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

type Props = {
  el: DocumentElementResource & {
    uniqueId: string;
  };
  onAddDocumentId: (documentId: string | undefined) => void;
  onAddPreview: (preview: string) => void;
  testPromptDialog: {
    documentId: string;
    uniqueId: string;
    preview: string;
  };
};
export const TestPromptSection = ({
  el,
  onAddDocumentId,
  onAddPreview,
  testPromptDialog,
}: Props) => {
  const project = useAppStore((state) => state.currentProject!.id);
  const [search, setSearch] = useState('');
  const documentsQuery = useListDocuments({
    pathParams: { project },
    queryParams: { filters: { search: search ? search : undefined } },
  });

  const testPromptMutation = useTestSection({
    onSuccess: (data) => {
      const key = el.element_type === 'body' ? 'text' : el.element_type;
      const preview = data.data[key];
      onAddPreview(preview);
    },
  });

  const handleTestPrompt = () => {
    testPromptMutation.mutate({
      pathParams: { project },
      body: {
        element_id: el.id,
        document_id: parseInt(testPromptDialog.documentId),
        values: {
          prompt_instruction: el.values?.prompt_instruction,
          raw_markdown: el.values?.raw_markdown,
          subheading: el.values?.subheading
            ? {
                title: el.values.subheading?.title,
                type: el.values.subheading?.type,
              }
            : undefined,
        },
      },
    });
  };
  return (
    <>
      <div className="flex items-center px-7 py-8">
        <div className="w-full">
          <Autocomplete
            placeholder="Search for content"
            searchValue={search}
            onSearchChange={setSearch}
            backendSearch
            isLoading={documentsQuery.isLoading}
            options={
              documentsQuery.data?.data.map((doc) => ({
                value: doc.id,
                title: doc.title ? doc.title : 'Untitled',
                subtitle: doc.keyword.name,
              })) ?? []
            }
            onChange={(value) => {
              onAddDocumentId(value);
            }}
            value={testPromptDialog.documentId}
          />
        </div>
        <div className="px-4">
          <Tooltip
            disabled={testPromptDialog.documentId !== ''}
            title="Please select a document to test the prompt"
          >
            <Button
              variant="outline"
              color="secondary"
              text={
                testPromptDialog.preview !== ''
                  ? 'TEST PROMPT OUTPUT AGAIN'
                  : 'TEST PROMPT OUTPUT'
              }
              onClick={handleTestPrompt}
              disabled={testPromptDialog.documentId === ''}
              isLoading={
                documentsQuery.isLoading || testPromptMutation.isPending
              }
            />
          </Tooltip>
        </div>
      </div>
      {testPromptDialog.preview !== '' && (
        <div>
          <div className="rounded-md border p-4">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              className="prose max-w-none text-base leading-tight"
            >
              {testPromptDialog.preview}
            </ReactMarkdown>
          </div>
          <div className="flex justify-end text-sm text-primary">
            {testPromptDialog.preview.split(' ').length} words
          </div>
        </div>
      )}
    </>
  );
};
