import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import SimpleTitle from '@/Components/SimpleTitle';
import * as Table from '@/Components/Table';
import {
  fetchListUrlKeywords,
  useGetGoogleSearchConsole,
  useListUrlKeywords,
  useListUrls,
  useProjectContentScannerUpdate,
  useStartScan,
} from '@/api/openapiComponents';
import { useAppStore } from '../AppLoader/stores';
import { TaskDot } from './components/TaskDot';
import { Button } from '@/Components/v2/Button';
import { formatThousandSeperator } from '@/utils';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@/Components/v2/Tooltip';
import { GSCChooseSiteDialog } from '../Settings/Connections/components/GSCChooseSiteDialog';
import { useConnectToGoogleSearchConsole } from '../Settings/hooks';
import {
  ChevronDown,
  ChevronUp,
  Globe,
  MoreVertical,
  PlugZap,
  Plus,
  Scan,
} from 'lucide-react';
import { useGoogleLogin } from '@react-oauth/google';
import { Loader } from '@/Components/Loader';
import AppLabel, { GradualScoreVariant } from '@/Components/Labels/AppLabel';
import {
  ScannedContentResource,
  ScannedContentSubResource,
} from '@/api/openapiSchemas';
import { useSnackbar } from '@/Components/v2/Snackbar';
import {
  formatRange,
  RangeSelector,
} from '@/Components/Table/components/RangeSelector';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { useQueryClient } from '@tanstack/react-query';

export const ContentScanner = () => {
  const appState = useAppStore();
  const { showSnackbar } = useSnackbar();

  const {
    googleDialog,
    hasNoAvailableSites,
    isGettingGoogleSearchConsoleData,
    isSelectingSiteForGoogleSearchConsole,
    isSettingUpGoogleSearchConsole,
    selectSiteForGoogleSearchConsole,
    setGoogleDialog,
    setupGoogleConsole,
    initialState,
  } = useConnectToGoogleSearchConsole(appState.currentProject!.id);

  const search = useSearch({ from: '/content-scanner' });
  const navigate = useNavigate();

  const [isScanning, setIsScanning] = useState(
    appState.currentProject!.is_content_scanning,
  );

  const client = useQueryClient();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setupGoogleConsole({
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          code: codeResponse.code,
        },
      });
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/webmasters.readonly',
    ux_mode: 'popup',
  });

  const listUrlKeywordsMutation = useListUrlKeywords();
  const startScanMutation = useStartScan();
  const scanSpecificUrlsMutation = useProjectContentScannerUpdate();
  const scannedContentQuery = useListUrls({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      page: search.page,
      limit: 100,
      sort_by: search.sortBy,
      sort_direction: search.sortDirection,
      filters: {
        search: search.filters?.search,
        keyword_clicks_max: search.filters?.keyword_clicks_max,
        keyword_clicks_min: search.filters?.keyword_clicks_min,
        keyword_impressions_max: search.filters?.keyword_impressions_max,
        keyword_impressions_min: search.filters?.keyword_impressions_min,
        opportunity_score_max: search.filters?.opportunity_score_max,
        opportunity_score_min: search.filters?.opportunity_score_min,
        keyword_position_max: search.filters?.keyword_position_max,
        keyword_position_min: search.filters?.keyword_position_min,
        total_clicks_max: search.filters?.total_clicks_max,
        total_clicks_min: search.filters?.total_clicks_min,
      },
    },
  });
  const googleQuery = useGetGoogleSearchConsole(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
    },
    {
      retry: false,
    },
  );

  const injectSubrowData = (id: number, data: ScannedContentSubResource[]) => {
    client.setQueriesData(
      { predicate: (query) => query.queryKey.includes('listUrls') },
      (prev: any) => {
        return {
          ...prev,
          data: prev.data.map((item: ScannedContentResource) => {
            if (item.id === id) {
              return {
                ...item,
                ...(data.find((subItem) => subItem.keyword === item.keyword) ??
                  {}),
                subRows: data,
              };
            }
            return item;
          }),
        };
      },
    );
  };

  useEffect(() => {
    const callBack = () => {
      setIsScanning(false);
      showSnackbar({
        message: `Scan complete`,
        color: 'secondary',
      });
    };
    const contentScanChannel = window.Echo.private(
      `projects.${appState.currentProject?.id}`,
    ).listen('.ContentScanCompleted', callBack);

    const singleScanChannel = window.Echo.private(
      `projects.${appState.currentProject?.id}`,
    ).listen('.ProjectUrlScanCompleted', (e: { project_url_id: number }) => {
      if (
        scannedContentQuery.data?.data.some((item) => {
          return item.id === e.project_url_id;
        })
      ) {
        loadSubrowData(e.project_url_id);
      }
    });

    return () => {
      singleScanChannel.stopListening('.ProjectUrlScanCompleted');
      contentScanChannel.stopListening('.ContentScanCompleted', callBack);
    };
  }, [scannedContentQuery.data?.data]);

  const handleStartScan = () => {
    startScanMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: () => {
          setIsScanning(true);
          appState.refreshCurrentProject();
        },
      },
    );
  };

  const handleStartSingleScan = (id: number) => {
    scanSpecificUrlsMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          ids: [id],
        },
      },
      {
        onError: () =>
          showSnackbar({
            message: `Failed to start scan`,
            color: 'red',
          }),
      },
    );
  };

  const loadSubrowData = async (id: number) => {
    const response = await fetchListUrlKeywords({
      pathParams: {
        project: appState.currentProject!.id,
        url: id,
      },
      queryParams: {
        limit: 100,
      },
    });
    injectSubrowData(id, response.data);
  };

  const getOppVariant = (score: number): keyof typeof GradualScoreVariant => {
    if (score >= 90) {
      return 'best';
    } else if (score >= 80) {
      return 'great';
    } else if (score >= 60) {
      return 'good';
    } else if (score >= 40) {
      return 'ok';
    } else {
      return 'remaining';
    }
  };

  const scannedContent = (scannedContentQuery.data?.data ??
    []) as (ScannedContentResource & {
    subRows?: ScannedContentSubResource[];
  })[];

  appState.pageTitle('Content scanner');

  return (
    <>
      <GSCChooseSiteDialog
        isLoading={isSelectingSiteForGoogleSearchConsole}
        isOpen={googleDialog.isOpen}
        hasNoAvailableSites={hasNoAvailableSites}
        onClose={() => setGoogleDialog(initialState)}
        onConnect={() => {
          selectSiteForGoogleSearchConsole({
            pathParams: {
              project: appState.currentProject!.id,
            },
            body: {
              site: googleDialog.selectedSite!,
            },
          });
          setGoogleDialog(initialState);
          scannedContentQuery.refetch();
        }}
        setGoogleDialog={setGoogleDialog}
        googleDialog={googleDialog}
      />
      <Menus>
        <PageContainer>
          <SimpleTitle>Content scanner</SimpleTitle>

          {googleQuery.data?.data.state === 'active' && (
            <div className="flex justify-end py-4">
              <Tooltip
                title="Content is currently scanning"
                disabled={!isScanning}
              >
                <Button
                  color="secondary"
                  text="Start scanning"
                  onClick={handleStartScan}
                  isLoading={startScanMutation.isPending}
                  disabled={isScanning}
                />
              </Tooltip>
            </div>
          )}

          {googleQuery.isFetching ? (
            <div className="flex flex-grow items-center justify-center">
              <Loader className="h-20 w-20 stroke-secondary" />
            </div>
          ) : (
            <>
              {googleQuery.data?.data.state === 'active' ? (
                <Table.Root<
                  ScannedContentResource & {
                    subRows?: ScannedContentSubResource[];
                  },
                  ScannedContentSubResource
                >
                  isLoading={scannedContentQuery.isLoading}
                  items={scannedContent ?? []}
                  meta={scannedContentQuery.data?.meta}
                  subRows={{
                    columns: [
                      {
                        render: (item, row: ScannedContentResource) => (
                          <div className="text-primary-400">{row.path}</div>
                        ),
                      },
                      {
                        render: () => <div />,
                      },
                      {
                        render: (item) =>
                          item.opportunity_score === undefined ? (
                            <SkeletonLoader height="2xs" />
                          ) : (
                            <p>
                              <AppLabel
                                variant={
                                  GradualScoreVariant[
                                    getOppVariant(item.opportunity_score)
                                  ]
                                }
                              >
                                <div className="flex w-6 justify-center">
                                  {item.opportunity_score}
                                </div>
                              </AppLabel>
                            </p>
                          ),
                      },
                      {
                        render: (item) =>
                          item.keyword === undefined ? (
                            <SkeletonLoader height="2xs" />
                          ) : (
                            <div>{item.keyword}</div>
                          ),
                      },
                      {
                        rightAlign: true,
                        render: (item) =>
                          item.clicks === undefined ? (
                            <SkeletonLoader height="2xs" />
                          ) : (
                            <div>{formatThousandSeperator(item.clicks)}</div>
                          ),
                      },
                      {
                        rightAlign: true,
                        render: (item) =>
                          item.impressions === undefined ? (
                            <SkeletonLoader height="2xs" />
                          ) : (
                            <div>
                              {formatThousandSeperator(item.impressions)}
                            </div>
                          ),
                      },
                      {
                        rightAlign: true,
                        render: (item) =>
                          item.position === undefined ? (
                            <SkeletonLoader height="2xs" />
                          ) : (
                            <div>{item.position.toFixed(0)}</div>
                          ),
                      },
                      {
                        render: (item, row) =>
                          row.is_scan_loading ? (
                            <SkeletonLoader height="2xs" />
                          ) : (
                            <TaskDot completed={item.in_meta_title_count > 0} />
                          ),
                      },
                      {
                        render: (item, row) =>
                          row.is_scan_loading ? (
                            <SkeletonLoader height="2xs" />
                          ) : (
                            <TaskDot completed={item.in_title_count > 0} />
                          ),
                      },
                      {
                        render: (item, row) =>
                          row.is_scan_loading ? (
                            <SkeletonLoader height="2xs" />
                          ) : (
                            <TaskDot completed={item.in_h2_count > 0} />
                          ),
                      },
                      {
                        render: (item, row) =>
                          row.is_scan_loading ? (
                            <SkeletonLoader height="2xs" />
                          ) : (
                            <TaskDot completed={item.in_h3_count > 0} />
                          ),
                      },
                      {
                        render: (item, row) =>
                          row.is_scan_loading ? (
                            <SkeletonLoader height="2xs" />
                          ) : (
                            <TaskDot completed={item.in_text_count > 0} />
                          ),
                      },
                      {
                        render: (item, row) =>
                          row.is_scan_loading ? (
                            <SkeletonLoader height="2xs" />
                          ) : (
                            <TaskDot
                              completed={item.in_meta_description_count > 0}
                            />
                          ),
                      },
                    ],
                    rowCount: (item) => item.keyword_count + 1,
                    rowLoaderHeight: 'xs',
                    rows: (item) => {
                      return item.subRows;
                    },
                  }}
                  onSortChange={({ sortBy, sortDirection }) => {
                    navigate({
                      search: (prev) => ({
                        ...prev,
                        sortBy,
                        sortDirection,
                      }),
                    });
                  }}
                  sorting={search}
                  columns={[
                    {
                      heading: 'URL',
                      render: (item) => (
                        <a
                          title={item.url ?? ''}
                          href={item.url ?? ''}
                          target="_blank"
                          className="block max-w-[350px] truncate hover:opacity-50"
                          rel="noreferrer"
                        >
                          {item.path}
                        </a>
                      ),
                    },
                    {
                      heading: 'TOTAL CLICKS',
                      sortableHeader: 'total_clicks',
                      rightAlign: true,
                      render: (item) =>
                        item.total_clicks === undefined ? (
                          <SkeletonLoader height="2xs" />
                        ) : (
                          <p>{formatThousandSeperator(item.total_clicks)}</p>
                        ),
                    },
                    {
                      heading: 'OPP.',
                      sortableHeader: 'opportunity_score',
                      render: (item) =>
                        item.opportunity_score === undefined ? (
                          <SkeletonLoader height="2xs" />
                        ) : (
                          <p>
                            <AppLabel
                              variant={
                                GradualScoreVariant[
                                  getOppVariant(item.opportunity_score)
                                ]
                              }
                            >
                              <div className="flex w-6 justify-center">
                                {item.opportunity_score}
                              </div>
                            </AppLabel>
                          </p>
                        ),
                    },
                    {
                      heading: 'TOP KEYWORD',
                      sortableHeader: 'keyword_name',
                      render: (
                        item,
                        _,
                        { hasSubrows, toggleSubRows, isSubrowsOpen },
                      ) => (
                        <div
                          className={`flex items-center gap-1 font-bold ${
                            hasSubrows ? 'cursor-pointer' : ''
                          }`}
                          onClick={() => {
                            toggleSubRows();
                            if (!isSubrowsOpen) {
                              loadSubrowData(item.id);
                            }
                          }}
                        >
                          {item.keyword}{' '}
                          {hasSubrows &&
                            (isSubrowsOpen ? (
                              <ChevronUp size={14} className="shrink-0" />
                            ) : (
                              <ChevronDown size={14} className="shrink-0" />
                            ))}
                        </div>
                      ),
                    },
                    {
                      heading: 'KW CLICKS',
                      sortableHeader: 'keyword_clicks',
                      rightAlign: true,
                      render: (item) =>
                        item.clicks === undefined ? (
                          <SkeletonLoader height="2xs" />
                        ) : (
                          <p>{formatThousandSeperator(item.clicks)}</p>
                        ),
                    },
                    {
                      heading: 'KW IMPRESSIONS',
                      sortableHeader: 'keyword_impressions',
                      rightAlign: true,
                      render: (item) =>
                        !item.impressions ? (
                          <SkeletonLoader height="2xs" />
                        ) : (
                          <p>{formatThousandSeperator(item.impressions)}</p>
                        ),
                    },
                    {
                      heading: 'GSC POSITION',
                      sortableHeader: 'keyword_position',
                      rightAlign: true,
                      render: (item) =>
                        !item.position ? (
                          <SkeletonLoader height="2xs" />
                        ) : (
                          <p>{item.position.toFixed(0) ?? '-'}</p>
                        ),
                    },
                    {
                      heading: 'T',
                      render: (item) =>
                        item.is_scan_loading ? (
                          <SkeletonLoader height="2xs" />
                        ) : (
                          <Tooltip side="top" title={item.meta_title}>
                            <TaskDot completed={item.in_meta_title_count > 0} />
                          </Tooltip>
                        ),
                    },
                    {
                      heading: 'H1',
                      render: (item) =>
                        item.is_scan_loading ? (
                          <SkeletonLoader height="2xs" />
                        ) : (
                          <Tooltip side="top" title={item.title}>
                            <TaskDot completed={item.in_title_count > 0} />
                          </Tooltip>
                        ),
                    },
                    {
                      heading: 'H2',
                      render: (item) =>
                        item.is_scan_loading ? (
                          <SkeletonLoader height="2xs" />
                        ) : (
                          <TaskDot completed={item.in_h2_count > 0} />
                        ),
                    },
                    {
                      heading: 'H3',
                      render: (item) =>
                        item.is_scan_loading ? (
                          <SkeletonLoader height="2xs" />
                        ) : (
                          <TaskDot completed={item.in_h3_count > 0} />
                        ),
                    },
                    {
                      heading: 'P',
                      render: (item) =>
                        item.is_scan_loading ? (
                          <SkeletonLoader height="2xs" />
                        ) : (
                          <TaskDot completed={item.in_text_count > 0} />
                        ),
                    },
                    {
                      heading: 'MD',
                      render: (item) =>
                        item.is_scan_loading ? (
                          <SkeletonLoader height="2xs" />
                        ) : (
                          <TaskDot
                            completed={item.in_meta_description_count > 0}
                          />
                        ),
                    },
                    {
                      render: (item) => (
                        <PopoverMenu
                          trigger={<IconButton icon={MoreVertical} />}
                          items={[
                            {
                              title: 'Rescan',
                              prependIcon: Scan,
                              onClick: () => handleStartSingleScan(item.id),
                            },
                            {
                              title: 'Create new',
                              prependIcon: Plus,
                              onClick: () =>
                                navigate({
                                  to: '/create-content/blank',
                                  search: (prev) => ({
                                    ...prev,
                                    keyword: item.keyword,
                                  }),
                                }),
                            },
                            {
                              title: 'import',
                              prependIcon: Globe,
                              onClick: () =>
                                navigate({
                                  to: '/create-content/import',
                                  search: (prev) => ({
                                    ...prev,
                                    url: item.url,
                                  }),
                                }),
                            },
                          ]}
                        />
                      ),
                    },
                  ]}
                >
                  <Table.Header
                    search={search.filters?.search}
                    onSearchChange={(value) => {
                      navigate({
                        search: {
                          ...search,
                          page: 1,
                          filters: {
                            ...search.filters,
                            search: value,
                          },
                        },
                      });
                    }}
                  >
                    <Table.ResultsTotal
                      title="URLs"
                      total={scannedContentQuery.data?.meta.total}
                    />
                    <Table.FilterPopover
                      name="Total clicks"
                      onRemoveFilter={() =>
                        navigate({
                          search: {
                            filters: {
                              ...search.filters,
                              total_clicks_min: undefined,
                              total_clicks_max: undefined,
                            },
                          },
                        })
                      }
                      filterName={formatRange({
                        min: search.filters?.total_clicks_min,
                        max: search.filters?.total_clicks_max,
                        name: 'Total clicks',
                      })}
                    >
                      <RangeSelector
                        values={{
                          min: search.filters?.total_clicks_min,
                          max: search.filters?.total_clicks_max,
                        }}
                        onAccept={(range) => {
                          navigate({
                            search: {
                              ...search,
                              filters: {
                                ...search.filters,
                                total_clicks_min: range.min,
                                total_clicks_max: range.max,
                              },
                            },
                          });
                        }}
                      />
                    </Table.FilterPopover>
                    <Table.FilterPopover
                      name="Opp."
                      onRemoveFilter={() =>
                        navigate({
                          search: {
                            filters: {
                              ...search.filters,
                              opportunity_score_min: undefined,
                              opportunity_score_max: undefined,
                            },
                          },
                        })
                      }
                      filterName={formatRange({
                        min: search.filters?.opportunity_score_min,
                        max: search.filters?.opportunity_score_max,
                        name: 'Opportunity score',
                      })}
                    >
                      <RangeSelector
                        values={{
                          min: search.filters?.opportunity_score_min,
                          max: search.filters?.opportunity_score_max,
                        }}
                        onAccept={(range) => {
                          navigate({
                            search: {
                              ...search,
                              filters: {
                                ...search.filters,
                                opportunity_score_min: range.min,
                                opportunity_score_max: range.max,
                              },
                            },
                          });
                        }}
                      />
                    </Table.FilterPopover>
                    <Table.FilterPopover
                      name="KW clicks"
                      onRemoveFilter={() =>
                        navigate({
                          search: {
                            filters: {
                              ...search.filters,
                              keyword_clicks_min: undefined,
                              keyword_clicks_max: undefined,
                            },
                          },
                        })
                      }
                      filterName={formatRange({
                        min: search.filters?.keyword_clicks_min,
                        max: search.filters?.keyword_clicks_max,
                        name: 'KW clicks',
                      })}
                    >
                      <RangeSelector
                        values={{
                          min: search.filters?.keyword_clicks_min,
                          max: search.filters?.keyword_clicks_max,
                        }}
                        onAccept={(range) => {
                          navigate({
                            search: {
                              ...search,
                              filters: {
                                ...search.filters,
                                keyword_clicks_min: range.min,
                                keyword_clicks_max: range.max,
                              },
                            },
                          });
                        }}
                      />
                    </Table.FilterPopover>
                    <Table.FilterPopover
                      name="KW impressions"
                      onRemoveFilter={() =>
                        navigate({
                          search: {
                            filters: {
                              ...search.filters,
                              keyword_impressions_min: undefined,
                              keyword_impressions_max: undefined,
                            },
                          },
                        })
                      }
                      filterName={formatRange({
                        min: search.filters?.keyword_impressions_min,
                        max: search.filters?.keyword_impressions_max,
                        name: 'KW impressions',
                      })}
                    >
                      <RangeSelector
                        values={{
                          min: search.filters?.keyword_impressions_min,
                          max: search.filters?.keyword_impressions_max,
                        }}
                        onAccept={(range) => {
                          navigate({
                            search: {
                              ...search,
                              filters: {
                                ...search.filters,
                                keyword_impressions_min: range.min,
                                keyword_impressions_max: range.max,
                              },
                            },
                          });
                        }}
                      />
                    </Table.FilterPopover>
                    <Table.FilterPopover
                      name="GSC position"
                      onRemoveFilter={() =>
                        navigate({
                          search: {
                            filters: {
                              ...search.filters,
                              keyword_position_min: undefined,
                              keyword_position_max: undefined,
                            },
                          },
                        })
                      }
                      filterName={formatRange({
                        min: search.filters?.keyword_position_min,
                        max: search.filters?.keyword_position_max,
                        name: 'GSC position',
                      })}
                    >
                      <RangeSelector
                        values={{
                          min: search.filters?.keyword_position_min,
                          max: search.filters?.keyword_position_max,
                        }}
                        onAccept={(range) => {
                          navigate({
                            search: {
                              ...search,
                              filters: {
                                ...search.filters,
                                keyword_position_min: range.min,
                                keyword_position_max: range.max,
                              },
                            },
                          });
                        }}
                      />
                    </Table.FilterPopover>
                  </Table.Header>
                  <Table.NoContent>
                    {isScanning
                      ? 'Content is currently scanning'
                      : 'No content scanned yet. Click start scanning to begin'}
                  </Table.NoContent>
                  <Table.Footer>
                    <Pagination
                      currentPage={Number(search.page)}
                      lastPage={scannedContentQuery.data?.meta.last_page}
                      setCurrentPage={(page) =>
                        navigate({ search: { ...search, page } })
                      }
                    />
                  </Table.Footer>
                </Table.Root>
              ) : (
                <div className="flex flex-grow items-center justify-center">
                  <div className="mt-12 flex flex-col items-center gap-6">
                    <PlugZap size={64} className="text-primary-200" />
                    <div className="text-center font-bold text-primary-600">
                      Connect to Google Search Console to use the content
                      scanner
                    </div>
                    <Button
                      isLoading={
                        isSettingUpGoogleSearchConsole ||
                        isGettingGoogleSearchConsoleData ||
                        googleDialog.isOpen
                      }
                      text="Connect"
                      color="secondary"
                      onClick={login}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </PageContainer>
      </Menus>
    </>
  );
};
