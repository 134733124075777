import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { useGetRandomPlaceHolder } from '@/data/placeholders';
import { FormEvent, useState } from 'react';
import { AiGeneratedFormState } from '../AiGenerated';
import {
  ContentTypeResource,
  DocumentTemplateResource,
} from '@/api/openapiSchemas';
import { SettingsToggle } from '../../../components/SettingsSwitch';
import { Button } from '@/Components/v2/Button';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useAnalyzePage } from '@/api/openapiComponents';
import { useFeatureFlagging } from '@/Pages/AppLoader';
import { useSearch } from '@tanstack/react-router';
import { ErrorAlert } from '@/Components/v2/Alert';
import { ContentTypeSelect } from '@/Components/ContentTypeSelect';
import { AdditionalContextForm } from '@/Components/AdditionalContextForm';
import { DropdownItem } from '@/Components/v2/Select/hooks';
import { Autocomplete } from '@/Components/v2/Select/AutoComplete';
import { Tooltip } from '@/Components/v2/Tooltip';
import { setTemplateToForm } from '../utils';

type Props = {
  isLoading?: boolean;
  formState: AiGeneratedFormState;
  contentTypes: ContentTypeResource[];
  briefLimit: number;
  onSubmit: () => void;
  setFormState: (state: AiGeneratedFormState) => void;
  templates: (
    | DropdownItem
    | {
        group: string;
      }
  )[];
  hasNewTemplatesFeature: () => boolean;
  allTemplates: DocumentTemplateResource[];
};

export const SingleContentForm = ({
  formState,
  isLoading,
  briefLimit,
  setFormState,
  onSubmit,
  contentTypes,
  templates,
  hasNewTemplatesFeature,
  allTemplates,
}: Props) => {
  const placeholder = useGetRandomPlaceHolder();
  const appState = useAppStore();
  const search = useSearch({ from: '/create-content/ai-generated' });

  const { hasFeature } = useFeatureFlagging();

  const [keywordError, setKeywordError] = useState<string>();

  const setSingleFormValues = (
    values: Partial<AiGeneratedFormState['single']>,
  ) => {
    setFormState({
      ...formState,
      single: {
        ...formState.single,
        ...values,
      },
    });
  };

  const analyseUrlMutation = useAnalyzePage({
    onSuccess: (data) => {
      setSingleFormValues({ briefFromUrl: data.data.content });
    },
  });

  const handleImportBrief = () => {
    analyseUrlMutation.mutate({
      body: {
        url: formState.single.importedUrl,
        content_format: 'text',
        page_type: 'analyze',
      },
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (formState.single.keyword === '') {
      setKeywordError('Keyword is required');
      return;
    }
    setKeywordError(undefined);

    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-6">
      <InputDecoration
        label="Target keyword"
        required
        hint="The keyword you would like to rank on"
      >
        <Input
          clearable
          disabled={search.documentId !== undefined || isLoading}
          error={keywordError}
          value={formState.single.keyword}
          onChange={(value) => setSingleFormValues({ keyword: value })}
          placeholder={placeholder.keyword}
        />
      </InputDecoration>
      <InputDecoration label="H1" hint="Leave blank to let ai create">
        <Input
          clearable
          disabled={isLoading}
          value={formState.single.title}
          onChange={(value) => setSingleFormValues({ title: value })}
          placeholder={placeholder.title}
        />
      </InputDecoration>
      {hasFeature('new-templates') && (
        <InputDecoration label="Templates">
          <Tooltip
            asChild
            side="bottom"
            title="Upgrade to a higher plan to get access to this feature."
            navLink="/account/subscription"
            navLinkText="See pricing"
            disabled={
              appState.subscription?.permissions.custom_document_templates
            }
          >
            <div className="w-full">
              <Autocomplete
                clearable
                disabled={
                  !appState.subscription?.permissions.custom_document_templates
                }
                options={templates}
                value={formState.single.template?.toString() ?? ''}
                onChange={(value) => {
                  const values = setTemplateToForm(allTemplates, value);

                  setSingleFormValues(values);
                }}
              />
            </div>
          </Tooltip>
        </InputDecoration>
      )}
      <ContentTypeSelect
        contentTypes={contentTypes}
        isLoading={isLoading}
        onChange={(value) =>
          setSingleFormValues({
            contentTypeId: value,
          })
        }
        value={formState.single.contentTypeId}
      />

      <AdditionalContextForm
        briefLimit={briefLimit}
        disabled={isLoading}
        isImportSuccess={analyseUrlMutation.isSuccess}
        isLoading={analyseUrlMutation.isPending}
        brief={formState.single.brief}
        briefFromUrl={formState.single.briefFromUrl}
        url={formState.single.importedUrl}
        onBriefChange={(value) => setSingleFormValues({ brief: value })}
        onBriefFromUrlChange={(value) =>
          setSingleFormValues({ briefFromUrl: value })
        }
        onUrlChange={(value) => setSingleFormValues({ importedUrl: value })}
        onTabChange={(value) =>
          setSingleFormValues({
            selectedBriefType: value as 'from-url' | 'manual' | undefined,
          })
        }
        selectedTab={formState.single.selectedBriefType}
        onImport={handleImportBrief}
        placeholder={placeholder}
      />
      <InputDecoration label="Generation settings">
        <div className="mt-3 flex flex-col gap-2">
          <SettingsToggle
            disabled={isLoading}
            text="View writing in real-time"
            onChange={(value) =>
              setSingleFormValues({ optionLiveGenerate: value })
            }
            value={formState.single.optionLiveGenerate}
          />
          <SettingsToggle
            disabled={formState.single.template !== null || isLoading}
            text="Review Title suggestions"
            onChange={(value) =>
              setSingleFormValues({ optionReviewTitle: value })
            }
            value={
              formState.single.template
                ? false
                : formState.single.optionReviewTitle
            }
          />
          <SettingsToggle
            disabled={formState.single.template !== null || isLoading}
            text="Review Subheadings suggestions"
            onChange={(value) =>
              setSingleFormValues({ optionReviewSubheadings: value })
            }
            value={
              formState.single.template
                ? false
                : formState.single.optionReviewSubheadings
            }
          />
        </div>
      </InputDecoration>
      {(!appState.subscription!.usage.credits.generated_article.is_allowed ||
        !appState.subscription!.usage.credits.content.is_allowed) && (
        <ErrorAlert title="You have insufficient credits to generate an AI article" />
      )}

      <div className="mx-auto w-1/2">
        <Button
          fullWidth
          text={(() => {
            if (hasNewTemplatesFeature()) {
              return `${search.documentId ? 'SAVE' : 'CREATE'} AND REVIEW`;
            }
            return 'Generate';
          })()}
          type="submit"
          color="secondary"
          disabled={
            !appState.subscription!.usage.credits.generated_article
              .is_allowed ||
            !appState.subscription!.usage.credits.content.is_allowed
          }
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};
