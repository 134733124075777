import { Collapsable } from '@/Components/v2/Collapsable';
import { DynamicIcon } from '@/Components/v2/DynamicIcon/DynamicIcon';
import { DocumentElementResource } from '@/api/openapiSchemas';
import { FileQuestion } from 'lucide-react';

type Props = { el: DocumentElementResource };

export const TemplateCollapsable = ({ el }: Props) => {
  return (
    <Collapsable
      title={
        <div className="flex items-center">
          <div className="mr-1.5">
            {el.icon ? (
              <DynamicIcon
                className="stroke-primary opacity-60"
                size={20}
                name={el.icon as any}
              />
            ) : (
              <FileQuestion className="stroke-primary opacity-60" size={20} />
            )}
          </div>
          <p className="text-base font-bold text-primary">{el.display_name}</p>
        </div>
      }
      fullWidth
    >
      <p className="font-sm mx-[calc(0.375rem*2+23px)] mt-1 font-normal text-primary">
        {el.display_description}
      </p>
    </Collapsable>
  );
};
