import { SlideOver } from '@/Components/SlideOver';
import { TemplateLoader } from './TemplateLoader';
import { DocumentElementCategoryGroupResource } from '@/api/openapiSchemas';
import { TemplateSlideoverElement } from './TemplateSlideoverElement';

type Props = {
  isOpen?: boolean;
  elements: DocumentElementCategoryGroupResource[];
  isLoading: boolean;
  title: string;
};
export const TemplateSlideOver = ({
  isLoading,
  elements,
  isOpen = true,
  title,
}: Props) => {
  return (
    <SlideOver
      titleColor="text-primary"
      title="AVAILABLE ELEMENTS"
      subHeading={title}
      isOpen={isOpen}
    >
      {isLoading ? (
        <div className="flex flex-col gap-4">
          <TemplateLoader />
          <TemplateLoader />
        </div>
      ) : (
        elements.map((element) => (
          <div key={element.id} className="mb-2">
            <h1 className="text-primary">{element.name.toUpperCase()}</h1>
            <div className="mt-2 flex flex-col gap-4">
              {element.elements?.map((el) => (
                <TemplateSlideoverElement key={el.id} el={el} />
              ))}
            </div>
          </div>
        ))
      )}
    </SlideOver>
  );
};
