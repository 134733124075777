import { DocumentElementResource } from '@/api/openapiSchemas';
import { useDroppable } from '@dnd-kit/core';

type Props = {
  noIcon?: boolean;
  el?: DocumentElementResource & {
    uniqueId: string;
  };
  isMoving?: boolean;
  activeElement: DocumentElementResource | null;
  noLine?: boolean;
};

export const ElementDropzone = ({
  noIcon,
  noLine,
  isMoving,
  el,
  activeElement,
}: Props) => {
  const { isOver, setNodeRef } = useDroppable({
    id: 'add-item-and-change-order-drop ' + el?.uniqueId ?? 'first',
    data: { el },
    disabled: noIcon && !isMoving,
  });

  return (
    <div className="relative flex w-full justify-center" ref={setNodeRef}>
      {activeElement && (!noIcon || isMoving) && (
        <div
          className={`absolute top-2 h-6 w-full rounded-xl border border-dashed ${
            isOver
              ? 'border-secondary-400 bg-secondary-100'
              : ' border-primary-400 bg-primary-100'
          }`}
        />
      )}
      <div
        className={`h-10 w-0.5 ${
          noLine ? '' : 'border border-primary-300'
        } text-center`}
      >
        {activeElement && (!noIcon || isMoving) ? (
          <div className="absolute  inset-0 z-30 flex items-center justify-center">
            {isMoving ? (
              <p className="text-primary">
                Drag here to move element to this place
              </p>
            ) : (
              <p className="text-primary">
                Drag here to add element to this place
              </p>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
