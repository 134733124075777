import { Dialog } from '@/Components/Dialog';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import {
  DocumentElementActionType,
  DocumentElementResource,
  HeadingType,
} from '@/api/openapiSchemas';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FileQuestion, GripVertical, Pencil, Trash2 } from 'lucide-react';
import { TemplateItemInputs } from './TemplateItemInputs';
import { useState } from 'react';
import { TestPromptSection } from './TestPromptSection';
import { DynamicIcon } from '@/Components/v2/DynamicIcon/DynamicIcon';

type Props = {
  el: DocumentElementResource & { uniqueId: string };
  onChange: (
    type: DocumentElementActionType,
    el: DocumentElementResource & { uniqueId: string },
    value: string,
    property?: 'subheading' | 'display_name',
  ) => void;
  onDelete: (el: DocumentElementResource & { uniqueId: string }) => void;
  onSelect: (
    el: DocumentElementResource & { uniqueId: string },
    value: HeadingType,
  ) => void;
  removeDrag?: boolean;
  testPromptDialog: {
    documentId: string;
    uniqueId: string;
    preview: string;
  };
  onCloseDialog: () => void;
  onAddPreview: (preview: string) => void;
  onOpenDialog: (uniqueId: string) => void;
  onAddDocumentId: (documentId: string | undefined) => void;
};

export const TemplateItem = ({
  el,
  onChange,
  onDelete,
  onSelect,
  removeDrag,
  testPromptDialog,
  onAddPreview,
  onCloseDialog,
  onOpenDialog,
  onAddDocumentId,
}: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: 'movable ' + el.uniqueId,
      data: { el },
      disabled: removeDrag,
    });

  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const isOpen = testPromptDialog.uniqueId === el.uniqueId;

  const style = !removeDrag
    ? {
        transform: CSS.Transform.toString(transform),
        transition,
      }
    : {};

  const getDescription = () => {
    switch (el.action_type) {
      case 'raw':
        return el.values?.raw_markdown;
      case 'prompt':
        return el.values?.prompt_instruction;
      default:
        return el.values?.prompt_instruction;
    }
  };
  return (
    <>
      <Dialog
        dense
        isOpen={isOpen}
        title={
          <div className="mt-5 flex w-full items-center gap-2 px-7 pr-4 text-lg font-bold">
            {isEditing ? (
              <input
                onClick={(e) => e.stopPropagation()}
                type="text"
                autoFocus
                value={el.values?.display_name ?? ''}
                placeholder={el.display_name}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setIsEditing(false);
                  }
                }}
                onBlur={() => setIsEditing(false)}
                onChange={(e) =>
                  onChange(el.action_type, el, e.target.value, 'display_name')
                }
                className="w-full border-none px-0 py-0.5 placeholder:text-primary hover:bg-primary-50 focus:bg-primary-50"
              />
            ) : (
              <>
                {el.values?.display_name
                  ? el.values.display_name
                  : el.display_name}
                <IconButton
                  icon={Pencil}
                  onClick={() => setIsEditing(true)}
                  dense
                />
              </>
            )}
          </div>
        }
        size="lg"
        handleClose={onCloseDialog}
      >
        <div className="bg-primary-50">
          <div className="mt-5 border-b bg-white px-7 pb-12">
            <TemplateItemInputs
              onSelect={onSelect}
              el={el}
              onChange={onChange}
            />
          </div>
          <TestPromptSection
            el={el}
            onAddDocumentId={onAddDocumentId}
            onAddPreview={onAddPreview}
            testPromptDialog={testPromptDialog}
          />
        </div>
      </Dialog>

      <div
        style={style}
        className="relative rounded-md border bg-white px-2 py-2 shadow-base-small"
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        onClick={() => onOpenDialog(el.uniqueId)}
        onMouseEnter={(e) => {
          e.stopPropagation();
          setIsHovering(true);
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          setIsHovering(false);
        }}
      >
        {!removeDrag && (
          <div
            className={`absolute -left-10 bottom-0 top-0 flex h-12 items-center pr-2 ${
              isHovering ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <IconButton icon={GripVertical} />
          </div>
        )}
        <div className="flex flex-grow items-center justify-between overflow-hidden">
          <div className="flex flex-grow items-center justify-between">
            <div className="mx-2">
              {el.icon ? (
                <DynamicIcon
                  className="stroke-primary opacity-60"
                  size={20}
                  name={el.icon as any}
                />
              ) : (
                <FileQuestion className="stroke-primary opacity-60" size={20} />
              )}
            </div>
            <p className="flex-grow truncate text-lg font-bold text-primary">
              {el.values?.display_name
                ? el.values.display_name
                : el.display_name}
            </p>

            <div className={`${isHovering ? 'opacity-100' : 'opacity-0'}`}>
              <IconButton
                icon={Pencil}
                onClick={() => onOpenDialog(el.uniqueId)}
              />
            </div>
          </div>
        </div>
        {getDescription() && (
          <div className="my-1 pl-[2.375rem] pr-2 text-primary">
            {getDescription()}
          </div>
        )}

        <div
          className={`absolute -right-10 bottom-0 top-0 flex h-12 items-center pl-2 ${
            isHovering ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <IconButton
            icon={Trash2}
            onClick={(e) => {
              e.stopPropagation();
              onDelete(el);
            }}
          />
        </div>
      </div>
    </>
  );
};
