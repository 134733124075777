import { PlaceHolder } from '@/types';
import { Button } from './v2/Button';
import { Input } from './v2/Input/Input';
import { InputDecoration } from './v2/Input/InputDecoration';
import { Tabs } from './v2/Tabs/Tabs';
import { TextArea } from './v2/TextArea/TextArea';
type Props = {
  brief: string;
  onBriefChange: (value: string) => void;
  briefFromUrl: string;
  onBriefFromUrlChange: (value: string) => void;
  url: string;
  onUrlChange: (value: string) => void;
  isLoading?: boolean;
  briefLimit: number;
  disabled?: boolean;
  isImportSuccess: boolean;
  selectedTab: string;
  onTabChange: (value: string) => void;
  onImport: () => void;
  placeholder: PlaceHolder;
};

export const AdditionalContextForm = ({
  briefLimit,
  brief,
  briefFromUrl,
  onBriefChange,
  onBriefFromUrlChange,
  onUrlChange,
  url,
  isImportSuccess,
  isLoading,
  onImport,
  onTabChange,
  disabled,
  selectedTab,
  placeholder,
}: Props) => {
  const briefTabs = [
    {
      value: 'manual',
      text: 'Provide text',
    },
    {
      value: 'from-url',
      text: 'Import from url',
    },
  ];

  return (
    <InputDecoration label="Additional context">
      <div className="flex flex-col gap-4">
        <Tabs
          disabled={isLoading}
          value={briefTabs.findIndex((tab) => tab.value === selectedTab)}
          onChange={(value) =>
            onTabChange(briefTabs[value].value as 'manual' | 'from-url')
          }
          tabs={[
            {
              text: 'Provide text',
            },
            {
              text: 'Import from url',
            },
          ]}
        />
        {selectedTab === 'manual' && (
          <TextArea
            counter={brief.length}
            disabled={isLoading}
            counterMax={briefLimit}
            value={brief}
            onChange={onBriefChange}
            placeholder={'Provide background information for the AI.'}
          />
        )}
        {selectedTab === 'from-url' && (
          <>
            <div className="flex flex-col items-end gap-6">
              <Input
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    onImport();
                  }
                }}
                clearable
                fullWidth
                disabled={disabled || isLoading}
                value={url}
                onChange={onUrlChange}
                placeholder={placeholder.url}
              />

              <Button
                color={!isImportSuccess ? 'secondary' : 'gray'}
                variant={isImportSuccess ? 'outline' : 'fill'}
                onClick={onImport}
                isLoading={isLoading}
                text={isImportSuccess ? 'Retry' : 'Import'}
              />
            </div>
            {isImportSuccess && (
              <TextArea
                value={briefFromUrl}
                onChange={onBriefFromUrlChange}
                disabled={isLoading}
                counterMax={briefLimit}
                counter={briefFromUrl.length}
              />
            )}
          </>
        )}
      </div>
    </InputDecoration>
  );
};
