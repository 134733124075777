import React, { useEffect, useState } from 'react';
import Checkbox from '@/Components/Checkbox';
import LogoutLink from '@/Pages/Auth/Finalize/LogoutLink';
import { LanguageResource } from '@/api/openapiSchemas';
import LanguageComboboxV2 from '@/Components/Inputs/LanguageComboBoxV2';
import { useNavigate } from '@tanstack/react-router';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { tracking } from '@/Services/tracking/Tracking';
import { Button } from '@/Components/v2/Button';
import { SeoAiLogoBlack } from '@/Components/SVGs/SeoAiLogoBlack';
import { Errors } from '@/Components/Errors';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { useLanguagesIndex, useProjectsStore } from '@/api/openapiComponents';

type FormValues = {
  language: LanguageResource | null;
  domain: string;
  acceptTerms: boolean;
};

export default function CreateProject() {
  const appState = useAppStore();
  const navigate = useNavigate();
  const initialValues: FormValues = {
    language: null,
    domain: '',
    acceptTerms: false,
  };

  const languageQuery = useLanguagesIndex({ queryParams: { limit: 1000 } });

  const [formValues, setFormValues] = useState(initialValues);
  const [showForceDomain, setShowForceDomain] = useState(false);
  const [forceDomain, setForceDomain] = useState(false);

  const createProjectMutation = useProjectsStore();

  const onHandleChange = (value: string, name: string) => {
    const strippedValue =
      (name === 'domain' && value.startsWith('http://')) ||
      value.startsWith('https://')
        ? value.replace(/^(http:\/\/|https:\/\/)/, '')
        : value;
    setFormValues({ ...formValues, [name]: strippedValue });
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    createProjectMutation.reset();
    createProjectMutation.mutate(
      {
        body: {
          domain: formValues.domain,
          language_id: formValues.language?.id!,
          force: forceDomain,
        },
      },
      {
        onSuccess: async (data) => {
          appState.handleCreateProject(data.data);
          setTimeout(() => {
            tracking.event('onboarding_create_project');

            navigate({ to: '/onboarding/choose-keyword' });
          }, 100);
        },
      },
    );
  };

  useEffect(() => {
    if (
      createProjectMutation.error?.body?.errors?.domain?.includes(
        'The domain must be reachable.',
      )
    ) {
      setShowForceDomain(true);
    } else {
      setShowForceDomain(false);
      setForceDomain(false);
    }
  }, [createProjectMutation.error]);

  if (!languageQuery.data) {
    return <div />;
  }

  appState.pageTitle('Create your account');

  return (
    <div className="flex h-screen flex-col items-center justify-center text-gray-800">
      <div className="absolute left-0 right-0 top-2 flex w-full justify-center">
        <SeoAiLogoBlack />
      </div>
      <div className="absolute bottom-2 right-2">
        <LogoutLink />
      </div>
      <div>
        <h2 className="mb-3 text-center font-work text-[25px] font-bold leading-normal text-black">
          Set domain and market
        </h2>

        <p className="mb-8 text-center  text-gray-400">
          Configure your setup to guarantee precise and relevant output.
        </p>

        <Errors error={createProjectMutation.error} />

        <form onSubmit={submit} className="w-[40rem] animate-fade-slow">
          {/* Language input */}
          <InputDecoration
            label="Language & target market"
            required
            description="Sets AI writing language and Google search benchmarks."
          >
            <LanguageComboboxV2
              languages={languageQuery.data.data}
              placeholder="Choose language (Market)"
              selectedLanguage={formValues.language}
              setSelectedLanguage={(language) =>
                setFormValues({ ...formValues, language })
              }
            />
          </InputDecoration>

          <div className="mb-8 ml-1 mt-1 w-full text-sm">
            Learn more about&nbsp;
            <a
              href="https://docs.seo.ai/a-4-how-to-create-projects"
              target={'_blank'}
              rel="noreferrer"
            >
              <u>website languages</u>
            </a>
          </div>

          {/* Domain input */}
          <InputDecoration
            label="What domain are you doing SEO for?"
            required
            description="Used for automated internal linking suggestions."
          >
            <Input
              type="text"
              name="domain"
              autoComplete="domain"
              placeholder="www.yoursite.com"
              value={formValues.domain}
              onChange={(value) => onHandleChange(value, 'domain')}
            />
          </InputDecoration>

          {/* Accept terms */}
          <div className="my-4">
            {showForceDomain && (
              <label className="flex items-center gap-2 ">
                <Checkbox
                  value={forceDomain}
                  handleChange={(e) => setForceDomain(e.target.checked)}
                />
                I accept that my domain can't be crawled
              </label>
            )}
          </div>

          {/* Submit */}
          <div className="mx-auto w-96">
            <Button
              fullWidth
              color="secondary"
              type="submit"
              isLoading={createProjectMutation.isPending}
              text="COMPLETE SETUP"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
