import React, { ReactNode } from 'react';
import { X } from 'lucide-react';

type Props = {
  children: ReactNode | ReactNode[];
  onRemove: () => void;
};

export const FilterItem = ({ children, onRemove }: Props) => {
  const handleXClick = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();
    onRemove();
  };

  return (
    <>
      {children} <X onClick={handleXClick} size={16} />
    </>
  );
};
