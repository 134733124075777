import { createRoute, redirect, useSearch } from '@tanstack/react-router';
import { rootRoute } from './Router';
import {
  guards,
  hasActiveSubscription,
  hasFeature,
  hasProject,
  hasUnpaidSubscription,
  isAuthenticated,
} from './Router/guards';
import EditProject from './Settings/EditProject/EditProject';
import CreateProject from './CreateProject';
import Checkout from './Checkout';
import SubscriptionUnpaid from './SubscriptionUnpaid';
import Project from './Project';
import { Settings } from './Settings/Settings';
import { BrandVoice } from './Settings/BrandVoice';
import ProjectUrls from './Settings/ProjectUrls/ProjectUrls';
import { Connections } from './Settings/Connections/Connections';
import { Templates } from './Templates';
import { Template } from './Templates/Template';
import { useViewDocumentTemplate } from '@/api/openapiComponents';
import { validateTanStackSearch } from '@/utils';

const checkoutRoute = createRoute({
  getParentRoute: () => rootRoute,
  validateSearch: (search: Record<string, string>) => ({
    product_id: Number(search.product_id),
  }),
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated]);
  },
  path: '/checkout',
  component: () => {
    const params = checkoutRoute.useSearch();
    return <Checkout productId={params.product_id} />;
  },
});
const settingsRoute = createRoute({
  getParentRoute: () => rootRoute,
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },

  path: '/settings',
  component: () => <Settings />,
});

const editProjectRoute = createRoute({
  getParentRoute: () => settingsRoute,
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },
  path: '/edit-project',
  component: () => <EditProject />,
});

const brandVoiceRoute = createRoute({
  getParentRoute: () => settingsRoute,
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },
  path: '/brand-voice',
  component: () => <BrandVoice />,
});

const websiteUrlsRoute = createRoute({
  getParentRoute: () => settingsRoute,
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },
  path: '/website-urls',
  component: () => <ProjectUrls />,
});

const connectionsRoute = createRoute({
  getParentRoute: () => settingsRoute,
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },
  path: '/connections',
  component: () => <Connections />,
});

export const routes = [
  settingsRoute.addChildren([
    editProjectRoute,
    brandVoiceRoute,
    websiteUrlsRoute,
    connectionsRoute,
  ]),
  createRoute({
    getParentRoute: () => rootRoute,
    validateSearch: validateTanStackSearch,
    beforeLoad: ({ context }) => {
      guards(context, [
        isAuthenticated,
        hasActiveSubscription,
        hasProject,
        (context) => {
          if (!context.hasFeature('new-templates')) {
            throw redirect({
              to: '/',
            });
          }
        },
      ]);
    },
    path: '/templates',
    component: () => <Templates />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    validateSearch: (
      search: Record<string, string>,
    ): { templateId?: number } => ({
      templateId: search.templateId ? Number(search.templateId) : undefined,
    }),
    beforeLoad: ({ context }) => {
      guards(context, [
        isAuthenticated,
        hasActiveSubscription,
        hasProject,
        (context) => hasFeature(context, undefined, 'new-templates'),
      ]);
    },

    path: '/templates/template',
    component: () => {
      const params = useSearch({ from: '/templates/template' });
      const variables = {
        pathParams: { documentTemplate: params.templateId! },
      };
      const templateQuery = useViewDocumentTemplate(variables, {
        enabled: params.templateId !== undefined,
      });

      return (
        <Template
          key={templateQuery.data?.data.id}
          template={templateQuery.data?.data}
          isLoading={templateQuery.isLoading}
        />
      );
    },
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/create-project',
    component: () => <CreateProject />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasUnpaidSubscription]);
    },
    path: '/subscription-unpaid',
    component: () => <SubscriptionUnpaid />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [
        () => isAuthenticated(context, '/login', undefined),
        hasActiveSubscription,
        hasProject,
        () => redirect({ to: '/created-content' }),
      ]);
    },
    path: '/',
    component: () => <Project />,
  }),
  checkoutRoute,

  //redirects
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: () => {
      throw redirect({ to: '/' });
    },
    path: '/project/$projectId',
    component: () => <Project />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: () => {
      throw redirect({ to: '/' });
    },
    path: '/current-project',
    component: () => <Project />,
  }),
];
