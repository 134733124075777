import { SlideOver } from '@/Components/SlideOver';
import { ErrorAlert, WarningAlert } from '@/Components/v2/Alert';
import { Button } from '@/Components/v2/Button';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { useAppStore } from '@/Pages/AppLoader/stores';
import {
  useGetGoogleSearchConsole,
  useProjectKeywordGaps,
} from '@/api/openapiComponents';
import { DocumentResource } from '@/api/openapiSchemas';
import { Globe, PlugZap, RefreshCcw } from 'lucide-react';
import { KeywordInjectionOverlay } from './components/KeywordInjectionOverlay';
import { useEffect, useState } from 'react';
import { useKeywordInjectionStore } from '@/Pages/Document/stores';
import { useShallow } from 'zustand/react/shallow';
import { KeywordGapRow } from './components/KeywordGapRow';
import { GSCChooseSiteDialog } from '@/Pages/Settings/Connections/components/GSCChooseSiteDialog';
import { useConnectToGoogleSearchConsole } from '@/Pages/Settings/hooks';
import { useGoogleLogin } from '@react-oauth/google';
import { useURLState } from '@/Pages/Document/hooks';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';

type Props = {
  isOpen: boolean;
  document: DocumentResource;
  onClose: () => void;
};

export const KeywordGapSlideover = ({ isOpen, document, onClose }: Props) => {
  const appState = useAppStore();
  const [url, setUrl] = useURLState();

  const [urlInput, setUrlInput] = useState('');
  const [isSubmittingUrl, setIsSubmittingUrl] = useState(false);

  useEffect(() => {
    if (url === '') {
      setUrlInput('');
      setIsSubmittingUrl(false);
    }
  }, [url]);

  const {
    googleDialog,
    googleSearchConsoleData,
    hasNoAvailableSites,
    isGettingGoogleSearchConsoleData,
    isSelectingSiteForGoogleSearchConsole,
    isSettingUpGoogleSearchConsole,
    selectSiteForGoogleSearchConsole,
    setGoogleDialog,
    setupGoogleConsole,
    initialState,
  } = useConnectToGoogleSearchConsole(appState.currentProject!.id);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setupGoogleConsole({
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          code: codeResponse.code,
        },
      });
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/webmasters.readonly',
    ux_mode: 'popup',
  });

  const [showOverlay, setShowOverlay] = useState(false);
  const { keyword, resetSlideOver } = useKeywordInjectionStore(
    useShallow((state) => ({
      keyword: state.keyword,
      resetSlideOver: state.resetSlideOver,
    })),
  );

  const googleQuery = useGetGoogleSearchConsole(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
    },
    {
      enabled: isOpen,
      retry: false,
    },
  );

  const keywordGapQuery = useProjectKeywordGaps(
    {
      pathParams: {
        project: appState.currentProject!.id,
        document: document.id,
      },
    },
    {
      enabled: isOpen,
      retry: false,
    },
  );

  const handleOverlayClose = () => {
    setShowOverlay(false);
  };

  const handleInsertKeyword = (keyword: string) => {
    resetSlideOver(keyword, 'missing');
    setShowOverlay(true);
  };

  return (
    <>
      <GSCChooseSiteDialog
        isLoading={isSelectingSiteForGoogleSearchConsole}
        isOpen={googleDialog.isOpen}
        hasNoAvailableSites={hasNoAvailableSites}
        onClose={() => setGoogleDialog(initialState)}
        onConnect={() => {
          selectSiteForGoogleSearchConsole({
            pathParams: {
              project: appState.currentProject!.id,
            },
            body: {
              site: googleDialog.selectedSite!,
            },
          });
          setGoogleDialog(initialState);
          keywordGapQuery.refetch();
        }}
        setGoogleDialog={setGoogleDialog}
        googleDialog={googleDialog}
      />
      <SlideOver
        isOpen={isOpen}
        title="Keyword Gaps"
        subHeading="Use on existing content to find keywords generating clicks but are missing in content."
        onClose={onClose}
        color="bg-fuchsia-500"
        readMoreUrl="https://docs.seo.ai/a-36-keyword-gaps"
      >
        <div className="relative flex h-full w-full flex-col">
          <KeywordInjectionOverlay
            project={appState.currentProject!}
            document={document}
            keyword={showOverlay ? keyword : undefined}
            onClose={handleOverlayClose}
          />
          <div className="-mr-4 flex justify-end pb-4">
            {googleQuery.data?.data.state === 'active' && (
              <Button
                size="xs"
                text="refresh"
                variant="outline"
                dense
                prependIcon={RefreshCcw}
                onClick={() => keywordGapQuery.refetch()}
              />
            )}
          </div>
          <div className="-mr-6 flex-grow overflow-y-auto pr-2">
            {(document.url?.length ?? 0) === 0 ? (
              <form
                className="mt-12 flex flex-col items-center gap-6 px-12 font-bold text-primary-600"
                onSubmit={(e) => {
                  e.preventDefault();
                  setIsSubmittingUrl(true);
                  setUrl(urlInput);
                }}
              >
                <Globe size={64} className="text-primary-200" />
                Add URL to content to identify missing keywords
                <InputDecoration label="Content url">
                  <Input
                    value={urlInput}
                    onChange={(value) => setUrlInput(value)}
                    placeholder="https://example.com"
                  />
                </InputDecoration>
                <div className="-mt-4 flex w-full justify-end">
                  <Button
                    type="submit"
                    text="save"
                    color="secondary"
                    isLoading={isSubmittingUrl}
                  />
                </div>
              </form>
            ) : (
              <>
                {googleQuery.isLoading || keywordGapQuery.isFetching ? (
                  <div className="flex flex-col gap-2">
                    <SkeletonLoader />
                    <SkeletonLoader />
                    <SkeletonLoader />
                    <SkeletonLoader />
                    <SkeletonLoader />
                    <SkeletonLoader />
                    <SkeletonLoader />
                    <SkeletonLoader />
                    <SkeletonLoader />
                    <SkeletonLoader />
                    <SkeletonLoader />
                  </div>
                ) : (
                  <>
                    {googleQuery.data?.data.state === 'active' ? (
                      <>
                        {keywordGapQuery.error ? (
                          <>
                            <ErrorAlert
                              title="Error"
                              body={[
                                'We were not able to fetch data from Search Console.',
                                'Please try again later.',
                              ]}
                            />
                            <div className="mt-4 flex justify-center">
                              <Button
                                text="Reconnect"
                                color="secondary"
                                onClick={login}
                              />
                            </div>
                          </>
                        ) : (keywordGapQuery.data?.data.length ?? 0) > 0 ? (
                          <table className="w-full">
                            <thead>
                              <th className="text-start text-sm text-primary-800">
                                MISSING KEYWORD
                              </th>
                              <th className="text-end text-sm text-primary-800">
                                CLICKS
                              </th>
                              <th className="text-end text-sm text-primary-800">
                                IMPRES.
                              </th>
                            </thead>
                            <tbody>
                              {keywordGapQuery.data!.data.map((keywordGap) => (
                                <KeywordGapRow
                                  keywordGap={keywordGap}
                                  onKeywordInsert={handleInsertKeyword}
                                />
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div className="flex flex-col items-center">
                            <WarningAlert
                              title="No data found in Search console"
                              body={[
                                `We were not able to find any data for ${document.url} in Search Console.`,

                                'This might be because the URL is not matching the URLs in Search Console.',
                              ]}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="mt-12 flex flex-col items-center gap-6">
                        <PlugZap size={64} className="text-primary-200" />
                        <div className="text-center font-bold text-primary-600">
                          Connect to Google Search Console to identify missing
                          keywords
                        </div>
                        <Button
                          isLoading={
                            isSettingUpGoogleSearchConsole ||
                            isGettingGoogleSearchConsoleData ||
                            googleDialog.isOpen
                          }
                          text="Connect"
                          color="secondary"
                          onClick={login}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </SlideOver>
    </>
  );
};
